import React from "react";
import PropTypes from "prop-types";
import ConnectedBonus  from "./Bonus";
import "./ChooseStock.scss";
import ConnectedPromo from "./Promo";
import {connect} from "react-redux";

class ChooseStock extends React.Component {
    state = { type: "promo", }

    choosePromo = () => {
        this.setState({ type: "promo", });
        this.props.setDueBonus(false);
    }

    chooseBonus = () => {
        this.setState({ type: "bonus", });
        this.props.setDuePromo(false);
        this.props.setPromocode(null);
    }

    render() {
        const { setDuePromo, setDueBonus, bonuses, paramsForOrder, setPromocode, } = this.props;
        return (
            <div className="spend-points">
                {(this.state.type === "bonus")
                    && (
                        <div>
                            <ConnectedBonus setNotBonuses setDueBonus={setDueBonus} bonuses={bonuses} />
                            <div className="spend-points__set-type" onClick={this.choosePromo}>У меня есть промокод</div>
                        </div>
                    )
                }
                {(this.state.type === "promo")
                    && (
                        <div>
                            <ConnectedPromo paramsForOrder={paramsForOrder} setDuePromo={setDuePromo} setPromocode={setPromocode} />
                            <div className="spend-points__set-type bonus" onClick={() => {
                                this.chooseBonus();

                            }}>Потратить баллы</div>
                        </div>
                    )
                }
            </div>
        );
    }
}

ChooseStock.propTypes = {
    setDueBonus: PropTypes.func.isRequired,
    setDuePromo: PropTypes.func.isRequired,
    setPromocode: PropTypes.func.isRequired,
    bonuses: PropTypes.number,
    paramsForOrder: PropTypes.func.isRequired,
};
ChooseStock.defaultProps = { bonuses: undefined, };

export default connect(null, null)(ChooseStock)

// export default ChooseStock;
