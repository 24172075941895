import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import RestMap from "./RestMap";
import rest1 from "./rest1.jpg";
import "./restorants.scss";


function RestaurantsArea({ showMobileAuth, restaurants, }) {
    const restaurantsContent = () => restaurants.map(restaurant => restaurant.published ? (
        <div className="restourans-item" key={restaurant.id}>
            <div className="restourant-item__content">
                <p className="restourant-item__title">{restaurant.address}</p>
                <div className="restourant-item__metro">
                    {restaurant.metro}
                </div>
                <a className="restourant-item__phone" href={`tel:${restaurant.phone}`}>{restaurant.phone}</a>
                <div className="restourant-item__time">
                    {restaurant.schedule}
                </div>
            </div>
        </div>
    ) : "");
    const touchHandl = (e) => {
        if (e.touches.length === 1) e.preventDefault;
    };
    return (
        <main className={classNames("page container", { "hide-element": showMobileAuth, })}>
            <div className="page__container">
                <div className="restourants__flex-container">
                    <div className="restourants__section restourants__list">
                        <h1 className="restourants__section-title">Зона доставки</h1>
                        {/*<h1 className="restourants__section-title">Рестораны</h1>*/}
                        {restaurantsContent()}
                    </div>
                    <div className="restourants__section restourants__map">
                        {/* <div className="ymap-container" onTouchStart={touchHandl}>
                            <iframe
                                title="Зона доставки"
                                src="https://yandex.ru/map-widget/v1/-/CBRpEQatPA"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                allowFullScreen

                            />
                        </div> */}
                        {/* <div id="map" className="ymap-container" /> */}
                        <RestMap restaurant={restaurants[0]} />
                    </div>
                </div>
            </div>
        </main>
    );
}
RestaurantsArea.propTypes = {
    showMobileAuth: PropTypes.bool.isRequired,
    restaurants: PropTypes.instanceOf(Array).isRequired,
};
export default RestaurantsArea;
