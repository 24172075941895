import { connect } from "react-redux";
import Component from "./Market";

function mapStateToProps(state) {
    return { products: state.products, };
}

const ConnectedComponent = connect(
    mapStateToProps
)(Component);

export default ConnectedComponent;
