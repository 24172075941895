import { Link } from "react-router-dom";
import { getTotalCount } from "../../helpers";
import BasketIcon from "../icons/mobile/BasketMobile";

function BasketMobile({ cart, }) {
    const cartCount = getTotalCount(cart);
    if (cartCount > 0) {
        return (
            <Link to="/basket" className="mobile-basket-float">
                <BasketIcon /><span className="basket__counter">{cartCount}</span>
            </Link>
        );
    }
    return null;
}
BasketMobile.propTypes = { cart: PropTypes.instanceOf(Array).isRequired, };
export default BasketMobile;
