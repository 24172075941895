import { connect } from "react-redux";
import ProductCard from "./ProductCard";
import { addToCart, removeFromCart } from "../../AC";

const mapDispatchToProps = {
    addToCart,
    removeFromCart,
};

const ConnectedProductCard = connect(
    null, mapDispatchToProps
)(ProductCard);

export default ConnectedProductCard;
