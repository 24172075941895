import React from "react";
import PropTypes from "prop-types";
import { postRequest } from "../../../../../helpers";
import "./Promo.scss";
import ValidationResult from "../../../ValidationResult";

class Promo extends React.Component {
    state = {
        processing: false,
        errors: null,
        discount: null,
        hint: null,
        code: null,
        invalidPromo: false,
    }

    code = React.createRef();

    sendCode = () => {
        const code = this.code.current.value;
        if (!code) {
            this.setState({
                errors: [{ message: "Поле не должно быть пустым", }],
                hint: "err",
            });
            return null;
        }
        if (code === this.state.code) { return null; }
        const params = this.props.paramsForOrder();
        params.promocode = code;
        this.setState({ processing: true, });
        postRequest("/order/validate-promocode", params).then((res) => {
            if (res.data.success) {
                const { total_due, total, promocode_discount} = res.data.result.order;
                this.props.setPromocodeData(res.data)
                this.props.setDuePromo(total_due);
                this.props.setPromocode(code);
                this.setState({invalidPromo: false})
                this.setState({ hint: "discount", discount: (total - total_due) / total * 100, });
                this.props.setUsePromo(true);
                this.props.setPromoDiscount(promocode_discount);
            } else {
                this.setState({ errors: res.data.errors, hint: "err", });
                this.props.setDuePromo(false);
                this.setState({invalidPromo: true,})
                this.props.setPromocode(null);

            }
            this.setState({ processing: false, code, });
        }).catch(err =>{
                this.setState({invalidPromo: true,processing: false})
        });
    }

    render() {
        const loading = this.state.processing === true;
        const { hint, discount, errors, } = this.state;
        return (
            <div className="promo">
                <h3 className="promo__mobile-title">У меня есть промокод</h3>
                <h3 className="spend-points__title">Eсть промокод</h3>
                <div className={`promo__container ${!hint && "own-margin"}`}>
                    <input
                        className={`
                            ${hint === "err" && "error"} 
                            ${hint === "discount" && "success"} 
                            ${!hint && "own-margin"}
                        `}
                        ref={this.code}
                        type="text"
                        defaultValue={this.props.payment.use_promo? this.props.payment.promocode_data.result.order.promocode:''}
                        placeholder="Введите промо-код"
                    />

                    {hint === "err" && (
                        <div className="promo__errors">
                            {errors.map(err => (
                                <ValidationResult key={err.message} error={err.message} />
                            ))}
                        </div>
                    )}
                    {this.props.payment.use_promo && (
                        <div className="promo__discount">
                            -{Math.round(
                            (this.props.payment.promocode_data.result.order.total - this.props.payment.promocode_data.result.order.total_due) / this.props.payment.promocode_data.result.order.total * 100)}% на весь заказ
                        </div>
                    )}
                    <button className={`btn ${loading && "loading"} ${this.state.invalidPromo && "invalidPromo"}`} type="button" onClick={this.sendCode}>Применить код</button>
                </div>
            </div>
        );
    }
}
Promo.propTypes = {
    setPromocode: PropTypes.func.isRequired,
    paramsForOrder: PropTypes.func.isRequired,
    setDuePromo: PropTypes.func.isRequired,
};


export default Promo;
