import React from 'react';

const ModalHide = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18.5208" height="2.6916" rx="1" transform="matrix(0.707096 0.707118 -0.707096 0.707118 1.90625 0)" fill="#3D3B3B"/>
            <rect width="18.5207" height="2.69159" rx="1" transform="matrix(0.707098 -0.707115 0.707098 0.707115 0 13.0967)" fill="#3D3B3B"/>
        </svg>
    );
};

export default ModalHide;
