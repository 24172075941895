function ContentButton(props) {
    const { onClickMethod, onClickSubmit, buttonType, buttonClass, buttonTitle, children, } = props;

    if (onClickSubmit) {
        return (
            <button
                type="button"
                className={`button button-${buttonType} ${buttonClass}`}
                onClick={onClickMethod}
            >
                {children || buttonTitle}
            </button>
        );
    }
    return (
        <div
            className={`button button-${buttonType} ${buttonClass}`}
            onClick={onClickMethod}
        >
            {children || buttonTitle}
        </div>
    );
}

ContentButton.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    buttonType: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Array)
    ]),
    onClickMethod: PropTypes.func,
    onClickSubmit: PropTypes.bool,
};
ContentButton.defaultProps = {
    onClickMethod: () => { },
    onClickSubmit: null,
    children: null,
    buttonClass: "",
};
export default ContentButton;
