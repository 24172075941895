const BasketMobile = () => (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <rect x="10" y="4" width="60" height="60" rx="30" fill="#8DD161" />
        </g>
        <path d="M28 28H52V41C52 42.6569 50.6569 44 49 44H31C29.3431 44 28 42.6569 28 41V28Z" stroke="white" strokeWidth="2" />
        <path d="M34 23C34 21.8954 34.8954 21 36 21H44C45.1046 21 46 21.8954 46 23V28H34V23Z" stroke="white" strokeWidth="2" />
        <defs>
            <filter id="filter0_d" x="0" y="0" width="80" height="80" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.232128 0 0 0 0 0.41048 0 0 0 0 0.1178 0 0 0 0.4 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default BasketMobile;
