import React from 'react'
import './TransferNotification.scss'

export default function TransferNotification({ declineAction, acceptAction, name }) {

    let currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    let day = currentDate.getDate()
    let month = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1
    let year = currentDate.getFullYear()

    const openTime = new Date();
    const now = new Date();
    openTime.setHours(9, 0, 0);

    return (
        <section className="trans-notification__wrapper" onClick={(e) => {
            e.preventDefault()
            declineAction()
        }}>
            <div className="trans-notification__body">
                <div className="trans-notification__body-header">
                    <div className="trans-notification__body-header__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" class="svg-inline--fa fa-exclamation-circle fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" /></svg>
                    </div>
                    <div className="trans-notification__body-header__title">
                        <h2>Внимание</h2>
                    </div>
                </div>
                <div className="trans-notification__body-main">
                    <p className="trans-notification-text">{!name ? 'Уважаемый посетитель' : name}! Мы работаем с 9:00 до 23:00. Ваш заказ будет принят в работу <span className="pink">{openTime.valueOf() > now.valueOf() ? day - 1 : day}.{month}.{year}</span> в <span className="pink">9:00</span>. Вы уверены, что хотите продолжить?</p>
                </div>
                <div className="trans-notification__body-buttons">
                    <button onClick={(e) => {
                        e.preventDefault()
                        declineAction()
                    }} className="decline-btn">Отменить</button>
                    <button onClick={(e) => {
                        e.preventDefault()
                        acceptAction()
                    }} className="accept-btn">Продолжить</button>
                </div>
            </div>
        </section>
    )
}