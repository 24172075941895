import { connect } from "react-redux";
import Component from "./BasketArea";
import { addToCart,
    removeFromCart,
    removeAllItemsFromCart,
    setPaymentMethod,
    setConstructorVisible,
    setConstructorData } from "../../../AC";

function mapStateToProps(state) {
    return {
        cart: state.cart,
        bowl_constructor: state.bowl_constructor,
    };
}
const mapDispatchToProps = {
    addToCart,
    removeFromCart,
    removeAllItemsFromCart,
    setPaymentMethod,
    setConstructorVisible,
    setConstructorData,
};

const ConnectedComponent = connect(
    mapStateToProps, mapDispatchToProps
)(Component);

export default ConnectedComponent;
