import {
    RELOAD_DATA, SET_ERROR_MESSAGE,
    SET_PAYMENT_METHOD,
    SET_BONUS_DISCOUNT,
    SET_PAYMENT_WITH_BONUS,
    SET_PROMO_DISCOUNT, SET_PROMOCODE_DATA,
    SET_USE_PROMO
} from "../AC";

const  initialState = {
    payment_with_bonus: false,
    use_promo: false,
    bonus_discount: 0,
    promo_discount: 0,
    reload_data: false,
    payment_method:-1,
    promocode_data : {},
    error_message: {error: false}
};

function paymentReducer(state = initialState, action) {
    const { type, flag_bonus, discount} = action;
    switch (type) {
        case SET_PAYMENT_WITH_BONUS:{
            return {...state, payment_with_bonus: flag_bonus}
        }
        case SET_BONUS_DISCOUNT:{
            return {...state, bonus_discount: discount}
        }
        case SET_USE_PROMO:{
            return {...state, use_promo: flag_bonus}
        }
        case SET_PROMO_DISCOUNT:{
            return {...state,promo_discount: discount}
        }
        case RELOAD_DATA:
            return {...state, reload_data: action.payload};
        case SET_PAYMENT_METHOD:
            return {...state, payment_method: action.payload};
        case SET_PROMOCODE_DATA:
            return {...state, promocode_data: action.payload};
        case SET_ERROR_MESSAGE:
            return {...state, error_message: action.payload}
        default: {
            return {...state};
        }
    }
}

export default paymentReducer;
