import { ADD_TO_LIST_OF_TOPPINGS } from "../AC";
import initState from "../store/initState";

export default function toppingsReducer(toppings = initState.listOfToppings, action) {
    switch (action.type) {
    case ADD_TO_LIST_OF_TOPPINGS: {
        if (toppings.some(topping => topping.id === action.payload.id)) {
            return [...toppings];
        }

        return [...toppings, action.payload];
    }
    default:
        return toppings;
    }
}
