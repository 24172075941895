import Scroll from "react-scroll";
import { useDispatch } from "react-redux";
import { elsInCart } from "../../helpers";
import AddItemIcon from "../icons/AddItem";
import RemoveItemIcon from "../icons/RemoveItem";
import Button from "../Button";
import "./ItemControls.scss";

const scroll = Scroll.animateScroll;
const scrollOpt = {
    isDynamic: true,
    spy: true,
    smooth: true,
    duration: 500,
};

function ItemControls({
    cart,
    addToCart,
    removeFromCart,
    product,
    withScroll,
    buttonTitle,
}) {
    const elemsInCart = elsInCart(cart, product.id);

    if (elemsInCart === 0) {
        return (
            <Button
                buttonType="default"
                buttonTitle={buttonTitle}
                buttonClass="buy-item"
                onClickMethod={() => {
                    if (withScroll) scroll.scrollToTop(scrollOpt);
                    addToCart(product);
                }}
                dataObject={product}
            />
        );
    }
    return (
        <div className="item-controls-cart">
            <div className="remove-item-icon" onClick={() => removeFromCart(product.id)}>
                <RemoveItemIcon />
            </div>
            {elemsInCart}
            <div className="add-item-icon" onClick={() => addToCart(product)}>
                <AddItemIcon />
            </div>
        </div>
    );
}
ItemControls.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        categories: PropTypes.instanceOf(Array).isRequired,
    }).isRequired,
    cart: PropTypes.instanceOf(Array).isRequired,
    addToCart: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    withScroll: PropTypes.bool,
    buttonTitle: PropTypes.string,
};

ItemControls.defaultProps = {
    withScroll: false,
    buttonTitle: "В корзину",
};

export default ItemControls;
