import React from "react";

const AddItem = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill-icon" d="M0 6H14V8H0V6Z" fill="#F8819C" />
        <path className="fill-icon" d="M8 0L8 14L6 14L6 -8.74228e-08L8 0Z" fill="#F8819C" />
    </svg>
);

export default AddItem;
