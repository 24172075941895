import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AboutItem from "./AboutItem";
import SliderItem from "./SLiderItem";
import withFetch from "../../../HOC/withFetch";
import "./About.scss";

function About({ showMobileAuth, data, }) {

    const items = data.result.filter(item => item.img);
    const slides = data.result.filter(item => !item.img);
    const renderSlider = slides.map(slide => (
        <SliderItem
            key={slide.id}
            text={slide.text}
        />
    ));
    const renderItems = items.map((item, i) => (
        <AboutItem
            key={item.id}
            title={item.title}
            text={item.text}
            img={item.img}
            reverse={(i + 1) % 2 === 0}
        />
    ));

    return (
        <main className={classNames("page container", { "hide-element": showMobileAuth, })}>
            <div className="page__container">
                <div className="about__list-container">
                    {renderItems}
                    <div className="slider-container">
                        {renderSlider}
                    </div>
                </div>
            </div>
        </main>
    );
}

About.propTypes = {
    showMobileAuth: PropTypes.bool.isRequired,
    data: PropTypes.shape({ result: PropTypes.instanceOf(Object), }).isRequired,
};

export default withFetch("/pages/about", About);
