import React from "react";
import classNames from "classnames";
import { YMInitializer } from "react-yandex-metrika";
import Menu from "../Menu";
import InstagramIcon from "../icons/InstagramIcon";
import FacebookIcon from "../icons/FacebookIcon";
import {getCookie} from "../../helpers";

class Footer extends React.PureComponent {
    componentDidMount() {
        const options = {
            autoConfig: true,
            // debug: true,
        };
    }

    render() {
        const { showMobileAuth, setShowMobileMenu, socialLinks, } = this.props;
        const order = getCookie("lastOrder");
        const menuItems = [
            {
                title: "О нас",
                link: "/about",
            },
            {
                title: "Зона доставки",
                link: "/restaurants",
            }
        ];


        if (!showMobileAuth) {
            menuItems.push({
                link: "/legal",
                title: "Правовая информация",
            });
        } else {
            menuItems.unshift({
                title: "Меню",
                link: "/",
            });
            if (order) {
                menuItems.unshift({
                    title: "Статус заказа",
                    link: `/status/${order}`,
                });
            }
        }
        return (
            <footer className={classNames("footer", { "mobile-page": showMobileAuth, })
            }
            >
                <div className="container">
                    <div className="footer__content">
                        <div className="top-level">
                            <div className="left-bar">
                                <Menu
                                    menuItems={menuItems}
                                    setShowMobileMenu={showMobileAuth ? setShowMobileMenu : undefined}
                                />
                            </div>
                            <div className="right-bar phones">
                                <div className="contact-number">
                                    <a
                                        href={`tel:+7 (812) 317-20-70`}
                                    >
                                        +7 (812) 317-20-70
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-level">
                            <div className="left-bar">
                                <div className="text-description copyright">{new Date().getUTCFullYear()} pobo. Все права защищены</div>
                            </div>
                            <div className="right-bar">
                                <div className="social-icons">
                                    <a
                                        href={socialLinks.instagram}
                                        className="social-icons__item"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        onClick={this.sendPixel}
                                    >
                                        <InstagramIcon />
                                    </a>
                                    <a
                                        href={socialLinks.facebook}
                                        className="social-icons__item"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        onClick={this.sendPixel}
                                    >
                                        <FacebookIcon />
                                    </a>
                                </div>
                                <div className="tag">#{socialLinks.hashtag}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <YMInitializer accounts={[54294081]} options={{ webvisor: true, accurateTrackBounce: true, }} version="2" />
            </footer>
        );
    }
}
Footer.propTypes = {
    showMobileAuth: PropTypes.bool.isRequired,
    setShowMobileMenu: PropTypes.func.isRequired,
    socialLinks: PropTypes.shape({
        hashtag: PropTypes.string,
        facebook: PropTypes.string,
        instagram: PropTypes.string,
    }).isRequired,
};
export default Footer;
