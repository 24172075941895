import { connect } from "react-redux";
import App from "./App";
import {getProducts, getDefinitions, getProfile, reloadData, changeAddress, loadCart} from "../../AC";

function mapStateToProps(state) {
    return {
        products: state.products,
        definitions: state.definitions,
        profile: state.profile,
        reload: state.payment.reload_data,
    };
}
const mapDispatchToProps = {
    getProducts,
    getDefinitions,
    getProfile,
    reloadData,
    changeAddress,
    loadCart
};

const ConnectedApp = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default ConnectedApp;
