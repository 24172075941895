import React from "react";
import BasketMobile from "../BasketMobile";
import MarketSection from "../MarketSection";
import Hits from "../Hits";

class Market extends React.Component {
    state = {}

    render() {
        const { products, } = this.props;
        return (
            <div className="container">
                <BasketMobile />

                <div className="market-sections">
                    <Hits title="Хиты" />
                    {products.map(product => (
                        <MarketSection
                            key={product.category.id}
                            id={product.category.id}
                            title={product.category.title}
                            alias={product.category.alias}
                            items={product.items}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
Market.propTypes = { 
    products: PropTypes.instanceOf(Array).isRequired,
};
export default Market;
