import React from "react";
import classNames from "classnames";
import ym from "react-yandex-metrika";
import { Link, withRouter } from "react-router-dom";
import Logo from "../icons/LogoIcon";
import Menu from "../Menu";
import Button from "../Button";
import BurgerMobile from "../icons/mobile/Burger";
import basketIconDesctop from "./basket-icon-desctop.svg";
import basketIconMobile from "./mobile-basket-icon.svg";
import LogoMobile from "../icons/mobile/Logo";
import { formatCurrency, getCookie, getPrice1, PROD } from "../../helpers";
import BonusIcon from "../icons/BonusIcon";
import ContentButton from "../ContentButton";
import Constructor from "../Pages/Constructor";

class Header extends React.PureComponent {
    state = {
        unfinishedOrder: getCookie("lastOrder"),
        mobileMenu: false,
    };

    componentDidMount() {
        window.addEventListener("scroll", this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenToScroll);
    }

    cartPrice = () => formatCurrency(getPrice1(this.props.cart))


    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = winScroll / height;

        this.setState({ mobileMenu: scrolled > 0.03, });
    }

    renderBasketIcon = ({ mobile, }) => {
        const basketIconClass = mobile ? "mobile-basket-icon" : "desctop-basket-icon";
        const basketIconSrc = mobile ? basketIconMobile : basketIconDesctop;
        if (!this.props.authorized) return null;
        return (
            <span
                className={classNames(basketIconClass, { "hide-element": !!this.state.unfinishedOrder === false, })}
                onClick={() => {
                    window.location.hash = "#/status/" + this.state.unfinishedOrder;
                    this.props.setPaymentWithBonus(false);
                    this.props.setUsePromo(false);
                    this.props.setPromoDiscount(0);
                    this.props.setBonusDiscount(0);
                }}
            >
                <img src={basketIconSrc} alt="Иконка статуса заказа" />
            </span>
        );
    }

    openCart = type => () => {
        this.props.setPaymentWithBonus(false);
        this.props.setUsePromo(false);
        this.props.setPromoDiscount(0);
        this.props.setBonusDiscount(0);
        const ymId = type === "mobile" ? "mobile_basket_click" : "click-main-basket-button-on-home-page";
        if (PROD) { ym("reachGoal", ymId); }
        this.props.history.push("/basket");
    }

    renderProfileButton() {
        if (this.props.profile.phone === undefined) {
            const title = "Войти";
            return (
                <ContentButton
                    buttonType="default"
                    buttonTitle={title}
                    buttonClass="profile-button"
                    type="button"
                    onClickMethod={() => {
                        this.props.onShowUserProfile();
                    }
                    }
                >
                    {title}
                </ContentButton>
            );
        }
        const title = `${this.props.profile.firstname || this.props.profile.phone} ${this.props.profile.bonuses}`;
        return (
            <ContentButton
                buttonType="default"
                buttonTitle={title}
                buttonClass="profile-button"
                onClickMethod={this.props.onShowUserProfile}
            >
                <span>
                    {this.props.profile.firstname || this.props.profile.phone}
                </span>
                <span>
                    {this.props.profile.bonuses}  <BonusIcon />
                </span>
            </ContentButton>
        );
    }

    render() {
        const { categories, showMobileAuth, setShowMobileMenu, bowl_constructor, } = this.props;
        const menuItems = [...categories];

        if (menuItems.length) {
            menuItems.unshift({
                id: 0,
                alias: "hits",
                title: "Хиты",
            });
        }
        if (!this.props.bowl_constructor.view_constructor) {
            document.querySelectorAll("body")[0].style.overflow = "visible";
        }
        return (
            <header className={classNames("header", { "header-mobile": this.state.mobileMenu, })}>
                <div className={!bowl_constructor.view_constructor ? "container" : "container hide"}>
                    <section className="top-level">
                        <div className="left-bar">
                            <Link
                                to="/"
                                onClick={() => {
                                    this.props.setPaymentWithBonus(false);
                                    this.props.setBonusDiscount(0);
                                    this.props.setUsePromo(false);
                                    this.props.setPromoDiscount(0);
                                }}
                            ><Logo />
                            </Link>
                        </div>
                        <div className="right-bar">
                            <Menu
                                withoutConstructor
                                menuItems={[
                                    {
                                        link: "/",
                                        title: "Меню",
                                    },
                                    {
                                        link: "/about",
                                        title: "О нас",
                                    },
                                    // {
                                    //     link: "/sales",
                                    //     title: "Акции",
                                    // },
                                    {
                                        link: "/restaurants",
                                        title: "Зона доставки",
                                    }
                                ]}
                            />
                            {this.props.profile.phone !== undefined && this.renderBasketIcon({ mobile: false, })}
                            {this.renderProfileButton()}
                        </div>
                    </section>
                    <section className="bottom-level">
                        <div className="left-bar">
                            <Menu
                                menuItems={menuItems}
                                onClickMethod={() => {
                                    this.props.setPaymentWithBonus(false);
                                    this.props.setUsePromo(false);
                                    this.props.setPromoDiscount(0);
                                    this.props.setBonusDiscount(0);
                                }}
                            />
                        </div>
                        <div className="right-bar">
                            <div className="price-container" id="cart-price">
                                {(Number(this.cartPrice().split(" ")[0])
                                  - this.props.payment.promo_discount
                                  - this.props.payment.bonus_discount > 0)
                                    ? (
                                        Number(this.cartPrice().split(" ")[0])
                                      - this.props.payment.promo_discount
                                      - this.props.payment.bonus_discount
                                    )
                                    : 0} ₽
                            </div>
                            <Button
                                buttonType="basket"
                                buttonTitle="Корзина"
                                onClickMethod={this.openCart("desktop")}
                            />
                        </div>
                    </section>
                    <div className="mobile-section">
                        <div
                            className={classNames({ "hide-element": this.state.mobileMenu, })}
                            onClick={setShowMobileMenu}
                        >
                            <BurgerMobile
                                open={showMobileAuth}
                                className={classNames({ "hide-element": this.state.mobileMenu, })}
                            />
                        </div>
                        <div className={classNames("boul-button__container",
                            { "hide-element": !this.state.mobileMenu, })}
                        >
                            <Menu
                                onClickMethod={() => {
                                    this.props.setPaymentWithBonus(false);
                                    this.props.setUsePromo(false);
                                    this.props.setPromoDiscount(0);
                                    this.props.setBonusDiscount(0);
                                }}
                                menuItems={categories}
                            />
                        </div>
                        <a
                            className={classNames({ "hide-element": this.state.mobileMenu, })}
                            href="/"
                        >
                            <LogoMobile />
                        </a>
                        <div
                            className={classNames("right-bar", { "hide-element": this.state.mobileMenu, })}
                        >
                            {this.renderBasketIcon({ mobile: true, })}
                            <Button
                                className={classNames({ "hide-element": this.state.mobileMenu, })}
                                buttonType="basket"
                                buttonTitle={this.cartPrice()}
                                buttonClass={classNames("mobile-basket-button",
                                    { "hide-element": this.state.mobileMenu, })}
                                onClickMethod={this.openCart("mobile")}
                            />
                        </div>
                    </div>
                </div>
                {this.props.bowl_constructor.view_constructor ? (
                    <Constructor
                        showMobileAuth={showMobileAuth}
                    />
                ) : undefined}
            </header>
        );
    }
}
Header.propTypes = {
    categories: PropTypes.instanceOf(Array).isRequired,
    cart: PropTypes.instanceOf(Array).isRequired,
    onShowUserProfile: PropTypes.func.isRequired,
    showMobileAuth: PropTypes.bool.isRequired,
    setShowMobileMenu: PropTypes.func.isRequired,
    authorized: PropTypes.bool.isRequired,
    setPaymentWithBonus: PropTypes.func.isRequired,
    setUsePromo: PropTypes.func.isRequired,
    setPromoDiscount: PropTypes.func.isRequired,
    bowl_constructor: PropTypes.instanceOf(Object).isRequired,
    payment: PropTypes.instanceOf(Object).isRequired,
    profile: PropTypes.shape({
        phone: PropTypes.string,
        firstname: PropTypes.string,
        bonuses: PropTypes.number,
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired, }).isRequired,
};
export default withRouter(Header);
