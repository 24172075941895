const FILES_URL = 'https://pobo.ru/assets/svg';

export function getFlagSvgUrl(key, transparent = false) {
  const transparentStr = transparent ? '_transparent' : '';
  const FLAGS_SVGS_URLS = {
    adyen: `${FILES_URL}/adyen${transparentStr}.svg`,
    amex: `${FILES_URL}/amex${transparentStr}.svg`,
    aura: `${FILES_URL}/aura${transparentStr}.svg`,
    bin: `${FILES_URL}/bin${transparentStr}.svg`,
    boleto: `${FILES_URL}/boleto${transparentStr}.svg`,
    cirrus: `${FILES_URL}/cirrus${transparentStr}.svg`,
    credit_card: `${FILES_URL}/credit-card${transparentStr}.svg`,
    diners: `${FILES_URL}/diners${transparentStr}.svg`,
    direct_debit: `${FILES_URL}/direct_debit${transparentStr}.svg`,
    discover: `${FILES_URL}/discover${transparentStr}.svg`,
    ebay: `${FILES_URL}/ebay${transparentStr}.svg`,
    elo: `${FILES_URL}/elo${transparentStr}.svg`,
    eway: `${FILES_URL}/eway${transparentStr}.svg`,
    hipercard: `${FILES_URL}/hipercard${transparentStr}.svg`,
    jcb: `${FILES_URL}/jcb${transparentStr}.svg`,
    maestro: `${FILES_URL}/maestro${transparentStr}.svg`,
    mastercard: `${FILES_URL}/mastercard${transparentStr}.svg`,
    sage: `${FILES_URL}/sage${transparentStr}.svg`,
    shopify: `${FILES_URL}/shopify${transparentStr}.svg`,
    skrill: `${FILES_URL}/skrill${transparentStr}.svg`,
    skrill_moneybookers: `${FILES_URL}/skrill_moneybookers${transparentStr}.svg`,
    sodexo: `${FILES_URL}/sodexo${transparentStr}.svg`,
    solo: `${FILES_URL}/solo${transparentStr}.svg`,
    ticket: `${FILES_URL}/ticket${transparentStr}.svg`,
    visa: `${FILES_URL}/visa${transparentStr}.svg`,
    visa_electron: `${FILES_URL}/visa_electron${transparentStr}.svg`,
    western: `${FILES_URL}/western${transparentStr}.svg`,
    wirecard: `${FILES_URL}/wirecard${transparentStr}.svg`,
  };
  return FLAGS_SVGS_URLS[key];
}

export function getBankSvgUrls(key, transparent = false) {
  const transparentStr = transparent ? '_transparent' : '';
  const BANKS_SVGS_URLS = {
    boleto: `${FILES_URL}/boleto${transparentStr}.svg`,
    bb: `${FILES_URL}/bb${transparentStr}.svg`,
    bradesco: `${FILES_URL}/bradesco${transparentStr}.svg`,
    caixa: `${FILES_URL}/caixa${transparentStr}.svg`,
    hsbc: `${FILES_URL}/hsbc${transparentStr}.svg`,
    itau: `${FILES_URL}/itau${transparentStr}.svg`,
    santander: `${FILES_URL}/santander${transparentStr}.svg`,
  };

  return BANKS_SVGS_URLS[key];
}

export function getFacilitatorSvgUrl(key, transparent = false) {
  const transparentStr = transparent ? '_transparent' : '';
  const FACILITATORS_SVGS_URLS = {
    amazon: `${FILES_URL}/amazon${transparentStr}.svg`,
    adiq: `${FILES_URL}/adiq${transparentStr}.svg`,
    applepay: `${FILES_URL}/applepay${transparentStr}.svg`,
    bitcoin: `${FILES_URL}/bitcoin${transparentStr}.svg`,
    ebanx: `${FILES_URL}/ebanx${transparentStr}.svg`,
    firstdata: `${FILES_URL}/firstdata${transparentStr}.svg`,
    wallet: `${FILES_URL}/wallet${transparentStr}.svg`,
    paypal: `${FILES_URL}/paypal${transparentStr}.svg`,
    cielo: `${FILES_URL}/cielo${transparentStr}.svg`,
    global: `${FILES_URL}/global${transparentStr}.svg`,
    googlepay: `${FILES_URL}/googlepay${transparentStr}.svg`,
    iugu: `${FILES_URL}/iugu${transparentStr}.svg`,
    mercadopago: `${FILES_URL}/mercadopago${transparentStr}.svg`,
    pagarme: `${FILES_URL}/pagarme${transparentStr}.svg`,
    pagseguro: `${FILES_URL}/pagseguro${transparentStr}.svg`,
    paghiper: `${FILES_URL}/paghiper${transparentStr}.png`,
    rede: `${FILES_URL}/rede${transparentStr}.svg`,
    stelo: `${FILES_URL}/stelo${transparentStr}.svg`,
    stone: `${FILES_URL}/stone${transparentStr}.svg`,
    elavon: `${FILES_URL}/elavon${transparentStr}.svg`,
    getnet: `${FILES_URL}/getnet${transparentStr}.png`,
    worldpay: `${FILES_URL}/worldpay${transparentStr}.svg`,
    yapay: `${FILES_URL}/yapay${transparentStr}.svg`,
    sbp: `${FILES_URL}/sbp${transparentStr}.svg`,
  };

  return FACILITATORS_SVGS_URLS[key];
}

export function getUnknownSvg(key, transparent = false) {
  const transparentStr = transparent ? '_transparent' : '';

  const UNKNOWN_SVGS_URLS = {
    generic: `${FILES_URL}/generic${transparentStr}.svg`,
  };

  return UNKNOWN_SVGS_URLS[key];
}
