import {GET_PROFILE, ERR, CHANGE_ADDRESS, SET_ADDRESS_DATA} from "../AC";
import initState from "../store/initState";

function definitionsReducer(profile = initState.profile, { type, payload: data, }) {
    switch (type) {
        case GET_PROFILE: {
            return data.result;
        }
        case GET_PROFILE + ERR: {
            return profile;
        }
        case CHANGE_ADDRESS:{
            let user = profile;
            user.addresses = data;
            return user;
        }
        case SET_ADDRESS_DATA:
            return {...profile, address_data: data}
        default: {
            return profile;
        }
    }
}
export default definitionsReducer;
