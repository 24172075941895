export default function basketCheck(currentBasket, productsList) {
  const brokenItems = [];

  currentBasket.forEach((item) => {
    if (
      item.type !== 'set' &&
      !productsList.some(
        (product) =>
          item.id === product.id && item.iiko_id === product.iiko_id && item.alias === product.alias
      )
    ) {
      brokenItems.push(item);
    }
  });

  console.log('broken ', brokenItems);

  return brokenItems;
}
