import React, {useEffect, useState} from "react";
import classNames from "classnames";
import MarketItem from "../MarketItem";
import poke from '../../assets/img/make_your_poke.png';
import boul from '../../assets/img/make_your_boul.png';
import {useDispatch, useSelector} from "react-redux";
import {setConstructorVisible, setPaymentWithBonus, setBonusDiscount, setPromoDiscount, setUsePromo} from "../../AC";
function MarketSection({ items, withScroll, alias, title, id, }) {
    return (
        <section className="market-section" id={alias} name={alias}>
            <div className="market-section__title-container">
                <div className="market-section__title">{title}</div>
                <div className="market-section__title help-element" />
            </div>
            <div className={classNames({ "swipe-container": id === 0, })}>
                <div className={classNames("market-section__items", { "scroll-container": id === 0, })}>
                    {(alias === 'salaty' || alias === 'poke') &&
                        <StartItem
                            id={alias.toString(2)}
                            alias={'constructor'}
                            title={'Конструктор'}
                            image={alias === 'poke'? poke: boul}
                            description={`Собери ${alias === 'poke' ? 'поке': 'боул'} по своим вкусам прямо сейчас!`}
                            buttonText={alias === 'poke' ? 'Собрать поке': 'Собрать боул'}
                        />
                    }
                    {items.map(product => (
                        <MarketItem
                            key={product.id}
                            product={product}
                            categoryID={id}
                            withScroll={withScroll}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

const StartItem = ({id, alias, title, image,description,buttonText}) => {
    const dispatch = useDispatch();
    const bowl_constructor = useSelector(store => store.bowl_constructor)

    const openConstructor = () => {
        dispatch(setPaymentWithBonus(false))
        dispatch(setUsePromo(false))
        dispatch(setPromoDiscount(0))
        dispatch(setBonusDiscount(0))
        dispatch(setConstructorVisible(!bowl_constructor.view_constructor))
    }

    return (
        <div key={`product${id}`} className="item">
            <div
                onClick={() => openConstructor()}
                className="item__content start">
                <div className="item-img-container">
                    <img className="item-img-container__img" style={{objectFit: 'contain'}} src={image} alt="" />
                </div>
                <div className="item-title">
                    <div className="item-title__title">
                        {title}
                    </div>
                </div>
                <div className="item-desc">
                    <div className="item-desc__description text-description">
                        {description}
                    </div>
                </div>
            </div>
            <div className="item__footer">
                <div className="item__footer-weight"/>
                <button className={'start__button'}
                    onClick={() => openConstructor()}
                >
                    {buttonText}
                </button>
            </div>
        </div>
    )
}

MarketSection.propTypes = {
    items: PropTypes.instanceOf(Array).isRequired,
    title: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    withScroll: PropTypes.bool,
};
MarketSection.defaultProps = { withScroll: false, };
export default MarketSection;
