import React from "react";
import Button from "../Button";
import BonusIcon from "../icons/BonusIcon";

class MobileAuthArea extends React.Component {
    get enterButton() {
        const title = "Войдите в свой аккаунт";

        return (
            <Button
                buttonType="default"
                buttonTitle={title}
                buttonClass="btn mobile-auth-btn"
                type="button"
                onClickMethod={() => {
                    this.props.onShowUserProfile();
                }}
            >
                {title}
            </Button>
        );
    }

    get profileInfo() {
        return (
            <a className="inf-block-link" href="#/cabinet" onClick={this.props.onShowUserProfile}>
                <div className="inf-block">
                    <div className="mobile-inf">{this.props.profile.firstname}</div>
                    <div className="mobile-inf">+{this.props.profile.phone}</div>
                    <div className="mobile-inf mobile-bonuses">Побо-бонусов
                        <span className="mobile-inf mobile-bonuses-count">{this.props.profile.bonuses}</span>
                        <BonusIcon />
                    </div>
                </div>
            </a>
        );
    }

    render() {
        return (
            <main className="page mobile-page">
                <div className="container">
                    {this.props.profile.phone ? this.profileInfo : this.enterButton}
                </div>
            </main>
        );
    }
}

MobileAuthArea.propTypes = {
    onShowUserProfile: PropTypes.func.isRequired,
    profile: PropTypes.shape({
        phone: PropTypes.string,
        firstname: PropTypes.string,
        bonuses: PropTypes.number,
    }).isRequired,
};

export default MobileAuthArea;
