import React from "react";
import { useDispatch } from "react-redux";
import { addTopping, removeTopping } from "../../AC";
import "./toppings.scss";

export default function Topping({ product, smallCart, topping = {}, }) {
    const dispatch = useDispatch();

    const onAddToppingHandler = (e) => {
        e.preventDefault();
        dispatch(addTopping(product.id, topping));
    };

    const onRemoveToppingHandler = (e) => {
        e.preventDefault();
        dispatch(removeTopping(product.id, topping));
    };

    return (
        <div className={`topping ${smallCart && "row"}`}>
            <div className="topping__info">
                <h2 className="topping__info-name">
                    {topping.name}
                </h2>
                <span className="topping__info-price">{topping.price} ₽</span>
            </div>
            <div className="topping__btns">
                <button onClick={onRemoveToppingHandler} type="button" className="topping__btns-btn">
          -
                </button>
                <span className="topping__btns-count">{topping.amount}</span>
                <button onClick={onAddToppingHandler} type="button" className="topping__btns-btn">
          +
                </button>
            </div>
        </div>
    );
}

Topping.propTypes = {
    product: PropTypes.instanceOf(Object).isRequired,
    topping: PropTypes.instanceOf(Object),
    smallCart: PropTypes.boolean,
};

Topping.defaultProps = { topping: {}, smallCart: false, };
