import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputMask from "react-input-mask";
import Button from "../../Button";
import ValidationResult from "../ValidationResult";
import { postRequest, focusInputById } from "../../../helpers";

class BirthbayField extends React.Component {
    state = { birthday: this.props.birthday === null ? "" : this.props.birthday, }

    updateBirthday = (e) => {
        this.setState({ birthday: e.target.value, });
    }

    isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    onSaveBirthday = (e) => {
        e.preventDefault();

        postRequest("/profile/birthday", { birthday: this.state.birthday, })
            .then((res) => {
                if (res && res.data && res.data.success) {
                    this.props.setUserProfile(res.data.result);
                } else if (res.data.errors) {
                    const error = res.data.errors.map(it => it.message).join(" ");
                    this.setState({
                        edit: true,
                        error,
                    }, () => {
                        if (this.state.edit) {
                            focusInputById("birthday");
                        }
                    });
                }
            });
    }

    render() {
        return (
            <form className="input-stroke">
                <div className="input-stroke__label">День рождения</div>
                {this.props.birthday !== null // TODO: не должно быть
                    && (
<input
                        type="text"
                        className="personal-input_hidden"
                        id="birthday_static"
                        name="birthday_static"
                        autoComplete="off"
                        onChange={() => { }}
                        value={this.props.birthday}
                    >
                    </input>
)
                }
                {this.props.birthday === null /*  TODO: должно быть  */
                    && (
<div className="input-stroke__container ">
                        <InputMask
                            mask="99.99.9999"
                            value={this.state.birthday}
                            onChange={this.updateBirthday}
                            alwaysShowMask
                            className="personal-input"
                            id="birthday"
                        />
                        <ValidationResult error={this.state.error} />
                    </div>
)
                }
                {this.props.birthday === null /*  TODO: должно быть  */
                    && (
<div className="input-stroke__container">
                        <Button
                            buttonType="default"
                            buttonTitle="Сохранить"
                            buttonClass="personal-button"
                            onClickSubmit
                            onClickMethod={this.onSaveBirthday}
                        />
                    </div>
)
                }
                <div className="input-stroke__label_desc">В день рождения мы дарим скидки и подарки. Дата вводится один раз без возможности изменения в дальнейшем.</div>
            </form>
        );
    }
}

BirthbayField.propTypes = {
    setUserProfile: PropTypes.func.isRequired,
    birthday: PropTypes.string,
};

BirthbayField.defaultProps = { birthday: undefined, };
export default BirthbayField;
