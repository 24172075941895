import { connect } from "react-redux";
import Hits from "./Hits";
import { getHits, addToCart, removeFromCart } from "../../AC";

function mapStateToProps(state) {
    return { hits: state.hits, };
}
const mapDispatchToProps = {
    getHits,
    reduxAddToCart: addToCart,
    reduxRemoveFromCart: removeFromCart,
};

const ConnectedHits = connect(
    mapStateToProps,
    mapDispatchToProps
)(Hits);

export default ConnectedHits;
