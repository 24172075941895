import React from "react";
import Burger from "../icons/Burger";
import Button from "../Button";
import { formatCurrency } from "../../helpers";

class PreviousOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: this.props.isExpand, };
    }

    toggleSection() {
        this.setState({ isOpen: !this.state.isOpen, });
    }

    get button() {
        return (
            <div className="button-container">
                <Button
                    buttonType="default"
                    buttonTitle="Повторить заказ"
                    buttonClass="personal-button order-button"
                    onClickMethod={() => {
                        this.props.repeateOrder(this.props.item.id);
                    }}
                    onClickSubmit
                />
            </div>
        );
    }

    render() {
        return (
            <div className="previous-order">
                <div className="previous-order__inf">
                    <div className="previous-order__date">{this.props.item.order_date}</div>
                    <div className="previous-order__number">№{this.props.item.order_number}</div>
                    <div className="previous-order__price">{formatCurrency(parseInt(this.props.item.total_due, 10))}</div>
                </div>
                <div className="previous-order__controls">
                    <div className="burger-wrapper" onClick={this.toggleSection.bind(this)}>
                        <Burger />
                    </div>
                    <div className="description">{this.props.item.items.length} позиций</div>
                </div>
                {this.state.isOpen && this.props.item.items.map((product, i) => (<div key={'orderItem' + product.id} className="order__order-item">
                        <div className="order__item-container">
                            <div className="order__item-title">
                                {product.title}
                                {"\u00A0"}
                                {product.quantity > 1 ? "x" + product.quantity : ""}
                            </div>
                            <div className="order__item-desc">{product.description}</div>
                        </div>
                        <div className="order__price">{formatCurrency(parseInt(product.price, 10) || product.amount)}</div>
                    </div>))}
                {this.state.isOpen && this.button}
            </div>
        );
    }
}

export default PreviousOrder;
