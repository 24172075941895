import React from "react";
import classNames from "classnames";
import Market from "./Market";
import MarketSlides from "./MarketSlides/MarketSlides";

class MainContent extends React.Component {
    componentDidMount() {
        console.log(this.props.mobileSlides)
        window.onscroll = function () {
            const scrolled = window.pageYOffset || document.documentElement.scrollTop;
            const visibleWindow = document.documentElement.clientHeight;
            const windowHeight = document.body.scrollHeight;
            if (windowHeight * 0.75 < scrolled + visibleWindow) {
                window.onscroll = null;
            }
        };
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.showMobileAuth !== this.props.showMobileAuth) {
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    render() {
        return (
            <div className={classNames({ "hide-element": this.props.showMobileAuth, })}>
                <MarketSlides slides={this.props.slides} moreCssClass="desktop-only" />
                <MarketSlides slides={this.props.mobileSlides} moreCssClass="mobile-only" />
                <Market />
            </div>
        );
    }
}

MainContent.propTypes = {
    showMobileAuth: PropTypes.bool.isRequired,
    slides: PropTypes.instanceOf(Array).isRequired,
    mobileSlides: PropTypes.instanceOf(Array).isRequired,
};

export default MainContent;
