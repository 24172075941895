import { scroller } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import "./ProductCard.scss";
import Hits from "../Hits";
import vector from "./product-crumbs-vector.svg";
import mobileVector from "./product-crumbs-mobile-vector.svg";
import ItemControls from "../ItemControls";
import BasketMobile from "../BasketMobile";

function ProductCard({ showMobileAuth, product, }) {
    const { title, image, item_image, measure, description, specifications: spec, price, categories, meta_title, meta_description, meta_keywords, } = product;
    return (
        <>
            <Helmet>
                <title>{meta_title}</title>
                <meta name="keywords" content={meta_keywords} />
                <meta name="description" content={meta_description} />
            </Helmet>
            <div className={`product ${showMobileAuth && "hide-element"}`}>
                <div className="container">
                    <BasketMobile />
                    <div className="product-crumbs">
                        <span className="product-crumbs__home">
                            <Link to="/" className="product-crumbs__link">
                                Pobo
                            </Link>
                            <img className="product-crumbs__vector" src={vector} alt="" />
                        </span>
                        <span className="product-crumbs__categories-title">
                            <img className="product-crumbs__mobile-vector" src={mobileVector} alt="" />
                            <HashLink
                                to={`/#${categories[0].alias}`}
                                scroll={(el) => {
                                    const elName = el.getAttribute("name");
                                    scroller.scrollTo(elName, { offset: -80, });
                                }}
                                className="product-crumbs__link"
                            >
                                {categories[0].title}
                            </HashLink>
                        </span>

                        <span className="product-crumbs__title">
                            <img className="product-crumbs__vector" src={vector} alt="" />
                            {title}
                        </span>
                    </div>
                    <div className="product-card">
                        <div className="product-card__img" style={{ backgroundImage: `url(${item_image || image})`, }} alt="" />
                        <div className="product-card__desc">
                            <div className="product-card__title-container">
                                <h1 className="product-card__title">{title}
                                    <span className="product-card__title-comma">,</span>
                                </h1>
                                <div className="product-card__weight">{measure.value} {measure.unit}</div>
                            </div>
                            <h2 className="product-card__price">{`${price} ₽`}</h2>
                            <h3 className="product-card__composition">Состав</h3>
                            <div className="product-card__composition-text">{description}</div>
                            {spec[0] && (
                                <div className={'product-card__container'}>
                                    <h3 className="product-card__composition">КЖБУ на 1 порцию</h3>
                                    <div className="product-card__specs">
                                        <span className="product-card__spec">{spec[0].value} {spec[0].title}</span>
                                        <span className="product-card__spec">{spec[2].value} {spec[2].title}</span>
                                        <span className="product-card__spec">{spec[1].value} {spec[1].title}</span>
                                        <span className="product-card__spec">{spec[3].value} {spec[3].title}</span>
                                    </div>
                                </div>
                            )
                            }
                            <ItemControls product={product} />
                            {/* buttonTitle={`Добавить в корзину ${price} ₽`} */}


                        </div>
                    </div>
                    <Hits title="Хиты" />
                </div>
            </div>
        </>
    );
}

ProductCard.propTypes = {
    product: PropTypes.shape({ id: PropTypes.number.isRequired, }).isRequired,
    showMobileAuth: PropTypes.bool.isRequired,
};

export default ProductCard;
