import React from 'react';

const RubleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <circle cx="9" cy="9" r="9" fill="#8DD161"/>
        <path d="M14.4016 6.83155C14.4016 6.35224 14.3148 5.91325 14.1414 5.52578C13.9704 5.14503 13.7126 4.80011 13.3706 4.50222C13.0286 4.20434 12.6296 3.97812 12.1885 3.82806C11.7425 3.67576 11.2344 3.59961 10.6793 3.59961H5.89885V8.65021V8.7398V8.96377H5.65103H5.55191H3.60156V10.0657H5.55439H5.65351H5.90133V10.2897V10.3793V11.3379V11.4275V11.6515H5.65351H5.55439H3.60156V12.5809H5.55439H5.65351H5.90133V12.8049V12.8945V14.3996H7.28169V12.8945V12.8049V12.5809H7.52951H7.62864H12.1018V11.6492H7.62616H7.52703H7.27921V11.4252V11.3357V10.377V10.2875V10.0635H7.52703H7.62616H10.6793C11.2344 10.0635 11.7425 9.98733 12.1885 9.83503C12.6296 9.68497 13.0286 9.45875 13.3706 9.16087C13.7126 8.86299 13.9704 8.51807 14.1414 8.13507C14.3123 7.74984 14.4016 7.31085 14.4016 6.83155ZM12.2753 8.37472C11.8218 8.76668 11.2245 8.96377 10.5009 8.96377H7.62616H7.52703H7.27921V8.7398V8.65021V5.01512V4.92553V4.70156H7.52703H7.62616H10.4984C11.222 4.70156 11.8193 4.9009 12.2728 5.29061C12.7313 5.68704 12.9642 6.20442 12.9642 6.83155C12.9667 7.46091 12.7337 7.97829 12.2753 8.37472Z" fill="white"/>
        </svg>
    );
};

export default RubleIcon;
