import React from 'react';

const BonusIcon = () => {
    return (
        <svg width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12.2949H4.25987V25.0019H0V12.2949Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.15871 7.73533C11.8643 7.73533 14.0576 9.86875 14.0576 12.5005C14.0576 15.1322 11.8643 17.2656 9.15871 17.2656C6.45315 17.2656 4.25987 15.1322 4.25987 12.5005C4.25987 9.86875 6.45315 7.73533 9.15871 7.73533ZM9.15871 3.59174C14.2169 3.59174 18.3174 7.58031 18.3174 12.5005C18.3174 17.4206 14.2169 21.4092 9.15871 21.4092C4.10049 21.4092 0 17.4206 0 12.5005C0 7.58031 4.10049 3.59174 9.15871 3.59174Z" fill="currentColor"/>
            <path d="M0 12.709H4.25987V0.00196934H0V12.709Z" fill="currentColor"/>
        </svg>
    );
};

export default BonusIcon;