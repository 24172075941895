import React from "react";
import classNames from "classnames";
import { getRequest, formatCurrency } from "../../../helpers";
import RubleIcon from "../../icons/RubleIcon";
import ChooseHint from "./ChooseHint";
import Checkbox from "../../Elements/Checkbox";
import RemoveItemButton from "../../icons/RemoveItemButton";
import {removeFromCart} from "../../../AC";

class ConstructorArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible_options: false,
            groups: [],
            activeTab: 0,
            amount: 0,
            defaultAmount: 0,
        };

        this.aliases = {};
        this.editConstructor = this.editConstructor.bind(this);
    }

    editConstructor(){
        let data = this.props.bowl_constructor.data.itemData;
        if (this.props.bowl_constructor.data.itemData !== undefined) {
            data.groups.forEach(group => {
                group.ingredients.forEach(item => {
                    for (let i = 0; i < item.count; i++)
                        this.onSelectItem(item, group.alias,  false, true);
                })
            })
        }
    }

    componentWillMount() {
        const self = this;
        getRequest("/ingredients")
            .then((res) => {
                const state = {
                    groups: res.data.result.groups,
                    amount: parseInt(res.data.result.set_fixed_price, 10),
                    defaultAmount: parseInt(res.data.result.set_fixed_price, 10),
                };

                res.data.result.groups.map((item, i) => {
                    this.aliases[item.alias] = i;
                    state[item.alias] = [];
                });

                self.setState(state);
                this.editConstructor()
            });
    }

    renderGroupsSelections() {
        return this.state.groups.map((group, i) => {
            const haveElems = this.state[group.alias].length > 0;
            return (
                <div className="block" key={group.alias + i}>
                    <span className={`block__title ${!haveElems && "block__title--disabled"}`}>{i + 1}. {group.title}</span>
                    <ul className="block__list">
                        {haveElems ? this.renderGroupSelections(group.alias) : this.renderEmptySelections(group)}
                    </ul>
                </div>
            );
        });
    }

    renderEmptySelections(group) {
        return (
            <li className="block__list-item block__list-item--disable" key={"empty" + group.id}>
                <div className="title">Не выбрано</div>
            </li>
        );
    }

    renderGroupSelections(group) {
        return this.state[group].map((item) => {
            const itemSumm = (item.paid && item.price) * (item.count - item.free);
            const itemSummText = itemSumm ? `${itemSumm} ₽` : false;
            const weightText = `${item.measure.value * item.count} г`;
            const xCount = item.count > 1 ? `x${item.count}` : false;
            return (
                <li className="block__list-item" key={"product" + item.id}>
                    <div className="block__list-item__title--text">
                        {item.title}
                    </div>
                    <span className="block__list-item__count">{xCount}</span>
                    <span className="block__list-item__weight">{weightText}</span>
                    <div className="block__list-item__price">{itemSummText}</div>
                    {this.getGroup(group).rules.required
                        ? <div className="trash" />
                        : <div className="trash" onClick={() => { this.deleteItem(item, group); }}>{this.trashButton(true)}</div>}
                </li>
            );
        });
    }

    deleteItem(item, group) {
        const state = {};
        const groupState = this.state[group].slice();
        const index = this.getIndexInCart(group, item.id);

        groupState.splice(index, 1);
        state[group] = groupState;

        this.setState({ ...state, }, () => {
            this.setNewSelection(group, this.state.groups[this.aliases[group]].rules);
            this.setState({ amount: this.amount, });
        });
    }

    renderGroupsSelectionsMobile() {
        return this.state.groups.filter(group => this.state[group.alias].length).map((group, i) => (
            this.renderGroupSelectionsMobile(group.alias)
        ));
    }

    renderGroupSelectionsMobile(group) {
        return this.state[group].map((item, i) => (
            <li className="block__list-item" key={"product" + item.id}>
                {this.getGroup(group).rules.required ? <div className="trash--disabled">{this.trashButton(false)}</div> : <div className="trash" onClick={() => { this.deleteItem(item, group); }}>{this.trashButton(true)}</div>}
                <div className="title">{item.title} {item.count > 1 ? <span className="count">x{item.count}</span> : ""}</div>
            </li>
        ));
    }

    renderTotalMobile() {
        const activeClass = this.state.base && this.state.base.length ? "" : "btn--grey-inverse";

        return (
            <div className="constructor__total mobile-only">
                <span className="constructor__total-header">Выбранные ингредиенты</span>
                <div className="constructor__total-content">
                    <ul className="block__list">
                        {this.renderGroupsSelectionsMobile()}
                    </ul>
                </div>
                <div className="constructor__total-footer">
                    <span className="cost"><span className="cost__label">Стоимость</span> <span className="cost__amount">{formatCurrency(this.state.amount)}</span></span>
                    <button
                        className={`btn ${activeClass}`}
                        onClick={() => {
                            if (this.state.base && this.state.base.length) {
                                this.addToCart();
                                window.location.href = "#/basket";
                            }
                        }}
                    >В корзину
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <main className={classNames("page constructor-page", { "hide-element": this.props.showMobileAuth, })}>
                <div className="constructor container">
                    <div className="constructor__section">
                        <div className="constructor__wizard">
                            <div className={"constructor__content"} >
                                {!(this.state.groups.length === 0 || this.state.groups[0] === undefined) && this.constructorBowlOptions}
                            </div>
                            <div className={'constructor__background'} style={{width: '100vw', height: '100vh', background: 'none'}} onClick={() => {
                                this.props.setConstructorVisible(!this.props.bowl_constructor.view_constructor)
                                this.props.setConstructorData({});
                            }
                            }/>
                        </div>
                    </div>
                    <div className={'constructor__background'} style={{width: '100vw', height: '100vh'}} onClick={() =>
                        this.props.setConstructorVisible(!this.props.bowl_constructor.view_constructor)
                    }/>
                    {/*{this.renderTotalMobile()}*/}
                </div>
            </main>
        );
    }


    setVisibleOptions(flag){
        this.setState({visible_options: flag})
    }

    renderTabListItem(item, group, isbase) {
        const price = !!item.price && item.paid || !!item.price && this.showPrice(group) || item.only_paid ? ("+" + formatCurrency(item.price)) : "";

        return (
            <li
                className={"constructor__item " + this.getActiveClass(item, group)}
                key={"boulItems" + item.id}
                onClick={() => {
                    this.onSelectItem(item, group);
                    this.setVisibleOptions(!this.state.visible_options);
                }}
            >
                <span className="constructor__count">{this.getCount(group, item)}</span>
                <div className="constructor__img-wrapp">
                    <img className="constructor__img" src={item.image} alt="" />
                </div>
                <div className="constructor__text">{item.title}</div>
                <div className="constructor__price">{price}</div>
            </li>
        );
    }


    get constructorBowlOptions(){
        let price = this.state.defaultAmount;
        document.querySelectorAll('body')[0].style.overflow = 'hidden';
        const {bowl_constructor} = this.props;
        return(
            <div className={'constructor__options'}>
                <button className={'constructor__options_close-button'} onClick={() =>this.props.setConstructorVisible(!bowl_constructor.view_constructor)}>
                    <RemoveItemButton />
                </button>
                <div className={'constructor__options_container'}>
                    <h1 className="page__title container market-section market-section__title constructor-section constructor-section__title">Конструктор - Собери свой боул</h1>
                    <p className="constructor-page__text text-gray">Боул состоит из основы — это сложный углевод, помогающий оставаться сытым долгое время, соуса, придающего блюду пикантный вкус, рыбы, морепродуктов или альтерантивных видов белка для вегетарианцев и овощного топпинга, который добавит витаминов
                        к вашему боулу.
                    </p>
                    <div className="constructor__header">
                        <p className={" constructor__title--active"}>Выберите опции</p>
                    </div>
                    {this.state.groups.map((group, index) => {
                        const alias = group.alias;
                        const items = this.state[alias];
                        const rules = group.rules;
                        const classes = "constructor__count-hint-mobile";
                        return(
                            <div className={'constructor__options-position options-position'}>
                                <ChooseHint rules={rules} items={items} classes={classes} />
                                <p className={'options-position__header'}>{group.title}</p>
                                <div className={'options-position__content'}>
                                    {group.items.map((item, indexID) => {
                                        let count = 0;
                                        this.state[group.alias].map(element => {
                                            if (element.id === item.id) {
                                                count = element.count;
                                                price += count*element.price;
                                            }
                                        })
                                        return(
                                            <div className={'options-position__content-position'}>
                                                <div className={'options-position__content-element'}>
                                                    <Checkbox
                                                        checked={ count !== 0}
                                                        id={'constructor-checkbox-'+index+'-'+indexID}
                                                        onChange={(e) => {
                                                            this.onSelectItem(item, group.alias,  true);
                                                        }}
                                                    />
                                                    <p className={'options-position__position-title'}>{item.title}</p>
                                                </div>
                                                <div className={'options-position__content-element'}>
                                                    <p className={'options-position__position-price'}>+ {item.price} ₽</p>
                                                    {group.alias !== 'base' &&
                                                    <div className={'options-position__position-counter'}>
                                                        <button className={'options-position__position-button'}
                                                                onClick={() => {
                                                                    if (count > 0)
                                                                        this.onSelectItem(item, group.alias, false, false);
                                                                }}>
                                                            <svg width="18" height="4" viewBox="0 0 18 4" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M17.5 2C17.5 2.82843 16.8284 3.5 16 3.5L2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.499999 2 0.499999L16 0.5C16.8284 0.5 17.5 1.17157 17.5 2Z"
                                                                      fill="#292929"/>
                                                            </svg>
                                                        </button>
                                                        <p className={'options-position__position-value'}>{count}</p>
                                                        <button className={'options-position__position-button'}
                                                                onClick={() => {
                                                                    if (count <5)
                                                                     this.onSelectItem(item, group.alias, false, true);
                                                                }}>
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M10.5 2C10.5 1.17157 9.82843 0.5 9 0.5C8.17157 0.5 7.5 1.17157 7.5 2V7.5H2C1.17157 7.5 0.5 8.17157 0.5 9C0.5 9.82843 1.17157 10.5 2 10.5H7.5V16C7.5 16.8284 8.17157 17.5 9 17.5C9.82843 17.5 10.5 16.8284 10.5 16V10.5H16C16.8284 10.5 17.5 9.82843 17.5 9C17.5 8.17157 16.8284 7.5 16 7.5H10.5V2Z"
                                                                      fill="#292929"/>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                    <p className={'constructor__result'}>
                        Итого: <span className={'constructor__result_price'}>{price} ₽</span>
                    </p>
                    {this.state.error_message !== undefined && <p className={'constructor__error-message'}>{this.state.error_message}</p>}
                    <button onClick={() => {
                        let flag = true;
                        let bad_groups = 'Не выбраны опции для пунктов: ';
                        this.state.groups.forEach(group =>{
                            if (this.state[group.alias].length === 0){
                                flag = false;
                                bad_groups +=  group.title+', '
                            }
                        });
                        if (flag) {
                            this.props.setConstructorVisible(false);
                            this.addToCart();
                            this.props.removeFromCart(this.props.bowl_constructor.data.itemData.id);
                            this.props.setConstructorData({});
                        }
                        else {
                            bad_groups = bad_groups.substring(0, bad_groups.length-2);
                            this.setState({
                                error_message: bad_groups
                            })
                        }
                    }} className={'constructor__add-basket_button'}>
                        Добавить в корзину
                    </button>
                </div>
            </div>
        )
    }

    onSelectItem(item, group, change, plus) {
        const state = {};
        const rules = this.getGroup(group).rules;
        let groupState = this.state[group].slice(); // Выбранные элементы не учитывает повторы
        let groupCount = 0;
        let itemCount = 0;
        const itemCopy = Object.assign({}, item);
        let index = this.getIndexInCart(group, itemCopy.id);
        groupState.map((elem) => {
            groupCount += elem.count;

            if (elem.id === itemCopy.id) {
                itemCount = elem.count;
            }
        });
        if (group !== 'base') {
            if (change) {
                if (itemCount > 0) {
                    groupState.splice(index, 1);
                } else {
                    itemCopy.count = 1;
                    groupState.push(itemCopy);
                }
            } else {
                if (itemCount > 0) {
                    if (plus) {
                        if (groupState[index].count < 5)
                            groupState[index].count += 1;
                    } else {
                        if (groupState[index].count > 0)
                            groupState[index].count -= 1;
                    }
                }else {
                    itemCopy.count = 1;
                    groupState.push(itemCopy);
                }
            }
        }else{
            groupState = [];
            itemCopy.count = 1;
            groupState.push(itemCopy);
        }
        state[group] = groupState;
        state["empty" + group] = !groupState.length;
        this.setState(state, () => {
            this.setNewSelection(group, rules);
            this.setState({ amount: this.amount, });
        });
    }

    setNewSelection(group, rules) {
        const result = [];
        const selected = this.state[group].slice();
        let maxFree = rules.min_items;

        this.state[group].map((item) => {
            item.free = 0;
            item.paid = 0;
        });

        selected.map((item, i) => {
            if (item.only_paid) {
                item.paid = item.count;
                item.free = 0;
            } else if (maxFree > 0) {
                if (item.count >= maxFree) {
                    item.free = maxFree;
                    item.paid = item.count - maxFree;
                    maxFree = 0;
                } else {
                    item.free = item.count;
                    item.paid = 0;
                    maxFree -= item.count;
                }
            } else {
                item.paid = item.count;
                item.free = 0;
            }
            result.push(item);
        });

        const groupState = {};
        groupState[group] = result;
        this.setState(groupState);

        return result;
    }


    addToCart() {
        this.setVisibleOptions(!this.state.visible_options);
        const config = {
            amount: this.state.amount,
            title: "Свой поке",
            type: "set",
            id: Math.ceil(new Date().getTime()/100),
            groups: [],
        };
        this.state.groups.map((item, i) => {
            config.groups.push({
                id: item.id,
                title: item.title,
                ingredients: this.state[item.alias] ? this.state[item.alias].filter(item => item.count > 0) : [],
                alias: item.alias,
            });
        });
        this.props.addToCart(config);
    }

    nextStep() {
        this.setState({ activeTab: this.state.activeTab + 1, });
    }

    selectStep(i) {
        this.setState({ activeTab: i, });
    }


    prevStep() {
        this.setState({ activeTab: this.state.activeTab - 1, });
    }

    getGroup(alias) {
        return this.state.groups[this.aliases[alias]];
    }

    get renderTabsHeaders() {
        const activeTab = this.state.activeTab;
        const groupsLen = this.state.groups.length;
        if (groupsLen) {
            const alias = this.state.groups[activeTab].alias;
            const items = this.state[alias];
            const rules = this.state.groups[activeTab].rules;

            return this.state.groups.map((item, index) => {
                const isActive = activeTab === index;
                const lastIndex = groupsLen === index + 1;
                const classes = lastIndex ? "right-0" : "";
                const className = "constructor__title"
                    + (activeTab > index ? " constructor__title--passed" : "")
                    + (isActive ? " constructor__title--active" : "");

                return (
                    <div className={className} key={item.id} onClick={() => { this.selectStep(index); }}>
                        {item.title}
                        {isActive && <ChooseHint rules={rules} items={items} classes={classes} />}
                    </div>
                );
            });
        }
    }

    get renderTabsContents() {
        return this.state.groups.map((group, index) => {
            const alias = group.alias;
            const items = this.state[alias];
            const rules = group.rules;
            const classes = "constructor__count-hint-mobile";

            const className = "constructor__content-tab"
                + (this.state.activeTab !== index ? " constructor__content-tab--hidden" : "");

            const listItems = group.items.map(item => this.renderTabListItem(item, group.alias, group.title));

            return (
                // constructor__content--hidden
                <div className={className} key={group.alias}>
                    <ChooseHint rules={rules} items={items} classes={classes} />
                    <h2 className="constructor-list__title mobile-only">{group.title}</h2>
                    <ul className="constructor__list">
                        {listItems}
                        {group.rules.required ? "" : this.renderSkipItem(group.cancel_label, group.alias)}
                    </ul>
                    {index === 0 ? "" : <button className="btn" onClick={() => this.prevStep()}>Назад</button>}
                    {index === this.state.groups.length - 1 ? this.getButtonToCart() : this.getButtonNext(index)}
                </div>
            );
        });
    }



    getButtonNext(i) {
        return (
            <button className="btn" onClick={() => this.nextStep()}>
                {this.state.groups[i + 1].select_label ? this.state.groups[i + 1].select_label : "Выбрать"}
            </button>
        );
    }

    getButtonToCart() {
        const activeClass = this.state.base && this.state.base.length ? "" : "btn--grey-inverse";
        return (
            <button
                className={`btn ${activeClass}`}
                onClick={() => {
                    if (this.state.base && this.state.base.length) {
                        this.addToCart();
                        window.location.href = "#/basket";
                    }
                }}
            >В корзину
            </button>
        );
    }

    getCount(group, element) {
        let count = [];

        if (this.state[group] && Object.keys(this.state[group]).length) {
            this.state[group].map((item) => {
                if (item.id === element.id) {
                    if (item.free && item.paid) {
                        count.push(<span>
                            <span className="constructor__count-container--free">{item.free} +</span>
                            {this.getIcons(item.paid)}
                                   </span>);
                    } else if (item.paid && !item.free && item.price) {
                        count = this.getIcons(item.paid);
                    } else if (item.free && !item.paid && item.count > 1) {
                        count.push("x" + item.count);
                    }
                }
            });
        }

        return count;
    }

    getIcons(count) {
        const icons = [];

        for (let i = 0; i < count; i++) {
            icons.push(<span className="constructor__crown"><RubleIcon /></span>);
        }

        return icons;
    }

    showPrice(group) {
        let count = 0;

        this.state[group] && this.state[group].map((item, i) => {
            count += item.count;
        });

        return this.state.groups.length && this.state.groups[this.aliases[group]].rules.min_items <= count && !this.state["empty" + group];
    }



    renderSkipItem(label, group) {
        return (
            <li
                className={"constructor__item " + (this.state["empty" + group] ? "constructor__item--active" : "")}
                onClick={() => { this.onSkipSelectionItems(group); }}
                key={"without" + group}
            >
                <div className="constructor__img-wrapp">
                    <div className="constructor__img--skip">
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.8" />
                            <rect width="28" height="4" rx="1" transform="matrix(1 0 0.0155768 0.999879 14 26)" fill="#8DD161" />
                        </svg>
                    </div>
                    <img className="constructor__img" src="" alt="" />
                </div>
                <div className="constructor__text">{label || "Без ингредиента"}</div>
            </li>
        );
    }

    onSkipSelectionItems(group) {
        const state = {};

        this.state[group].map((item) => {
            item.free = 0;
            item.paid = 0;
        });

        state[group] = [];
        state["empty" + group] = !this.state["empty" + group];

        this.setState(state, () => {
            this.setState({ amount: this.amount, });
        });
    }





    get amount() {
        let overPaid = 0;

        Object.keys(this.aliases).map((i) => {
            this.state[i].map((item) => {
                overPaid += item.price*item.count;
            });
        });

        return this.state.defaultAmount + overPaid;
    }

    getIndexInCart(group, id) {
        let index = -1;

        this.state[group].map((item, i) => {
            if (item.id === id) {
                index = i;
            }
        });

        return index;
    }

    getActiveClass(item, group) {
        return this.getIndexInCart(group, item.id) !== -1 ? "constructor__item--active" : "";
    }

    trashButton(enabled) {
        const fillColor = enabled ? "#8DD161" : "#CCCCCC";
        return (
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9612 3.55728L12.6099 2.50315C12.4762 2.10203 12.1 1.83461 11.6772 1.83461H8.72386V0.870662C8.72386 0.388688 8.33214 0 7.85338 0H5.1487C4.66683 0 4.27823 0.391798 4.27823 0.870662V1.83461H1.32484C0.902034 1.83461 0.528974 2.10514 0.395295 2.50626L0.0408876 3.55728C-0.039942 3.79671 0.000472799 4.06413 0.149697 4.26935C0.298921 4.47458 0.535192 4.59585 0.790116 4.59585H1.15696L1.96526 14.596C2.02432 15.3392 2.65542 15.9207 3.39843 15.9207H9.76843C10.5114 15.9207 11.1425 15.3392 11.2016 14.596L12.0099 4.59585H12.212C12.4638 4.59585 12.7032 4.47458 12.8524 4.26935C12.9985 4.06413 13.0389 3.79982 12.9612 3.55728ZM5.21088 0.932852H7.79432V1.83461H5.21088V0.932852ZM10.2721 14.5214C10.2503 14.7826 10.0296 14.9878 9.76843 14.9878H3.39843C3.13728 14.9878 2.91656 14.7826 2.8948 14.5214L2.09272 4.59585H11.0741L10.2721 14.5214ZM0.989082 3.663L1.2782 2.79856C1.28442 2.7799 1.30307 2.76435 1.32484 2.76435H11.6804C11.7021 2.76435 11.7208 2.77679 11.727 2.79856L12.0161 3.663H0.989082Z" fill={fillColor} />
                <path d="M9.27283 5.14774C9.2635 5.14774 9.25728 5.14774 9.24796 5.14774C9.00236 5.14774 8.79407 5.34053 8.78163 5.58929L8.34329 13.9974C8.33085 14.2555 8.52671 14.4731 8.78474 14.4887C9.04277 14.5011 9.26039 14.3052 9.27594 14.0471L9.71428 5.63904C9.72672 5.38095 9.53086 5.16328 9.27283 5.14774Z" fill={fillColor} />
                <path d="M4.21902 5.58929C4.20658 5.34053 3.99829 5.14774 3.75269 5.14774C3.74337 5.14774 3.73404 5.14774 3.72782 5.14774C3.46979 5.16017 3.27393 5.38095 3.28637 5.63904L3.74648 14.0471C3.75891 14.3052 3.97964 14.5011 4.23767 14.4887C4.49571 14.4763 4.69156 14.2555 4.67913 13.9974L4.21902 5.58929Z" fill={fillColor} />
                <path d="M6.51024 5.15318C6.2522 5.15318 6.04391 5.36152 6.04391 5.61961L6.04391 14.0277C6.04391 14.2858 6.2522 14.4941 6.51024 14.4941C6.76827 14.4941 6.97656 14.2858 6.97656 14.0277L6.97656 5.61961C6.97656 5.36152 6.76827 5.15318 6.51024 5.15318Z" fill={fillColor} />
            </svg>
        );
    }
}

export default ConstructorArea;
