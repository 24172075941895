import React from 'react';

const EmptyBasketIcon = () => {
    return (
        <svg width="53" height="46" viewBox="0 0 53 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8456 30.6664H44.6152C45.2992 30.6664 45.9016 30.2166 46.0854 29.5522L52.211 8.08571C52.3437 7.62571 52.2519 7.12483 51.966 6.73639C51.6801 6.34795 51.2207 6.12306 50.7409 6.12306H13.3131L12.2207 1.18577C12.0573 0.500885 11.4346 0 10.7199 0H1.53141C0.684032 0 0 0.684883 0 1.53332C0 2.38176 0.684032 3.06664 1.53141 3.06664H9.49477C9.68875 3.94574 14.7322 26.6695 15.0283 27.978C13.405 28.6833 12.2615 30.3086 12.2615 32.1997C12.2615 34.7348 14.3238 36.7997 16.8558 36.7997H44.6254C45.4728 36.7997 46.1568 36.1148 46.1568 35.2664C46.1568 34.4179 45.4728 33.733 44.6254 33.733H16.8456C15.9982 33.733 15.3141 33.0482 15.3141 32.1997C15.3141 31.3513 15.9982 30.6664 16.8456 30.6664ZM48.7092 9.19992L43.4615 27.5998H18.0707L13.9869 9.19992H48.7092Z" fill="#474A51"/>
            <path d="M15.3125 41.4007C15.3125 43.9358 17.3748 46.0007 19.9067 46.0007C22.4387 46.0007 24.501 43.9358 24.501 41.4007C24.501 38.8657 22.4387 36.8008 19.9067 36.8008C17.3748 36.8008 15.3125 38.8657 15.3125 41.4007ZM19.9067 39.8674C20.7541 39.8674 21.4382 40.5523 21.4382 41.4007C21.4382 42.2492 20.7541 42.9341 19.9067 42.9341C19.0594 42.9341 18.3753 42.2492 18.3753 41.4007C18.3753 40.5523 19.0594 39.8674 19.9067 39.8674Z" fill="#474A51"/>
            <path d="M36.957 41.4007C36.957 43.9358 39.0193 46.0007 41.5513 46.0007C44.0832 46.0007 46.1455 43.9358 46.1455 41.4007C46.1455 38.8657 44.0832 36.8008 41.5513 36.8008C39.0193 36.8008 36.957 38.8657 36.957 41.4007ZM41.5513 39.8674C42.3987 39.8674 43.0827 40.5523 43.0827 41.4007C43.0827 42.2492 42.3987 42.9341 41.5513 42.9341C40.7039 42.9341 40.0199 42.2492 40.0199 41.4007C40.0199 40.5523 40.7039 39.8674 41.5513 39.8674Z" fill="#474A51"/>
        </svg>
    );
};

export default EmptyBasketIcon;
