import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DadataSuggestions from "react-dadata-suggestions";
import axios from "axios";
import {formatCurrency, postRequest} from "../../helpers";
import Button from "../Button";
import EditItem from "../icons/EditItem";
import { connect } from "react-redux";
import ValidationResult from "./ValidationResult";
import DeleteIcon from "../icons/DeleteIcon";
import {requestUri} from "../../../common/config";
import {reloadData} from "../../AC";

class Addresses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addresses: this.props.addresses ? JSON.parse(JSON.stringify(this.props.addresses)) : [],
            addNewAddress: this.props.addresses && !this.props.addresses.length, // Если нет ни одного адреса сразу показываем форму
            basketCost: props.basketCost ? props.basketCost : 0,
            newAddress: {},
            editedAddresses: {},
            errors: {},
            setNewAddress: false
        };
    }

    onEditToggle(i, value) {
        const addresses = JSON.parse(JSON.stringify(this.state.addresses));
        const id = addresses[i].id;
        const editedAddresses = Object.assign({}, this.state.editedAddresses);

        if (!editedAddresses[id]) {
            editedAddresses[id] = Object.assign({}, addresses[i]);
        }

        this.setState({ editedAddresses, }, () => {
            addresses[i].edit = value;
            this.setState({ addresses, });
        });
    }

    onAddToggle(value) {
        this.setState({ addNewAddress: value, });
    }

    checkHouse(adress, index) {
        if (!adress.house) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [index]: "Необходимо указать улицу и номер дома",
                },
            });
            return false;
        }
        return true;
    }

    onSave(index) {
        const id = this.state.addresses[index].id;
        const self = this;
        if (!this.checkHouse(this.state.editedAddresses[id], index)) return;
        postRequest("/addresses/update?id=" + id, this.state.editedAddresses[id])
            .then((res) => {
                const errors = Object.assign({}, self.state.errors);
                const addresses = self.state.addresses.slice();
                let position = -1;

                addresses.map((item, i) => {
                    if (item.id === id) {
                        position = i;
                    }
                });

                if (res.data.success) {
                    const editedAddresses = Object.assign({}, res.data.result);
                    delete editedAddresses[id];

                    errors[index] = "";
                    addresses[position] = res.data.result;

                    self.setState({
                        addresses,
                        editedAddresses,
                    });

                    self.props.setAddresses(addresses);
                } else if (res.data.errors) {
                    errors[index] = res.data.errors.map(it => it.message).join(" ");
                    self.setState({ errors, });
                }
            });
    }

    onSaveNew() {
        const self = this;
        if (!this.checkHouse(this.state.newAddress, "new")) return;
        postRequest("/addresses/add", this.state.newAddress)
            .then((res) => {
                const errors = Object.assign({}, self.state.errors);

                if (res.data.success) {
                    errors.new = "";

                    const addresses = self.state.addresses.slice();
                    addresses.push(res.data.result);
                    self.setState({
                        addresses,
                        addNewAddress: false,
                        newAddress: {},
                        errors,
                        setNewAddress: true
                    });
                    if (addresses.length === 1) this.setAddress(addresses[0]);
                    else this.setAddress(addresses[addresses.length-1])
                } else if (res.data.errors) {
                    errors.new = res.data.errors.map(it => it.message).join(" ");
                    self.setState({ errors, });
                }
            });
    }

    onSetField(event, field, i) {
        return (i === undefined) ? this.onChangeNewField(event, field) : this.onChangeField(event, field, i);
    }

    onSaveAddress(i) {
        return (i === undefined) ? this.onSaveNew() : this.onSave(i);
    }

    onCancelEdit(i) {
        const errors = Object.assign({}, this.state.errors);
        errors[(i === undefined) ? "new" : i] = "";
        this.setState({ errors, });

        return (i === undefined) ? this.onAddToggle(false) : this.onEditToggle(i, false);
    }

    onChangeField(event, field, i) {
        const addresses = this.state.addresses.slice();
        const address = addresses.length ? Object.assign({}, addresses[i]) : {};
        const id = address.id;
        const editedAddresses = Object.assign({}, this.state.editedAddresses);

        editedAddresses[id][field] = event.currentTarget.value;
        this.setState({ editedAddresses, });
    }

    onChangeNewField(event, field) {
        const address = Object.assign({}, this.state.newAddress);

        address[field] = event.currentTarget.value;
        this.setState({ newAddress: address, });
    }

    setAddressValue(value, lat, lon, street, street_type_full, house, restaurant_id, i) {
        return (i === undefined) ? this.setNewAddressValue(value, lat, lon, street, street_type_full, house, restaurant_id) : this.changeAddressValue(value, lat, lon, street, street_type_full, house, restaurant_id, i);
    }

    setNewAddressValue(value, lat, lon, street, street_type_full, house, restaurant_id) {
        const address = Object.assign({}, this.state.newAddress);
        address.address = value;
        address.geo_lat = lat;
        address.geo_lon = lon;
        address.street = street;
        address.street_type_full = street_type_full;
        address.house = house;
        address.restaurant_id = restaurant_id;
        this.setState({ newAddress: address, });
    }

    changeAddressValue(value, lat, lon, street, street_type_full, house, restaurant_id, i) {
        const addresses = this.state.addresses.slice();
        const address = addresses.length ? Object.assign({}, addresses[i]) : {};
        const id = address.id;
        const editedAddresses = { ...this.state.editedAddresses, };
        editedAddresses[id].address = value;
        editedAddresses[id].geo_lat = lat;
        editedAddresses[id].geo_lon = lon;
        editedAddresses[id].street = street;
        editedAddresses[id].street_type_full = street_type_full;
        editedAddresses[id].house = house;
        editedAddresses[id].restaurant_id = restaurant_id;
        // let element = this.state.addresses.filter(el => el.id === id)[0]
        // let copy_arr = [...this.state.addresses]
        // copy_arr[copy_arr.indexOf(element)] = editedAddresses[id]
        this.setState({editedAddresses: editedAddresses})

    }

    sendCoordinatesRequest(unrestricted_value, value, street, street_type_full, house, restaurant_id, i) {
        let lat = null;
        let lon = null;
        axios.post(
            "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
            {
                query: unrestricted_value, count: 1, restrict_value: true, locations: { kladr_id: "7800000000000", },
            },
            { headers: { Authorization: "Token " + this.props.dadataToken, }, }
        ).then((res) => {
            if (res.data.suggestions && res.data.suggestions[0].data !== undefined) {
                if (res.data.suggestions[0].data.geo_lat !== null && res.data.suggestions[0].data.geo_lon !== null) {
                    lat = res.data.suggestions[0].data.geo_lat;
                    lon = res.data.suggestions[0].data.geo_lon;
                }
            }
            this.setAddressValue(value, lat, lon, street, street_type_full, house, restaurant_id, i);
        });
    }

    deleteAddressItem(id){
        postRequest('/addresses/delete/?id='+id,{})
            .then(res => {
                if (res.status === 200){
                    let addresses = this.state.addresses;
                    addresses = addresses.filter(item => item.id !== id)
                    this.props.reloadData(true);
                    this.setState({
                        addresses: addresses
                    })
                }
            });
    }

    renderEdit(i) {
        let address = {};
        let editAddressTitle;

        if (i !== undefined && this.state.editedAddresses[this.state.addresses[i].id]) {
            address = this.state.editedAddresses[this.state.addresses[i].id];
            editAddressTitle = "Редактирование адреса";
        } else {
            address = this.state.newAddress;
            editAddressTitle = "Новый адрес";
        }

        const dadataRequestOptions = {
            locations: [{ kladr_id: "7800000000000", },{ kladr_id: "4700000000000", }],
            locations_boost: [
                { kladr_id: "7800000000000", }
            ],
            restrict_value: true,
        };
        return (
            <div>
                <h2 className="edit-address-title">{editAddressTitle}</h2>
                <div className="adress-inputs-container">

                    <div className="address-container">
                        <div className="input-stroke__label address-title">Адрес</div>
                        <DadataSuggestions
                            name="address"
                            autoComplete="off"
                            placeholder="выберите улицу, дом"
                            query={address.address}
                            token={this.props.dadataToken}
                            geolocation={false}
                            specialRequestOptions={dadataRequestOptions}
                            onSelect={(suggestion) => {
                                let value = "";
                                let lat = null;
                                let lon = null;
                                let street = null;
                                let street_type_full = null;
                                let house = null;
                                let restaurant_id = null;
                                if (suggestion.data.street !== undefined && suggestion.data.street !== null) {
                                    value = suggestion.data.street_type_full + " " + suggestion.data.street;
                                    street = suggestion.data.street;
                                    street_type_full = suggestion.data.street_type_full;
                                    if (suggestion.data.house !== undefined && suggestion.data.house !== null) {
                                        value = value + ", " + suggestion.data.house_type + " " + suggestion.data.house;
                                        house = suggestion.data.house_type + " " + suggestion.data.house;
                                    }
                                    if (suggestion.data.block !== undefined && suggestion.data.block !== null) {
                                        value = value + ", " + suggestion.data.block_type + " " + suggestion.data.block;
                                        house = house + ", " + suggestion.data.block_type + " " + suggestion.data.block;
                                    }
                                }
                                if (suggestion.data.geo_lat !== undefined && suggestion.data.geo_lon !== undefined) {
                                    lat = suggestion.data.geo_lat;
                                    lon = suggestion.data.geo_lon;
                                }
                                address.address = value;
                                if ((lat === null || lon === null) && suggestion.unrestricted_value !== undefined) {
                                    this.sendCoordinatesRequest(suggestion.unrestricted_value, value, street, street_type_full, house, restaurant_id, i);
                                } else {
                                    this.setAddressValue(value, lat, lon, street, street_type_full, house, restaurant_id, i);
                                }
                                this.setState({ query: value, });
                            }}
                        />
                    </div>
                    <div className="address-container address-container-half">
                        <div className="input-stroke__label address-title">Квартира/Офис</div>
                        <input
                            type="text"
                            className="personal-input"
                            name="flat"
                            autoComplete="off"
                            value={address.flat}
                            onChange={(event) => {
                                this.onSetField(event, "flat", i);
                            }}
                        />
                    </div>
                    <div className="address-container address-container-half">
                        <div className="input-stroke__label address-title">Парадная</div>
                        <input
                            type="text"
                            className="personal-input"
                            name="entrance"
                            autoComplete="off"
                            value={address.entrance}
                            onChange={(event) => {
                                this.onSetField(event, "entrance", i);
                            }}
                        />
                    </div>
                    <div className="address-container">
                        <div className="input-stroke__label address-title">Название адреса</div>
                        <input
                            type="text"
                            className="personal-input"
                            name="title"
                            autoComplete="off"
                            value={address.title}
                            onChange={(event) => {
                                this.onSetField(event, "title", i);
                            }}
                        />
                    </div>
                </div>
                <ValidationResult error={this.state.errors[(i === undefined) ? "new" : i]} />
                <div className="address-buttons-container">
                    <Button
                        buttonType="default"
                        buttonTitle="Сохранить"
                        buttonClass="personal-button"
                        onClickSubmit
                        onClickMethod={() => {
                            this.onSaveAddress(i);
                        }}
                    />
                    <Button
                        buttonType="default"
                        buttonTitle="Отмена"
                        buttonClass="personal-button_light"
                        onClickSubmit
                        onClickMethod={() => {
                            this.onCancelEdit(i);
                        }}
                    />
                </div>
            </div>
        );
    }

    setAddress(item) {
        this.props.setAddress(item);
    }

    renderChoose(item, i) {
        let basketCost = (this.state.basketCost ? this.state.basketCost : 0);
        let defaultAddressId = 0;
        let filterredAdresses = this.state.addresses.filter((address, index) => address.min_payment_amount <= basketCost);
        if (filterredAdresses.length !== 0) {
            if (this.state.setNewAddress) {
                defaultAddressId = filterredAdresses[filterredAdresses.length - 1].id;
            }
            // else
            //     defaultAddressId = filterredAdresses[0].id;
        }
        let disabled = item.min_payment_amount > (this.state.basketCost ? this.state.basketCost : 0);
        return (
            <div className="input-stroke__label address-title">
                <input
                    type="radio"
                    id={"address" + item.id}
                    name="address"
                    value={item.id}
                    defaultChecked={item.id === defaultAddressId}
                    disabled = {disabled}
                    onChange={() => {
                        this.setAddress(item);
                    }}
                />
                <label htmlFor={"address" + item.id}>{item.title} <span style={{fontSize: "10px"}}>{disabled ? "(Минимальный заказ " + formatCurrency(item.min_payment_amount) + " для этого адреса)" : ""}</span></label>
            </div>
        );
    }

    renderLabel(title) {
        return (<div className="input-stroke__label address-title">{title}</div>);
    }

    renderAddress(i) {
        const address = Object.assign({}, this.state.addresses[i]);
        return (
            <div className="addresses__container">
                <div className="input-stroke">
                    <div className="address-container">
                        <div className="container-address-title">
                            {this.props.mode === "payment" ? this.renderChoose(address, i) : this.renderLabel(address.title)}
                            <div
                                className={classNames({ "hide-element": address.edit, })}
                            >
                                <div
                                    onClick={
                                        this.onEditToggle.bind(this, i, true)
                                    }
                                >
                                    <EditItem />
                                </div>
                            </div>
                        </div>
                        <div className={'address__info'}>
                            <div className="input-stroke__label address-value">{`${address.address}, ${address.flat}, ${address.entrance}`}</div>
                            <div onClick={
                                this.deleteAddressItem.bind(this,address.id)
                            }>
                                <DeleteIcon/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAddButton() {
        return (
            <Button
                buttonType="default"
                buttonTitle="Добавить новый адрес"
                buttonClass="address-button_save"
                onClickSubmit
                onClickMethod={() => {
                    this.onAddToggle(true);
                }}
            />
        );
    }

    renderAddresses() {
        const renderItems = [];
        const addresses = this.state.addresses.slice();
        addresses.forEach((item, i) => {
            renderItems.push(
                <React.Fragment key={"address-" + i}>
                    {this.state.addresses[i].edit ? this.renderEdit(i) : this.renderAddress(i)}
                </React.Fragment>
            );
        });
        return renderItems;
    }

    render() {
        const addresses = this.renderAddresses();
        let title = "Мои адреса";
        if (this.props.mode === "payment") title = "Адрес доставки";
        
        return (
            <div className="inf-block my-addresses">
                <h2 className="inf-block__title ">{title}</h2>
                {addresses}
                <div className={classNames({ "address-buttons": !this.state.addNewAddress, })}>
                    {this.state.addNewAddress ? this.renderEdit() : this.renderAddButton()}
                </div>
            </div>
        );
    }
}
Addresses.propTypes = { dadataToken: PropTypes.string, };
Addresses.defaultProps = { dadataToken: undefined, };

const mapStateToProps = store => {
    return{
        payment: store.payment,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        reloadData: (flag) => dispatch(reloadData(flag))
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(Addresses);
