import React from "react";
import classNames from "classnames";
import Order from "./Order/Order";
import { getRequest, postRequest, setCookie, deleteCookie } from "../../helpers";

class StatusArea extends React.Component {
    constructor(props) {
        super(props);

        const hash = window.location.hash.split("/");

        if (hash.length < 3 || hash.length > 2 && hash[2] === "") {
            if (window.location.hash.indexOf("#/status") !== -1) {
                window.location.hash = "#/";
            }
        }

        this.state = {
            id: hash[hash.length - 1],
            status: {},
            customer: {},
            order: {
                items: [],
                delivery_details: { address: {}, },
                total_due: 0,
                bonuses_accrued: 0,
            },
            profile: this.props.profile,
            timer: null,
        };
    }

    async componentDidMount() {
        const self = this;
        const state = {};

        if (this.state.profile.phone) {
            await postRequest("/order/status", { id: this.state.id, })
                .then((res) => {
                    if (res && res.data && res.data.success === true) {
                        state.status = res.data.result;

                        if (res.data.result.current_status && res.data.result.finished !== true) {
                            setCookie("lastOrder", self.state.id, {
                                path: window.location.pathname,
                                expires: 86400,
                            });
                        } else {
                            deleteCookie("lastOrder");
                        }
                    }
                });
            await getRequest("/order/get?id=" + this.state.id)
                .then((res) => {
                    state.customer = res.data.result.customer;
                    state.order = res.data.result.order;
                });

            this.setState(state);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.timer)
    }

    createNewOrder() {
        window.location.href = "#/";
    }

    get order() {
        let payment = "";
        let address = "";
        let phone = "";
        let pub = { name: '', telephone: '' };

        if (this.state.order) {
            payment = this.state.order.payment_method === 3 ? "Картой на сайте" : "Оплата в ресторане";
            phone = this.state.order.delivery_details.address.phone;
            
            if (!phone) {
                let current_pub = this.props.restaurants && this.props.restaurants.filter(item => item.id === this.state.order.delivery_details.address.restaurant_id)[0]
                
                pub.name = current_pub && current_pub.address
                pub.telephone = current_pub && current_pub.phone
            }

            if (this.state.order.delivery_details.address.address) {
                if (this.state.order.delivery_details.address.address) {
                    address += this.state.order.delivery_details.address.address;
                }

                if (this.state.order.delivery_details.address.entrance) {
                    address = address + ", парадная " + this.state.order.delivery_details.address.entrance;
                }

                if (this.state.order.delivery_details.address.flat) {
                    address = address + ", квартира " + this.state.order.delivery_details.address.flat;
                }
            }
        }

        return (
            <div>
                {phone ? <div className="inf-block">
                    <h2 className="inf-block__title">Ваш заказ готовит ресторан {address.split(" / ")[0]}</h2>
                    <div className="inf-block__content">{phone}</div>
                </div> :
                    <div className="inf-block">
                        <h2 className="inf-block__title">Ваш заказ готовит ресторан по адресу {pub.name}</h2>
                        <div className="inf-block__content">{pub.telephone}</div>
                    </div>
                }

                <div className="inf-block">
                    <h2 className="inf-block__title">Личная информация</h2>
                    {this.state.customer.firstname ? <div>{this.state.customer.firstname}</div> : ""}
                    <div className="inf-block__content">{this.state.customer.phone ? "+" + this.state.customer.phone : ""}</div>
                </div>
                <div className="inf-block">
                    <h2 className="inf-block__title">Адрес доставки</h2>
                    <div className="inf-block__content">{address || ""}</div>
                </div>
                <div className="inf-block">
                    <h2 className="inf-block__title">Способ оплаты</h2>
                    <div className="inf-block__content">{payment || ""}</div>
                </div>
                <div className="inf-block">
                    <Order
                        order={this.state.order.items}
                        total_due={+this.state.order.total_due}
                        total={+this.state.order.total}
                        bonusAccrualPercent={+this.state.bonusAccrualPercent}
                        bonusesAccrued={+this.state.order.bonuses_accrued}
                    />
                </div>
            </div>
        );
    }

    get estimate() {
        return (
            <div className="order-status__timeline order-timeline">
                <span className="order-timeline__icon">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 0.75C6.03693 0.75 0.75 6.03693 0.75 12.5C0.75 18.9631 6.03693 24.25 12.5 24.25C18.9631 24.25 24.25 18.9631 24.25 12.5C24.25 6.03693 18.9631 0.75 12.5 0.75ZM12.5 22.9833C6.6964 22.9833 2.01667 18.3036 2.01667 12.5C2.01667 6.6964 6.6964 2.01667 12.5 2.01667C18.3036 2.01667 22.9833 6.6964 22.9833 12.5C22.9833 18.3036 18.3036 22.9833 12.5 22.9833Z" fill="#8DD161" stroke="#8DD161" strokeWidth="0.5" />
                        <path d="M12.501 3.05078C12.3239 3.05078 12.1677 3.13683 12.0607 3.2438C11.9538 3.35076 11.8677 3.50697 11.8677 3.68411V12.2508H6.36771C6.19057 12.2508 6.03435 12.3368 5.92739 12.4438C5.82043 12.5508 5.73438 12.707 5.73438 12.8841C5.73438 13.0613 5.82043 13.2175 5.92739 13.3244C6.03435 13.4314 6.19057 13.5174 6.36771 13.5174H12.501C12.6782 13.5174 12.8344 13.4314 12.9414 13.3244C13.0483 13.2175 13.1344 13.0613 13.1344 12.8841V3.68411C13.1344 3.50697 13.0483 3.35076 12.9414 3.2438C12.8344 3.13683 12.6782 3.05078 12.501 3.05078Z" fill="#8DD161" stroke="#8DD161" strokeWidth="0.5" />
                    </svg>
                </span>
                <span className="order-timeline__text">Доставим не позднее</span>
                <span className="order-timeline__time">{this.state.status.estimated_delivery_time}</span>
            </div>
        );
    }

    get timeline() {
        return this.state.status.status_history && this.state.status.status_history.map((item, index) => {
            const statusClass = item.time ? "complete" : "process";
            const lastClass = item.last ? " last-step" : "";

            return (
                <div className={"order-steps__row " + statusClass + lastClass} key={`step_row${index}`}>
                    <div className="order-steps__icon">
                        <svg className="svg svg-check ">
                            <use href="#check" />
                        </svg>
                    </div>
                    <div className="order-steps__time">{item.time}</div>
                    <div className="order-steps__name">{item.title}</div>
                </div>
            );
        });
    }

    render() {
        return (
            <main key="status" className={classNames("page order-status", { "hide-element": this.props.showMobileAuth, })}>
                <div className="page__container container">
                    <div className="status__left-column">
                        <h1 className="page__title">Статус заказа</h1>
                        <div className="order-status__name">Заказ № {this.state.order ? this.state.order.order_number : 0}</div>
                        {this.state.status.estimated_delivery_time ? this.estimate : ""}
                        <div className="order-status__steps order-steps">
                            {this.timeline}
                        </div>
                        <div className="order-status__btn btn-transparent">
                            <button
                                className="btn btn-order-status"
                                onClick={this.createNewOrder.bind(this)}
                            >
                                Новый заказ
                            </button>
                        </div>
                    </div>
                    <div className="status__right-column">{this.order}</div>
                </div>
            </main>
        );
    }
}

export default StatusArea;
