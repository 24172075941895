import { reactLocalStorage } from "reactjs-localstorage";
import ym from "react-yandex-metrika";
import { ADD_TO_CART,
    CLEAR_CART,
    REMOVE_FROM_CART,
    REMOVE_ALL_ITEMS_FROM_CART,
    LOAD_CART,
    ADD_TOPPING,
    REMOVE_TOPPING } from "../AC";
import initState from "../store/initState";
import { PROD } from "../helpers";

function cartReducer(cart = initState.cart, action) {
    const { type, item, items, id, } = action;
    console.log("action", action);
    switch (type) {
    case ADD_TO_CART: {
        const newCart = [...cart];
        const findId = newCart.findIndex(cartItem => cartItem.itemData.id === item.id);
        if (item.type === "set") {
            item.price = item.amount;
            const arr = [];
            item.groups.forEach((group) => {
                group.ingredients.forEach((ingredient) => {
                    arr.push(ingredient.title);
                });
            });
            item.description = arr.join(", ");
        }
        if (findId > -1) {
            newCart[findId].numberItems += 1;
        } else {
            newCart.push({
                inCart: true,
                itemData: {
                    ...item,
                    type: item.type || "product",
                },
                numberItems: 1,
            });
        }
        if (PROD) {
            console.log("Add to cart: ", item);
            ym("reachGoal", "AddToCart");
            const VK = window.VK;
            VK.Goal("conversion", { value: item.price, });
        }
        reactLocalStorage.setObject("cart", { items: newCart, });
        console.log("cart", newCart);
        return newCart;
    }

    case REMOVE_FROM_CART: {
        const newCart = [...cart];
        const findId = newCart.findIndex(cartItem => cartItem.itemData.id === id);
        if (newCart[findId].numberItems > 1) {
            newCart[findId].numberItems -= 1;
        } else {
            newCart.splice(findId, 1);
        }
        reactLocalStorage.setObject("cart", { items: newCart, });
        // saveCart(newCart)
        return newCart;
    }

    case REMOVE_ALL_ITEMS_FROM_CART: {
        const newCart = [...cart];
        const findId = newCart.findIndex(cartItem => cartItem.itemData.id === id);
        newCart.splice(findId, 1);

        reactLocalStorage.setObject("cart", { items: newCart, });
        // saveCart(newCart)
        return newCart;
    }

    case LOAD_CART: {
        const newCart = items.map(el => getCartItem(el));

        reactLocalStorage.setObject("cart", { items: newCart, });

        return newCart;
    }

    case CLEAR_CART: {
        reactLocalStorage.setObject("cart", { items: [], });
        return [];
    }

    case ADD_TOPPING: {
        const newCart = [...cart];
        const findId = newCart.findIndex(cartItem => cartItem.itemData.id === action.id);
        const findTopping = newCart[findId].itemData.toppings.findIndex(
            topping => topping.id === action.topping.id
        );
        if (findTopping > -1) {
            if (action.topping.iiko_id) {
                newCart[findId].itemData.toppings[findTopping] = {
                    ...action.topping,
                    amount: action.topping.amount + 1,
                };
            }
        }

        reactLocalStorage.setObject("cart", { items: newCart, });
        return newCart;
    }

    case REMOVE_TOPPING: {
        const newCart = [...cart];
        const findId = newCart.findIndex(cartItem => cartItem.itemData.id === action.id);
        const findTopping = newCart[findId].itemData.toppings.findIndex(
            topping => topping.id === action.topping.id
        );
        if (findTopping > -1) {
            if (newCart[findId].itemData.toppings[findTopping].amount > 0) {
                newCart[findId].itemData.toppings[findTopping] = {
                    ...action.topping,
                    amount: action.topping.amount - 1,
                };
            }
        }

        reactLocalStorage.setObject("cart", { items: newCart, });

        return newCart;
    }

    default: {
        return cart;
    }
    }
}

function getCartItem(item) {
    return {
        inCart: true,
        numberItems: item.quantity ? item.quantity : 1,
        itemData: {
            ...item,
            type: item.type || "product",
        },
    };
}
export default cartReducer;
