import React from 'react';
import RemoveItemButton from "../icons/RemoveItemButton";
import {useDispatch} from "react-redux";
import {setNotBonuses} from "../../AC";

const Notification = () => {
    const dispatch = useDispatch();


    return (
        <div className={'notification'}>
            <button
                className={'notification__close'}
                onClick={() => {
                    dispatch(setNotBonuses(false))
                }}
            >
                <RemoveItemButton />
            </button>
            <h5 className={'notification__title'}>
                Внимание!
            </h5>
            <p className={'notification__description'}>
                У Вас еще нет баллов для списания
            </p>
        </div>
    )
}

export default Notification;
