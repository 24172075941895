import React from "react";
import classNames from "classnames";
import ym from "react-yandex-metrika";
import Helmet from "react-helmet";
import {getPrice1, setDeliveryMethod, formatCurrency, getBoulTitle, PROD } from "../../../helpers";
import AddIcon from "../../icons/AddItem";
import RemoveIcon from "../../icons/RemoveItem";
import RemoveItemButton from "../../icons/RemoveItemButton";
import EmptyBasketIcon from "../../icons/EmptyBasketIcon";
import Hits from "../../Hits";
import EditItem from "../../icons/EditItem";
import ToppingButton from "../../Toppings/ToppingsButton";

class BasketArea extends React.Component {
    get basketItems() {
        const {
            cart,
            removeAllItemsFromCart,
            removeFromCart, addToCart,
            bowl_constructor,
        } = this.props;
        return cart.map(item => (
            <li key={item.itemData.title} className="basket__item basket-pos js-basket-pos">
                <div className="basket-pos__header">
                    <div className="basket-pos__title">{item.itemData.title}</div>
                    <div className="basket-pos__price">
                        <span
                            data-price={item.itemData.price}
                        >{formatCurrency(item.itemData.price || item.itemData.amount)}
                        </span>
                    </div>
                    <div className="basket-pos__consist">
                        {item.itemData.description
                            ? item.itemData.description
                            : getBoulTitle(item.itemData.groups)}
                    </div>
                    <div className="basket-pos__specifications">
                        {item.itemData.specifications
              && item.itemData.specifications.map(prop => (
                  <div key={prop.title} className="basket-pos__prop">
                      {`${prop.value} ${prop.title}`}
                  </div>
              ))}
                    </div>
                </div>
                <ToppingButton item={item} />
                <div className="basket-pos__footer">
                    <div className="basket-pos__buttons">
                        <div
                            className="basket-pos__delete"
                            onClick={() => {
                                removeAllItemsFromCart(item.itemData.id);
                                this.props.setConstructorData({});
                            }}
                        >
                            <RemoveItemButton />
                        </div>
                        {item.itemData.type === "set" && (
                            <div
                                className="basket-pos__edit"
                                onClick={() => {
                                    this.props.setConstructorData(item);
                                    // eslint-disable-next-line max-len
                                    this.props.setConstructorVisible(!bowl_constructor.view_constructor);
                                }}
                            >
                                <EditItem />
                            </div>
                        )}
                    </div>
                    <div className="basket-pos__amount amount-item">
                        <div
                            onClick={() => {
                                removeFromCart(item.itemData.id);
                                this.props.setConstructorData({});
                            }}
                            className="amount-item__dec"
                        >
                            <RemoveIcon />
                        </div>
                        <span className="amount-item__field">{item.numberItems}</span>
                        <div
                            onClick={() => {
                                addToCart(item.itemData);
                            }}
                            className="amount-item__inc"
                        >
                            <AddIcon />
                        </div>
                    </div>
                </div>
            </li>
        ));
    }

    get basket() {
        return (
            <div className="basket-total__prices">
                <p className="basket-total__title">Стоимость заказа</p>
                <p className="basket-total__cost">
                    <span className="js-total-cost">{formatCurrency(getPrice1(this.props.cart))}</span>
                </p>
            </div>
        );
    }

    get buttons() {
        return (
            <div className="basket-total__buttons">
                <button
                    className="basket-total__btn_dark btn-transparent"
                    onClick={this.clickPickupDelivery(1)}
                    type="button"
                >Самовывоз
                </button>
                <button
                    className="basket-total__btn_light btn-primary"
                    onClick={this.clickPickupDelivery(2)}
                    type="button"
                >Доставка
                </button>
            </div>
        );
    }

    get items() {
        if (this.props.cart.length) {
            return <ul className="basket__list">{this.basketItems}</ul>;
        }
        return (
            <div className="basket-empty">
                <div className="basket-empty__icon">
                    <EmptyBasketIcon />
                </div>
                <div className="basket-empty__text">В корзине пока еще ничего нет</div>
                <div className="basket-empty__button">
                    <button
                        type="button"
                        className="basket-total__btn_dark btn-transparent"
                        onClick={() => {
                            window.location.hash = "";
                        }}
                    >Вернуться в меню
                    </button>
                </div>
            </div>
        );
    }

  clickPickupDelivery = type => () => {
      if (PROD) {
          ym("reachGoal", "InitiateCheckout");
          const VK = window.VK;
          VK.Goal("initiate_checkout", { value: getPrice1(this.props.cart), });
      }
      this.redirectToPayment(type);
  };

  redirectToPayment(type) {
      setDeliveryMethod(type);
      this.props.onNavigateRequest("#/payment");
  }

  render() {
      const { showMobileAuth, pageTitle, cart, } = this.props;

      return (
          <main className={classNames("page basket-page", { "hide-element": showMobileAuth, })}>
              <Helmet>
                  <title>{pageTitle}</title>
              </Helmet>
              <div className="page__container container">
                  <p className="page__title">Ваш заказ</p>
                  <div className="basket">{this.items}</div>
              </div>
              <div className="container">
                  <Hits title="Рекомендуем" withScroll />
                  <div className="basket__total-block basket-total">
                      {this.props.cart.length ? this.basket : ""}
                      {this.props.cart.length ? this.buttons : ""}
                  </div>
              </div>
          </main>
      );
  }
}
BasketArea.propTypes = {
    showMobileAuth: PropTypes.bool.isRequired,
    pageTitle: PropTypes.string.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    onNavigateRequest: PropTypes.func.isRequired,
    cart: PropTypes.instanceOf(Array).isRequired,
    removeAllItemsFromCart: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    setConstructorData: PropTypes.func.isRequired,
    setConstructorVisible: PropTypes.func.isRequired,
    bowl_constructor: PropTypes.instanceOf(Object).isRequired,
};
export default BasketArea;
