import { connect } from "react-redux";
import Component from "./Promo";
import {clearCart, setPaymentWithBonus, setPromocodeData, setPromoDiscount, setUsePromo} from "../../../../../AC";

function mapStateToProps(state) {
    return { cart: state.cart, payment: state.payment };
}
const mapDispatchToProps = { clearCart,setPaymentWithBonus,setUsePromo,setPromoDiscount,setPromocodeData };

const ConnectedPromo = connect(
    mapStateToProps, mapDispatchToProps
)(Component);

export default ConnectedPromo;
