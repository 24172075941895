import React from "react";
import { Link } from "react-router-dom";
import ItemControls from "../ItemControls";
import { formatCurrency } from "../../helpers";
import iconCircle from "./market-item-circle.svg";
import iconFire from "./market-item-fire.svg";
import iconLeaf from "./market-item-leaf.svg";
import "./MarketItem.scss";

class MarketItem extends React.Component {
    renderItemLabel = (label) => {
        switch (label.icon) {
            case "circle":
                return (
                    <img key={label.icon} src={iconCircle} alt="" />
                );
            case "fire":
                return (
                    <span className="with-title" data-title='Острое блюдо' key={label.icon}>
                        <img src={iconFire} alt="" />
                    </span>
                    
                );
            case "leaf":
                return (
                    <span className="with-title" data-title='Вегетарианское блюдо' key={label.icon}>
                        <img src={iconLeaf} alt="🍁" />
                    </span>
                );
            default:
                return "";
        }
    }

    render() {
        const { withScroll, product, } = this.props;
        const { id, alias, title, description, image, labels, price, measure, } = product;
        let itemLabelText = "";
        let itemLabelIcons = "";
        if (labels && labels.length) {
            itemLabelText = labels.map(label => label.title).join(", ");
            itemLabelIcons = labels.map(label => this.renderItemLabel(label));
        }

        return (
            <div key={`product${id}`} className="item">
                <Link to={alias} className="item__content">
                    <div className="item-img-container">
                        <img className="item-img-container__img" src={image} alt="" />
                    </div>
                    <div className="item-title">
                        <div className="item-title__title">

                            <div className="item-title__icon" >
                                <span>{title}</span>
                            </div>
                            <div className="item-title__labels">
                                {itemLabelIcons}
                            </div>
                        </div>
                    </div>
                    <div className="item-desc">
                        <div className="item-desc__description text-description">
                            {description}
                        </div>
                    </div>
                </Link>
                <div className="item__footer">
                    <div className="item__footer-weight">
                        {measure.value} {measure.unit}
                    </div>
                    <ItemControls withScroll={withScroll} product={product} />
                    <div className="price-container">{formatCurrency(price)}</div>
                </div>
            </div>
        );
    }
}

MarketItem.propTypes = {
    product: PropTypes.shape({
        alias: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        labels: PropTypes.instanceOf(Object).isRequired,
        measure: PropTypes.instanceOf(Object).isRequired,
        specifications: PropTypes.instanceOf(Object).isRequired,
    }).isRequired,
    withScroll: PropTypes.bool,
};
MarketItem.defaultProps = { withScroll: false, };
export default MarketItem;
