import { connect } from "react-redux";
import Component from "./ItemControls";
import { addToCart, removeFromCart } from "../../AC";

function mapStateToProps(state) {
    return { cart: state.cart, };
}
const mapDispatchToProps = {
    addToCart,
    removeFromCart,
};

const ConnectedComponent = connect(
    mapStateToProps, mapDispatchToProps
)(Component);

export default ConnectedComponent;
