import React from 'react';

const LogoMobile = () => {
    return (
        <svg width="68" height="22" viewBox="0 0 68 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.7962 15.3707C26.9885 15.3707 28.7657 13.5542 28.7657 11.3135C28.7657 9.07287 26.9885 7.25644 24.7962 7.25644C22.6039 7.25644 20.8267 9.07287 20.8267 11.3135C20.8267 13.5542 22.6039 15.3707 24.7962 15.3707ZM24.7962 18.8986C28.8949 18.8986 32.2175 15.5026 32.2175 11.3135C32.2175 7.12445 28.8949 3.72852 24.7962 3.72852C20.6976 3.72852 17.375 7.12445 17.375 11.3135C17.375 15.5026 20.6976 18.8986 24.7962 18.8986Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.42124 15.3707C9.61353 15.3707 11.3907 13.5542 11.3907 11.3135C11.3907 9.07287 9.61353 7.25644 7.42124 7.25644C5.22894 7.25644 3.45174 9.07287 3.45174 11.3135C3.45174 13.5542 5.22894 15.3707 7.42124 15.3707ZM7.42124 18.8986C11.5199 18.8986 14.8425 15.5026 14.8425 11.3135C14.8425 7.12445 11.5199 3.72852 7.42124 3.72852C3.3226 3.72852 0 7.12445 0 11.3135C0 15.5026 3.3226 18.8986 7.42124 18.8986Z" fill="white"/>
            <path d="M0 11.1367H3.45174V21.9557H0V11.1367Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M59.9798 7.34809C62.1721 7.34809 63.9493 9.16452 63.9493 11.4052C63.9493 13.6459 62.1721 15.4623 59.9798 15.4623C57.7875 15.4623 56.0103 13.6459 56.0103 11.4052C56.0103 9.16452 57.7875 7.34809 59.9798 7.34809ZM59.9798 3.82017C64.0785 3.82017 67.4011 7.2161 67.4011 11.4052C67.4011 15.5943 64.0785 18.9902 59.9798 18.9902C55.8812 18.9902 52.5586 15.5943 52.5586 11.4052C52.5586 7.2161 55.8812 3.82017 59.9798 3.82017Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M42.6048 7.34809C44.7971 7.34809 46.5743 9.16452 46.5743 11.4052C46.5743 13.6459 44.7971 15.4623 42.6048 15.4623C40.4125 15.4623 38.6353 13.6459 38.6353 11.4052C38.6353 9.16452 40.4125 7.34809 42.6048 7.34809ZM42.6048 3.82017C46.7035 3.82017 50.0261 7.2161 50.0261 11.4052C50.0261 15.5943 46.7035 18.9902 42.6048 18.9902C38.5062 18.9902 35.1836 15.5943 35.1836 11.4052C35.1836 7.2161 38.5062 3.82017 42.6048 3.82017Z" fill="white"/>
            <path d="M35.1836 11.582H38.6353V0.763069H35.1836V11.582Z" fill="white"/>
        </svg>);
};

export default LogoMobile;
