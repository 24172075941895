import React from "react";
import "../../assets/scss/main.scss";
import Modal from "react-modal";
import InputMask from "react-input-mask";
import Button from "../Button";
import ModalHide from "../icons/ModalHide";
import { postRequest, focusInputById} from "../../helpers";
import ValidationResult from "../Pages/ValidationResult";

class AuthModal extends React.Component {
    constructor(props) {
        super(props);
        //+7 (999) 999-99-99

        this.state = {
            phoneNumber: this.props.phoneNumber === undefined ? '+7' : this.props.phoneNumber,
            authStep: 1,
            timeout: 59,
            code: "",
            profile: {},
            modalText: this.props.modalText,
            modalDescription: this.props.modalDescription,
            modalError: "",
            lastValue: '7',
            fetchingData: false,
            input: null,
        };
    }

    componentDidMount() {
        this.showAuthStep(1);

        let { input, phoneNumber } = this.state

        input = document.querySelector('#phone-number-input')
        input && input.addEventListener('paste', (e) => {
            let clipboardData, pastedData;

            e.stopPropagation();
            e.preventDefault();

            clipboardData = e.clipboardData || window.clipboardData;
            pastedData = clipboardData.getData('Text');

            if (pastedData) {
                phoneNumber = pastedData.replace(/-/, '').replace(/ /, '').replace('+7', '')
            }
        })
    }

    componentWillUnmount() {
        let { input, phoneNumber } = this.state
        input && input.removeEventListener('paste', (e) => {
            let clipboardData, pastedData;

            e.stopPropagation();
            e.preventDefault();

            clipboardData = e.clipboardData || window.clipboardData;
            pastedData = clipboardData.getData('Text');

            if (pastedData) {
                this.setState({phoneNumber: pastedData.replace(/-/, '').replace(/ /, '').replace('+7', '')})
            }
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isOpen !== this.props.isOpen) {
            this.showAuthStep(1);
            return true;
        }

        if (nextProps.phoneNumber && nextProps.phoneNumber !== this.props.phoneNumber) {
            this.setState({ phoneNumber: nextProps.phoneNumber, });

            return true;
        }
        if (nextProps.modalText !== this.state.modalText) {
            this.setState({ modalText: nextProps.modalText, });

            return true;
        } if (nextState.phoneNumber !== this.state.phoneNumber) {
            // ISSUE: blocks editting of phoneNumber
            this.setState({ phoneNumber: nextState.phoneNumber, });

            return true;
        }
        if (nextState.modalText !== this.state.modalText) {
            this.setState({
                modalText: nextState.modalText,
                ...nextState,
            });

            return true;
        } if (nextState.modalDescription !== this.state.modalDescription) {
            this.setState({
                modalDescription: nextState.modalDescription,
                ...nextState,
            });

            return true;
        } if (nextState.modalError !== this.state.modalError) {
            this.setState({
                modalError: nextState.modalError,
                ...nextState,
            });

            return true;
        } if (nextState.authStep !== this.state.authStep) {
            this.setState({
                authStep: nextState.authStep,
                ...nextState,
            });

            return true;
        } if (nextState.code !== this.state.code) {
            return true;
        }
        if (nextState.timeout !== this.state.timeout) {
            this.setState({
                timeout: nextState.timeout,
                ...nextState,
            });

            return true;
        } if (nextState.profile.phone !== this.state.profile.phone) {
            this.setState({ profile: nextState.profile, });

            return true;
        }

        if (nextState.fetchingData !== this.state.fetchingData) {
            this.setState({ ...nextState, fetchingData: nextState.fetchingData })
            return true
        }

        return false;
    }

    updateCode(value) {
        this.setState({ code: value, lastValue: value.length - 1 });
    }

    confirmAuthCode(code) {
        const self = this;

        postRequest(this.props.confirmAuthCodeApi, {
            phone: this.state.phoneNumber,
            code,
        })
            .then((res) => {
                if (res && res.data && res.data.success === true) {
                    if (typeof this.props.onSuccess === "function") {
                        this.props.onSuccess(res.data);
                    }

                    self.props.onClose();
                } else {
                    self.showAuthError(res.data);
                }
            });
    }

    async requestAuthCode(phoneNumber) {
        const self = this;

        await this.setState({
            fetchingData: true,
        })

        if (!/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/.test(phoneNumber)) {
            this.setState({ modalError: "Неверный номер телефона", });
            document.getElementById("phone-number-input").focus();

            this.setState({
                fetchingData: false,
            })

            return;
        }

        this.setState({
            phoneNumber,
            modalError: "",
        });

        const delay = 1000 * 60

        await postRequest(this.props.requestAuthCodeApi, { phone: this.state.phoneNumber, })
            .then((res) => {
                if (res && res.data && res.data.success) {
                    self.showAuthStep(2);
                    this.setState({ lastValue: 0 });
                } else {
                    self.showAuthError(res.data.errors[0].message);
                }
            }).catch((res) => {
                self.showAuthError('Не удалось отправить SMS, повторите попытку позже');
            }).finally(
                setTimeout(() => this.setState({
                    fetchingData: false,
                }), 1000)
            )
    }



    showAuthStep(value, props = {}) {
        if (this.timer) {
            clearInterval(this.timer);
        }

        if (value === 2 && this.state.phoneNumber) {
            this.setState({
                authStep: 2,
                modalText: `Мы отправили СМС с кодом на номер ${this.state.phoneNumber}`,
                modalDescription: `Получить код ещё раз можно будет через ${this.state.timeout} секунд`,
                modalError: "",
                timeout: 59,
            }, () => {
                focusInputById("sms-code-input");
                this.timer = setInterval(() => {
                    const timeout = this.state.timeout - 1;

                    this.setState({
                        timeout,
                        modalDescription: `Получить код ещё раз можно будет через ${timeout} секунд`,
                    });

                    if (timeout === 0) {
                        this.setState({ modalDescription: this.renderModalDescription(), });
                    }
                }, 1000);
            });
        } else {
            this.setState({
                authStep: 1,
                phoneNumber: "",
                modalText: this.props.modalText,
                modalDescription: this.props.modalDescription,
                modalError: "",
                ...props,
            }, () => {
                focusInputById("phone-number-input");
            });
        }
    }

    formSubmit(event) {
        let formData = "";

        event.preventDefault();
        formData = new FormData(event.target);
        event.target.reset();

        switch (this.state.authStep) {
            case 1:
                this.requestAuthCode(formData.get("phoneNumber"));
                break;
            case 2:
                this.confirmAuthCode(formData.get("smsCode"));
                break;
            default:
                break;
        }
    }

    showAuthError(response) {
        let errorMessage = "";

        if (response.errors && response.errors.length) {
            response.errors.map((item) => {
                errorMessage += item.message;
            });
        } else if (response.message) {
            errorMessage = response.message;
        }

        if (errorMessage === "") {
            errorMessage = "В настоящее время сервис недоступен. Попробуйте обновить страницу и отправить запрос заново.";
        }

        this.setState({ modalError: errorMessage, });
    }

    renderModalText() {
        if (this.state.authStep === 1) {
            return (
                <div className="modal-title__label">{this.state.modalText}</div>
            );
        } if (this.state.authStep === 2 && !this.state.modalError) {
            return (
                <div className="modal-title__label">Мы отправили СМС с кодом на номер {this.state.phoneNumber}
                    <div
                        className="modal-link"
                        onClick={() => {
                            this.showAuthStep(1);
                        }}
                    >Изменить
                    </div>
                </div>
            );
        }
        return (
            <div className="modal-title__label">{this.state.modalError || this.state.modalText}</div>
        );
    }

    renderModalDescription() {
        if (this.state.authStep === 1 || this.state.timeout > 0) {
            return (
                <span className="modal-description">{this.state.modalDescription}</span>
            );
        }
        return (
            <span className="modal-description">
                <div
                    className="modal-link"
                    onClick={() => {
                        this.requestAuthCode(this.state.phoneNumber);
                    }}
                >Отправить повторно
                </div>
            </span>
        );
    }

    renderModalValidationResult() {
        return (this.state.authStep === 1 && this.state.modalError) ? this.state.modalError : "";
    }
    updatePhone(value) {
        let val = value.replace(/\D/g, '');
        this.setState({ phoneNumber: value, lastValue: val[val.length - 1] });
    }
    getPhoneNumber(value) {
        let number = value;
        number = number.split(',').join('').split(' ').join('').split('-').join('').replace(/,/, '');
        if (number.length > 10)
            number = number.substring(0, 2) + ' ' + number.substring(2, 5) + ' ' + number.substring(5, 8) + '-' + number.substring(8, 10) + '-' + number.substring(10, number.length);
        else if (number.length > 8)
            number = number.substring(0, 2) + ' ' + number.substring(2, 5) + ' ' + number.substring(5, 8) + '-' + number.substring(8, 10);
        else if (number.length > 5)
            number = number.substring(0, 2) + ' ' + number.substring(2, 5) + ' ' + number.substring(5, 8);
        else if (number.length > 2)
            number = number.substring(0, 2) + ' ' + number.substring(2, 5);
        else
            number = '+7';
        if (number.length > 16)
            number = number.substring(0, 16);
        return number;
    }

    getSmsCode(value) {
        let result = value;
        switch (value.length) {
            case 1:
            case 3:
            case 5:
                result += ' ';
                break;
            default:
                break;
        }
        return result;
    }
    renderForm() {

        const modalText = this.renderModalText();
        const modalDescription = this.renderModalDescription();
        if (this.state.phoneNumber === undefined || this.state.phoneNumber.length === 0)
            this.setState({ phoneNumber: '+7' });

        if (this.state.authStep === 1) {
            return (
                <div className="modal-form-container">
                    <div
                        className="modal-hide"
                        onClick={this.props.onClose}
                    >
                        <ModalHide />
                    </div>
                    <h2 className="modal-title">{this.props.modalTitle}</h2>
                    {modalText}
                    <form
                        className="auth-form-modal"
                        onSubmit={(event) => {
                            this.formSubmit(event);
                        }}
                    >
                        <div className="modal-top-level" />
                        <div className="modal-bottom-level">
                            <div className="modal-input">
                                <input
                                    ref={(input) => this.text = input}
                                    type={"tel"}
                                    id="phone-number-input"
                                    className="modal-input__input"
                                    name="phoneNumber"
                                    placeholder={"+7 999 999-99-99"}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        if (value === undefined)
                                            this.updatePhone('+7');
                                        else {
                                            if (this.state.phoneNumber.length > value.length) {
                                                this.updatePhone(value);
                                            } else {
                                                if ((/^[0-9]+$/.test(value[value.length - 1]) && value.length >= 0)) {
                                                    if (value[0] !== '+') {
                                                        value = '+7' + value[0] + value.substring(3, value.length);
                                                        this.updatePhone(this.getPhoneNumber(value));
                                                    } else if (value[1] !== '7') {
                                                        value = '+7' + value.substring(3, value.length);
                                                        this.updatePhone(this.getPhoneNumber(value));
                                                    } else {
                                                        value = this.getPhoneNumber(e.target.value);
                                                        this.updatePhone(value);
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    autoComplete={false}
                                    onFocus={() => {
                                        this.text.selectionStart = this.state.phoneNumber.length === 0 ? -1 : this.state.phoneNumber.lastIndexOf(this.state.lastValue) + 1;
                                        this.text.selectionEnd = this.state.phoneNumber.length === 0 ? -1 : this.state.phoneNumber.lastIndexOf(this.state.lastValue) + 1;
                                    }}
                                    value={this.state.phoneNumber}
                                />
                                <ValidationResult error={(this.state.authStep === 1 && this.state.modalError) ? this.state.modalError : ""} />
                            </div>
                            <button
                                className={`button button-default modal-button ${this.state.fetchingData && 'loading'}`}
                                disabled={this.state.fetchingData}
                            >{!this.state.fetchingData ? "Отправить" : "Отправляем"}</button>
                        </div>
                        {modalDescription}
                    </form>
                </div>
            );
        }
        return (
            <div className="modal-form-container">
                <div
                    className="modal-hide"
                    onClick={this.props.onClose}
                >
                    <ModalHide />
                </div>
                <h2 className="modal-title">{this.props.modalTitle}</h2>
                {modalText}
                <form
                    className="auth-form-modal"
                    onSubmit={(event) => {
                        this.formSubmit(event);
                    }}
                >
                    <div className="modal-top-level" />
                    <div className="modal-bottom-level">
                        <div className="modal-input">
                            <InputMask
                                ref={(input) => this.code = input}
                                id="sms-code-input"
                                className="modal-input__input modal-input__input--sms-code"
                                name="smsCode"
                                autoComplete="off"
                                mask="9999"
                                type={'tel'}
                                value={this.state.code}
                                onChange={(e) => this.updateCode(e.target.value)}
                                onFocus={() => {
                                    this.code.selectionStart = this.state.code.lastIndexOf(this.state.lastValue) === -1 ? 0 : this.state.code.lastIndexOf(this.state.lastValue);
                                    this.code.selectionEnd = this.state.code.lastIndexOf(this.state.lastValue) === -1 ? 0 : this.state.code.lastIndexOf(this.state.lastValue);
                                }}
                            />
                            {/*<input*/}
                            {/*    id="sms-code-input"*/}
                            {/*    className="modal-input__input modal-input__input--sms-code"*/}
                            {/*    name="smsCode"*/}
                            {/*    autoComplete="off"*/}
                            {/*    value={this.state.code}*/}
                            {/*    onChange={(e)=>{*/}
                            {/*        let value = e.target.value;*/}
                            {/*        if (this.state.code.length > value.length){*/}
                            {/*            this.updateCode(value);*/}
                            {/*        }else {*/}
                            {/*            if (/^[0-9]+$/.test(value[value.length - 1]) && value.length < 8) {*/}
                            {/*                value = this.getSmsCode(e.target.value);*/}
                            {/*                this.updateCode(value);*/}
                            {/*            }*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*    onFocus={()=>{*/}
                            {/*        this.text.selectionStart = this.state.lastValue;*/}
                            {/*        this.text.selectionEnd = this.state.lastValue;*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <Button
                            buttonType="default"
                            buttonTitle="Отправить"
                            buttonClass="modal-button"
                            onClickSubmit
                        />
                    </div>
                    {modalDescription}
                </form>
            </div>
        );
    }

    render() {
        if (!this.state.input) {
            this.setState({ input: document.querySelector('#phone-number-input') }, () => {
                this.state.input && this.state.input.addEventListener('paste', (e) => {
                    let clipboardData, pastedData;

                    e.stopPropagation();
                    e.preventDefault();

                    clipboardData = e.clipboardData || window.clipboardData;
                    pastedData = clipboardData.getData('Text');

                    this.setState({phoneNumber: pastedData.replace(/-/, '').replace(/ /, '')})

                })
            })
        }
        if (this.state.phoneNumber === undefined || this.state.phoneNumber.length === 0)
            this.setState({ phoneNumber: '+7' });
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                contentLabel="Example Modal"
                className="auth-modal-container"
                overlayClassName="auth-modal-container__overlay"
                appElement={document.getElementById("app")}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}
AuthModal.propTypes = {
    phoneNumber: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalText: PropTypes.string.isRequired,
    modalDescription: PropTypes.string.isRequired,
    requestAuthCodeApi: PropTypes.string.isRequired,
    confirmAuthCodeApi: PropTypes.string.isRequired,
};
AuthModal.defaultProps = { phoneNumber: undefined, };
export default AuthModal;
