import { HashRouter, Switch, Route } from "react-router-dom";
import "../../assets/scss/main.scss";
import { StickyContainer, Sticky } from "react-sticky";
import classNames from "classnames";
import Header from "../Header";
import Footer from "../Footer/Footer";
import MainContent from "../MainContent";
import AuthModal from "../Modals/AuthModal";
import PersonalArea from "../Pages/PersonalArea";
import Sales from "../Pages/SalesArea";
import Legal from "../Pages/Legal/Legal";
import About from "../Pages/About/About";
import Restaurants from "../Pages/Restaurants/Restaurants";
import Basket from "../Pages/BasketArea";
import Payment from "../Pages/PaymentArea";
import Status from "../Pages/StatusArea";
import MobileAuth from "../Pages/MobileAuthArea";
import ProductCard from "../ProductCard";
import {getRequest, getCookie, setCookie, deleteCookie, sendRequest} from "../../helpers";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorization: {
                show: false,
                authorized: getCookie("authToken"),
            },
            profile: {},
            showMobileAuth: false,
            navigateRequest: "",
        };
    }

    async componentWillMount() {
        const self = this;
        const state = {};
        window.addEventListener("hashchange", this.onHashChange, false);

        if (self.state.authorization.authorized) {
            state.profile = await self.getUserProfile();
        } else {
            self.onHashChange();
        }
        await this.setState(state);
    }

    componentDidMount() {
        const { products, definitions, getProducts, getDefinitions, } = this.props;
        if (!definitions.length) getDefinitions();
        if (!products.length) getProducts();
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange, false);
    }

    onHashChange = () => {
        const hash = window.location.hash;
        if (!this.state.authorization.authorized) {
            if (hash === "#/cabinet" || hash === "#/payment") {
                window.location.hash = "#/";

                return;
            }
        }

        if (this.state.showMobileAuth) {
            this.setState({ showMobileAuth: false, });
        }
        if (hash[2] !== "#") { window.scrollTo(0, 0); }
    }

    getUserProfile = async () => {
        const token = getCookie("authToken");
        let profile = {}

        if (token) {
            await getRequest("/profile?addresses=true&orders=true&cards=true")
                .then((res) => {
                    if (res && res.data && res.data.success === true) {
                        profile = res.data.result;
                        // this.props.loadCart(res.data.result.basket_items)
                    }
                });
        }

        return profile;
    }



    logout = () => {
        deleteCookie("authToken");
        this.setState({ profile: {}, }, () => {
            window.location.hash = "#/";
        });
    }

    openAuth = () => {
        const authorization = Object.assign({}, this.state.authorization);

        authorization.authorized = "";
        authorization.show = true;

        this.setState({ authorization, });
    }

    closeAuth = () => {
        const authorization = Object.assign({}, this.state.authorization);

        authorization.authorized = getCookie("authToken");
        authorization.show = false;

        this.setState({ authorization, navigateRequest: "", });
    }

    doAuth = (data) => {
        if (data && !!data.token) {
            setCookie("authToken", data.token, {
                path: window.location.pathname,
                expires: 2550259188,
            });

            const authorization = Object.assign({}, this.state.authorization);
            authorization.authorized = data.token;
            authorization.show = false;

            if (authorization.authorized && this.state.navigateRequest) {
                this.doNavigate(this.state.navigateRequest);
            }

            this.setState({ authorization, navigateRequest: "", }, async () => {
                const profile = await this.getUserProfile();
                this.setUserProfile(profile);
            });
        }
    }

    setUserProfile = (profile) => {
        this.setState({ profile, });
    }

    setProfileBonuses = (bonuses) => {
        const profile = Object.assign({}, this.state.profile);

        profile.bonuses = bonuses;
        this.setState({ profile, });
    }

    showUserProfile = () => {
        this.onNavigateRequest("#/cabinet");
    }

    doNavigate(link) {
        if (this.state.showMobileAuth) {
            this.setState({ showMobileAuth: !this.state.showMobileAuth, });
        }
        window.location.href = link;
    }

    onNavigateRequest = (requestLink, unauthorized) => {
        if (this.state.profile.phone || unauthorized === true) {
            this.doNavigate(requestLink);
        } else {
            this.setState({ navigateRequest: requestLink, });
            this.openAuth();
        }
    }

    setShowMobileMenu = () => {
        this.setState(state => ({ showMobileAuth: !state.showMobileAuth, }));
    }

    setAddresses = (addresses) => {
        const profile = Object.assign({}, this.state.profile);

        profile.addresses = addresses;
        this.setState({ profile, });
    }

    setProfileEmail = (email, email_confirmed, email_subscribed) => {
        const profile = Object.assign({}, this.state.profile);

        profile.email = email;
        profile.email_confirmed = email_confirmed;
        profile.email_subscribed = email_subscribed;
        this.setState({ profile, });
    }

    get mobileAuth() {
        return (
            <MobileAuth
                onShowUserProfile={this.showUserProfile}
                profile={this.state.profile}
            />
        );
    }
    async reloadData(){
        this.props.reloadData(false);
        let data =  await this.getUserProfile();
        this.setState({profile: data});
        this.props.changeAddress(data.addresses);
    }
    render() {
        const { products, definitions, } = this.props;
        const { authorization, showMobileAuth, category, profile, } = this.state;
        
        const categories = products.map(item => item.category);
        console.log("products", products);
        if (this.props.reload) {
            this.reloadData();
        }
        
        return (
            <StickyContainer>
                <HashRouter>
                    <Sticky>
                        {({ style, isSticky, }) => (
                            <div
                                className={classNames({ "sticky-header": isSticky, })}
                                style={{
                                    zIndex: "11000",
                                    backgroundColor: "#fff",
                                    position: 'relative',
                                    ...style,
                                }}
                            >
                                <AuthModal
                                    modalTitle="Авторизация"
                                    modalText="Укажите телефон, куда прислать СМС с кодом для авторизации"
                                    modalDescription="Туда же будут начислены побонусы"
                                    requestAuthCodeApi="/auth/login-or-register"
                                    confirmAuthCodeApi="/auth/dual-confirm"
                                    isOpen={authorization.show && !authorization.authorized}
                                    onClose={this.closeAuth}
                                    onSuccess={this.doAuth}
                                />

                                <Header
                                    categories={categories}
                                    profile={profile}
                                    category={category}
                                    setShowMobileMenu={this.setShowMobileMenu}
                                    showMobileAuth={showMobileAuth}
                                    onShowUserProfile={this.showUserProfile}
                                    authorized={!!this.state.authorization.authorized}
                                />
                            </div>
                        )
                        }
                    </Sticky>
                    {showMobileAuth ? this.mobileAuth : ""}
                    <Switch>
                        {products.map(product => product.items.map(item => (
                            <Route
                                path={`/${item.alias}`}
                                key={item.id}
                                exact
                                component={() => (
                                    <ProductCard
                                        product={item}
                                        urlAdd={item.alias}
                                        showMobileAuth={showMobileAuth}
                                    />
                                )}
                            />
                        )))}
                        <Route
                            path="/"
                            exact
                            component={() => (
                                <MainContent
                                    slides={definitions.layout.frontpage_slider}
                                    mobileSlides={definitions.layout.mobile_app_slider}
                                    showMobileAuth={showMobileAuth}
                                />
                            )}
                        />
                        <Route
                            path="/cabinet"
                            component={() => (
                                <PersonalArea
                                    profile={profile}
                                    showMobileAuth={showMobileAuth}
                                    logout={this.logout}
                                    setProfileEmail={this.setProfileEmail}
                                    setAddresses={this.setAddresses}
                                    getUserProfile={this.getUserProfile}
                                    setUserProfile={this.setUserProfile}
                                    dadataToken={definitions.dadataToken}
                                />
                            )}
                        />
                        <Route path="/sales" component={() => <Sales showMobileAuth={showMobileAuth} />} />
                        <Route path="/legal" component={() => <Legal showMobileAuth={showMobileAuth} />} />
                        <Route path="/about" component={() => <About showMobileAuth={showMobileAuth} />} />
                        <Route
                            path="/restaurants"
                            component={() => (
                                <Restaurants
                                    restaurants={definitions.layout.restaurants}
                                    showMobileAuth={showMobileAuth}
                                />
                            )}
                        />
                        <Route
                            path="/basket"
                            component={() => (
                                <Basket
                                    pageTitle="Pobo - Корзина"
                                    showMobileAuth={showMobileAuth}
                                    onNavigateRequest={this.onNavigateRequest}
                                />
                            )}
                        />
                        <Route
                            path="/payment"
                            component={() => (
                                <Payment
                                    showMobileAuth={showMobileAuth}
                                    bonus_accrual_percent={definitions.orders.bonus_accrual_percent}
                                    restaurants={definitions.layout.restaurants}
                                    profile={profile}
                                    categories={categories}
                                    isAuthorized={authorization.authorized}
                                    setUserProfile={this.setUserProfile}
                                    setAddresses={this.setAddresses}
                                    setProfileBonuses={this.setProfileBonuses}
                                    doAuth={this.doAuth}
                                    cloudpaymentsPublicId={definitions.cloudpaymentsPublicId}
                                    dadataToken={definitions.dadataToken}
                                />
                            )}
                        />
                        {/*<Route*/}
                        {/*    path="/constructor"*/}
                        {/*    component={() => (*/}
                        {/*        <Constructor*/}
                        {/*            showMobileAuth={showMobileAuth}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}
                        <Route
                            path="/status/:id"
                            component={() => (
                                <Status
                                    profile={profile}
                                    showMobileAuth={showMobileAuth}
                                    restaurants={definitions.layout.restaurants}
                                />
                            )}
                        />
                        <Route
                            path="/status/"
                            component={() => (
                                <Status
                                    profile={profile}
                                    showMobileAuth={showMobileAuth}
                                    restaurants={definitions.layout.restaurants}
                                />
                            )}
                        />
                    </Switch>
                    <Footer
                        showMobileAuth={showMobileAuth}
                        setShowMobileMenu={this.setShowMobileMenu}
                        // companyPhone={['+7 (812) 942-87-78','+7 (812) 957-37-32']}
                        companyPhone={definitions.layout.restaurants}
                        socialLinks={definitions.layout.social_links}
                        authorized={authorization.authorized}
                    />
                </HashRouter>
            </StickyContainer>
        );
    }
}

App.propTypes = {
    products: PropTypes.instanceOf(Array).isRequired,
    definitions: PropTypes.instanceOf(Object).isRequired,
    profile: PropTypes.instanceOf(Object).isRequired,
    getProfile: PropTypes.func.isRequired,
    loadCart: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    getDefinitions: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    changeAddress: PropTypes.func.isRequired
};
export default App;
