import { combineReducers } from "redux";
import hitsReducer from "./hitsReducer";
import productsReducer from "./productsReducer";
import definitionsReducer from "./definitionsReducer";
import profileReducer from "./profileReducer";
import cartReducer from "./cartReducer";
import paymentReducer from "./paymentReducer";
import constructorReducer from "./constructorReducer";
import toppingsReducer from "./toppingsReducer";

const reducer = combineReducers({
    hits: hitsReducer,
    products: productsReducer,
    definitions: definitionsReducer,
    profile: profileReducer,
    cart: cartReducer,
    payment: paymentReducer,
    bowl_constructor: constructorReducer,
    listOfToppings: toppingsReducer,
});

export default reducer;
