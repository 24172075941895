import React from "react";
import { getRequest } from "../../helpers";
import PreviousOrder from "./PreviousOrder";
import Button from "../Button";

class MyOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: this.props.orders,
            ordersPage: 1,
        };
    }

    loadMoreOrders() {
        const self = this;

        getRequest("/order/list/?page=" + (this.state.ordersPage + 1))
            .then((res) => {
                if (res.data.success) {
                    const orders = this.state.orders.slice();

                    self.setState({
                        ordersPage: this.state.ordersPage + 1,
                        orders: orders.concat(res.data.result.items),
                    });
                }
            });
    }

    get button() {
        return (
<Button
            buttonType="default"
            buttonTitle="Ещё заказы"
            buttonClass="personal-button_light load-more-button"
            onClickMethod={() => {
                this.loadMoreOrders();
            }}
            onClickSubmit={true}
        />
);
    }

    render() {
        return (
<div>
            {this.state.orders && this.state.orders.map((item, i) => {
                return (<PreviousOrder key={'order' + item.order_number} isExpand={i === 0} item={item} repeateOrder={this.props.repeateOrder.bind(this)} />);
            })}
            {this.state.orders ? this.button : ''}
        </div>
);
    }
}

export default MyOrders;
