import { connect } from "react-redux";
import Header from "./Header";
import {setPaymentWithBonus, setBonusDiscount, setPromoDiscount, setUsePromo} from "../../AC";

function mapStateToProps(state) {
    return { cart: state.cart, payment: state.payment , bowl_constructor: state.bowl_constructor};
}
const mapDispatchToProps = {
    setPaymentWithBonus,
    setUsePromo,
    setPromoDiscount,
    setBonusDiscount
};

const ConnectedHeader = connect(
    mapStateToProps, mapDispatchToProps
)(Header);

export default ConnectedHeader;
