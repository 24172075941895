import { GET_DEFINITIONS, ERR } from "../AC";
import initState from "../store/initState";

function definitionsReducer(definitions = initState.definitions, { type, payload: data, }) {
    switch (type) {
    case GET_DEFINITIONS: {
        return data.result;
    }
    case GET_DEFINITIONS + ERR: {
        console.log(data);
        return definitions;
    }
    default: {
        return definitions;
    }
    }
}
export default definitionsReducer;
