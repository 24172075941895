import { GET_PRODUCTS, ERR } from "../AC";
import initState from "../store/initState";

function productsReducer(products = initState.products, { type, payload: data, }) {
    switch (type) {
    case GET_PRODUCTS: {
        const cats = [];
        data.result.items.forEach((item) => {
            if (!item.categories[0].published) return;
            const index = cats.findIndex(cat => cat.category.id === item.categories[0].id);
            if (index > -1) {
                cats[index].items.push(item);
            } else {
                cats.push({
                    category: item.categories[0],
                    items: [item],
                });
            }
        });
        cats.sort((a, b) => a.category.ordering - b.category.ordering);
        return cats;
    }
    case GET_PRODUCTS + ERR: {
        console.log(data);
        return products;
    }
    default: {
        return products;
    }
    }
}
export default productsReducer;
