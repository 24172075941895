export const GET_CART = "GET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_ALL_ITEMS_FROM_CART = "REMOVE_ALL_ITEMS_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const LOAD_CART = "LOAD_CART";
export const SET_PAYMENT_WITH_BONUS = "SET_PAYMENT_WITH_BONUS";
export const SET_USE_PROMO = "SET_USE_PROMO";
export const SET_PROMO_DISCOUNT = "SET_PROMO_DISCOUNT";
export const SET_BONUS_DISCOUNT = "SET_BONUS_DISCOUNT";
export const SET_CONSTRUCTOR_VISIBLE = "SET_CONSTRUCTOR_VISIBLE";
export const HAS_PAYMENT_ADDRESS = "";
export const RELOAD_DATA = "RELOAD_DATA";
export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_PROMOCODE_DATA = "SET_PROMOCODE_DATA";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_CONTRUCTOR_DATA = "SET_CONTRUCTOR_DATA";
export const SET_ADDRESS_DATA = "SET_ADDRESS_DATA";
export const SET_NOT_BONUSES = "SET_NOT_BONUSES";

export const ADD_TOPPING = "ADD_TOPPING";
export const ADD_TO_LIST_OF_TOPPINGS = "ADD_TO_LIST_OF_TOPPINGS";
export const REMOVE_TOPPING = "REMOVE_TOPPING";

export function addTopping(id, topping) {
    return {
        type: ADD_TOPPING,
        id,
        topping,
    };
}

export function removeTopping(id, topping) {
    return {
        type: REMOVE_TOPPING,
        id,
        topping,
    };
}

export function addToListOfToppings(topping) {
    return {
        type: ADD_TO_LIST_OF_TOPPINGS,
        payload: topping,
    };
}

export function addToCart(item) {
    return {
        type: ADD_TO_CART,
        item,
    };
}

export function removeFromCart(id) {
    return {
        type: REMOVE_FROM_CART,
        id,
    };
}

export function removeAllItemsFromCart(id) {
    return {
        type: REMOVE_ALL_ITEMS_FROM_CART,
        id,
    };
}

export function loadCart(items) {
    return {
        type: LOAD_CART,
        items,
    };
}

export function clearCart() {
    return { type: CLEAR_CART, };
}

export function setPaymentWithBonus(flag_bonus) {
    return {
        type: SET_PAYMENT_WITH_BONUS,
        flag_bonus,
    };
}

export function setBonusDiscount(discount) {
    return {
        type: SET_BONUS_DISCOUNT,
        discount,
    };
}

export function setUsePromo(flag_bonus) {
    return {
        type: SET_USE_PROMO,
        flag_bonus,
    };
}

export function setPromoDiscount(discount) {
    return {
        type: SET_PROMO_DISCOUNT,
        discount,
    };
}

export function setConstructorVisible(flag) {
    return { type: SET_CONSTRUCTOR_VISIBLE, payload: flag, };
}

export function reloadData(flag) {
    return { type: RELOAD_DATA, payload: flag, };
}

export function changeAddress(address) {
    return { type: CHANGE_ADDRESS, payload: address, };
}
export function setPaymentMethod(type) {
    return { type: SET_PAYMENT_METHOD, payload: type, };
}
export function setPromocodeData(data) {
    return { type: SET_PROMOCODE_DATA, payload: data, };
}

export function setErrorMessage(error) {
    return { type: SET_ERROR_MESSAGE, payload: error, };
}

export function setConstructorData(data) {
    return { type: SET_CONTRUCTOR_DATA, payload: data, };
}

export function setAddress(data) {
    return {
        type: SET_ADDRESS_DATA,
        payload: data,
    };
}

export function setNotBonuses(flag) {
    return {
        type: SET_NOT_BONUSES,
        payload: flag,
    };
}
