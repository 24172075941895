import { connect } from "react-redux";
import App from "./Constructor";
import {addToCart, removeFromCart, setConstructorData, setConstructorVisible} from "../../../AC";

function mapStateToProps(state) {
    return {
        bowl_constructor: state.bowl_constructor
        // products: state.products,
        // definitions: state.definitions,
        // profile: state.profile,
    };
}

const ConnectedApp = connect(
    mapStateToProps,
    { addToCart, removeFromCart,setConstructorVisible,setConstructorData }
)(App);

export default ConnectedApp;
