import { RSAA } from "redux-api-middleware";
import { START, ERR } from "./ACcommon";
import { requestUri } from "../../common/config";
import { getRequestHeaders } from "../helpers";

export const GET_HITS = "GET_HITS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_DEFINITIONS = "GET_DEFINITIONS";
export const GET_PROFILE = "GET_PROFILE";

export function getHits() {
    return {
        [RSAA]: {
            types: [START, GET_HITS, GET_HITS + ERR],
            method: "GET",
            endpoint: `${requestUri}/products/featured`,
            headers: getRequestHeaders(),
        },
    };
}

export function getProducts() {
    return {
        [RSAA]: {
            types: [START, GET_PRODUCTS, GET_PRODUCTS + ERR],
            method: "GET",
            endpoint: `${requestUri}/products`,
            headers: getRequestHeaders(),
        },
    };
}

export function getDefinitions() {
    return {
        [RSAA]: {
            types: [START, GET_DEFINITIONS, GET_DEFINITIONS + ERR],
            method: "GET",
            endpoint: `${requestUri}/definitions`,
            headers: getRequestHeaders(),
        },
    };
}

export function getProfile() {
    return {
        [RSAA]: {
            types: [START, GET_PROFILE, GET_PROFILE + ERR],
            method: "GET",
            endpoint: `${requestUri}/profile?addresses=true&orders=true&cards=true`,
            headers: getRequestHeaders(),
        },
    };
}
