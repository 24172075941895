import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss"
import "./MarketSlides.scss";

class MarketSlides extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.slides !== this.props.slides) {
            return true;
        }

        return false;
    }

    render() {
        const { slides, activeSlideIndex, moreCssClass, } = this.props;
        const items = slides.map(item => <img key={item.image} src={item.image} alt="" />);
        const params = {
            slidesPerView: 1,
            spaceBetween: 0,
            activeSlideKey: activeSlideIndex,
            loop: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        };

        return (
            <div className={`slide-container ${moreCssClass}`}>
                <Swiper {...params}>
                    {items}
                </Swiper>
            </div>
        );
    }
}
MarketSlides.propTypes = {
    moreCssClass: PropTypes.string,
    activeSlideIndex: PropTypes.number,
    slides: PropTypes.instanceOf(Array),
};
MarketSlides.defaultProps = {
    moreCssClass: "",
    activeSlideIndex: null,
    slides: [],
};
export default MarketSlides;
