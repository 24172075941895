import React from "react";
import PropTypes from "prop-types";
import bonusIcon from "./bonus-icon.svg";
import bonusIconMobile from "./bonus-icon-mobile.svg";
import "./Bonus.scss";

class Bonus extends React.Component{
    componentDidMount() {
        this.props.setDueBonus(true);
    }

    componentWillUnmount() {
        this.props.setDueBonus(false);
    }

    render() {
        return (
            <div>
                <div>
                    <div className="spend-points__title-container">
                        <div className="spend-points__title">
                            Потратить баллы
                            <span className="spend-points-bonuses-mobile">
                        <span className="spend-points-bonuses-mobile__amount">{this.props.bonuses}</span>
                        <img src={bonusIconMobile} alt="" />
                    </span>
                        </div>
                    </div>
                    <div className="spend-points-bonuses">
                        <div className="spend-points-bonuses__desctop">
                            <span className="spend-points-bonuses__amount">{this.props.bonuses}</span>
                            <img src={bonusIcon} alt="" />
                        </div>
                        <div className="spend-points-bonuses__hint">
                            Побо-бонусами можно оплатить 50%&nbsp;от&nbsp;суммы заказа
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Bonus.propTypes = {
    setDueBonus: PropTypes.func.isRequired,
    bonuses: PropTypes.number,
};
Bonus.defaultProps = { bonuses: undefined, };

export default Bonus;
