import React from "react";
import PropTypes from "prop-types";

import "./AboutItem.scss";

function AboutItem({ title, text, img, reverse, }) {
    const newText = text.replace(/<<(.*?)>>/g, "<span class='about-item__text_red'>$1</span>");

    return (
        <div className={`about-item ${reverse && "row-reverse"}`}>
            <div className={`about-item__img-container`}>
                <img src={img} className="about-item__img" alt="Фото секции" />
            </div>
            <div className="about-item__content">
                <h2 className="about-item__title">
                    <span dangerouslySetInnerHTML={{ __html: title, }} />
                </h2>
                <p className="about-item__text" dangerouslySetInnerHTML={{ __html: newText, }} />
            </div>
        </div>
    );
}

AboutItem.propTypes = {
    img: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

AboutItem.defaultProps = { reverse: false, };

export default AboutItem;
