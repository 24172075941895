import { getRequest } from "../helpers";
import Loading from "../components/Elements/Loader/Loader";

const withFetch = (url, Comp) => {
    class SubComponent extends React.Component {
        state = {
            data: null,
            isLoading: true,
        };

        mounted = true;

        componentDidMount() {
            const urlAdd = this.props.urlAdd;
            getRequest(url + urlAdd)
                .then((result) => {
                    if (this.mounted) {
                        this.setState({ data: result.data, isLoading: false, });
                    }
                })
                .catch((error) => {
                    console.log("error.request", error.request);
                    console.log("error.message", error.message);
                    if (this.mounted) {
                        this.setState({ isLoading: false, });
                    }
                });
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        render() {
            if (this.state.isLoading) {
                return <Loading />;
            }
            if (!this.state.data && !this.state.isLoading) {
                return <p>No data</p>;
            }

            return <Comp {...this.props} data={this.state.data} />;
        }
    }
    SubComponent.propTypes = { urlAdd: PropTypes.string, };
    SubComponent.defaultProps = { urlAdd: "", };
    return SubComponent;
};

export default withFetch;
