import React from "react";

class ValidationResult extends React.Component {
    render() {
        if (this.props.error) {
            return (
                <div className="validation-result">
                    <span className="validation-result__icon">
                        <svg width="15" height="15" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5.5" cy="6.5" r="5.1" stroke="#FD7065" strokeWidth="0.8" />
                            <path d="M5.95047 7.77445L6.13047 3.13945H5.05047L5.23047 7.77445H5.95047ZM5.00547 8.94445C5.00547 9.25945 5.27547 9.52945 5.59047 9.52945C5.90547 9.52945 6.17547 9.25945 6.17547 8.94445C6.17547 8.62945 5.90547 8.35945 5.59047 8.35945C5.27547 8.35945 5.00547 8.62945 5.00547 8.94445Z" fill="#FD7065" />
                        </svg>
                    </span>
                    <span className="validation-result__error">{this.props.error}</span>
                </div>
            );
        }

        return "";
    }
}

export default ValidationResult;
