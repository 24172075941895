
import React from 'react';

const Burger = () => {
    return (
        <svg width='25' height='14' viewBox='0 0 25 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect y='10.7539' width='24.2' height='2.68889' rx='1' fill='#3D3B3B'/>
            <rect y='5.37695' width='24.2' height='2.68889' rx='1' fill='#3D3B3B'/>
            <rect width='24.2' height='2.68889' rx='1' fill='#3D3B3B'/>
        </svg>
    );
};

export default Burger;

