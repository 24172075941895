import React from 'react';

const Facebook = () => {
    return (
        <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M20.7364 0H4.06977C1.74419 0 0 1.74419 0 4.06977V20.9302C0 23.062 1.74419 25 4.06977 25H20.9302C23.062 25 25 23.2558 25 20.9302V4.06977C24.8062 1.74419 23.062 0 20.7364 0ZM22.093 20.7364C22.093 21.5116 21.5116 22.093 20.7364 22.093H12.5969V16.2791H15.5039V13.7597H12.5969V11.4341C12.5969 9.30233 14.3411 7.55814 16.4729 7.55814V5.03876C12.9845 5.03876 10.0775 7.94574 10.0775 11.4341V13.7597H7.17054V16.2791H10.0775V22.093H4.06977C3.29457 22.093 2.71318 21.5116 2.71318 20.7364V4.06977C2.71318 3.29457 3.29457 2.71318 4.06977 2.71318H20.9302C21.7054 2.71318 22.2868 3.29457 22.2868 4.06977V20.7364H22.093Z' fill='#F8819C'/>
        </svg>
    );
};

export default Facebook;
