import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRequest } from "../../helpers";
import arrow from "./arrow.svg";
import Toppings from "./Toppings";
import { addTopping, addToListOfToppings } from "../../AC";
import "./toppings.scss";

export default function ToppingButton({ item, }) {
    const [toppingsIsOpen, setToppingsIsOpen] = React.useState(false);
    const [addToppingModal, setAddToppingModal] = React.useState(false);
    const [toppingsWithData, setToppingsWithData] = React.useState([]);

    const toppings = useSelector(state => state.listOfToppings);
    const cart = useSelector(state => state.cart);
    const dispatch = useDispatch();

    const product = item.itemData;

    const onAddModal = (e) => {
        e.preventDefault();
        setAddToppingModal(!addToppingModal);
    };

    const onAddTopping = (e, topping) => {
        e.preventDefault();
        setAddToppingModal(false);
        dispatch(addTopping(product.id, topping));
    };

    const onShowToppingClick = (e) => {
        e.preventDefault();
        setToppingsIsOpen(!toppingsIsOpen);
    };

    React.useEffect(() => {
        if (item && item.itemData && item.itemData.toppings) {
            item.itemData.toppings.forEach((toppingItem) => {
                if (!toppings.some(
                    topping => topping && toppingItem && topping.id === toppingItem.id
                )) {
                    getRequest(`/products/topping?topping_id=${toppingItem.id}`).then(data => dispatch(
                        addToListOfToppings({
                            ...data.data.result,
                            amount: 0,
                        })
                    ));
                }
            });
        }
    }, [item]);

    React.useEffect(() => {
        const currentToppings = [];
        if (item && item.itemData && item.itemData.toppings) {
            item.itemData.toppings.forEach((topping) => {
                let toppingData = null;
                toppings.forEach((toppingItem) => {
                    if (topping && toppingItem && toppingItem.id === topping.id) {
                        toppingData = toppingItem;
                    }
                });
                if (toppingData) {
                    const toppingAmount = topping.amount || 0;
                    currentToppings.push({ ...toppingData, amount: toppingAmount, });
                }
            });
        }
        setToppingsWithData(currentToppings);
    }, [toppings, cart, item]);

    return (
    <>
      {toppingsWithData && toppingsWithData.length !== 0 && (
          <div className="basket-pos__toppings">
              <div className="basket-pos__toppings-wrapper" onClick={onShowToppingClick}>
                  <h1 className="basket-pos__toppings-wrapper__title">Топпинги</h1>
                  <button type="button" className="basket-pos__toppings-wrapper__show-items-btn">
                      <img
                          className={`basket-pos__toppings-wrapper__show-items-btn__icon ${toppingsIsOpen && "rotate"}`}
                          src={arrow}
                          alt="⬇️"
                      />
                  </button>
              </div>
              <section className={`basket-pos__toppings-body ${toppingsIsOpen && "open"}`}>
                  <div className="basket-pos__toppings-body__toppings-list">
                      <Toppings
                          smallCart
                          product={{
                              ...item.itemData,
                              toppings: toppingsWithData,
                          }}
                      />
                  </div>
                  {toppingsWithData && toppingsWithData.some(
                      topping => topping && topping.amount === 0
                  ) && (
                      <div className="basket-pos__toppings-body__toppings-add">
                          <button
                              type="button"
                              className={`basket-pos__toppings-body__toppings-add-btn ${addToppingModal && "show"}`}
                              onClick={onAddModal}
                          >
                  + Добавить
                          </button>
                          <div className={`basket-pos__toppings-body__toppings-add__list ${addToppingModal && "show"}`}>
                              {toppingsWithData
                    && toppingsWithData
                        .filter(topping => topping && topping.amount === 0)
                        .map(topping => (
                            <button
                                key={topping.id}
                                type="button"
                                className="basket-pos__toppings-body__toppings-add__list-item"
                                onClick={(e) => {
                                    onAddTopping(e, topping);
                                }}
                            >
                                {topping.name} {topping.price} ₽
                            </button>
                        ))}
                          </div>
                      </div>
                  )}
              </section>
          </div>
      )}
    </>
    );
}

ToppingButton.propTypes = { item: PropTypes.instanceOf(Object).isRequired, };
