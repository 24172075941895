import { SET_CONSTRUCTOR_VISIBLE, SET_CONTRUCTOR_DATA, SET_NOT_BONUSES } from "../AC";

const initialState = {
    view_constructor: false,
    data: {},
    not_bonuses: false,
};

function constructorReducer(state = initialState, action) {
    const { type, payload, } = action;
    switch (type) {
    case SET_CONSTRUCTOR_VISIBLE:
        return { ...state, view_constructor: payload, };
    case SET_CONTRUCTOR_DATA:
        return { ...state, data: payload, };
    case SET_NOT_BONUSES:
        return { ...state, not_bonuses: payload, };
    default:
        return { ...state, };
    }
}

export default constructorReducer;
