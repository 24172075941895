import React from "react";
import { YMaps, Map, Placemark, ZoomControl, Polygon, GeolocationControl } from "react-yandex-maps";

import { requestUri } from "../../../../common/config";

class RestMap extends React.Component {
    state = { windowSize: screen.width, pins: [] }
    ////.replace(/[(]/g,'[').replace(/[)]/g,']')

    componentDidMount() {
        window.onresize = () => {
            if (Math.abs(this.state.windowSize - screen.width) > 20) {
                this.setState({ windowSize: screen.width, });
            }
        };
        fetch(requestUri + 'zones', {
            method: 'GET'
        }).then(response => {
            response.json().then(res =>{
                this.setState({pins: res})
            })
        })
    }

    disableDrag = (ref, windowSize) => {
        if (windowSize < 768 && ref) {
            ref.behaviors.disable("drag");
            ref.behaviors.disable("scrollZoom");
        } else if (windowSize > 768 && ref) {
            ref.behaviors.enable("drag");
            ref.behaviors.enable("scrollZoom");
        }
    };

    reverseArray = (array) => {
        let arr = [];
        array.forEach(item =>{
            arr.push([item[1],item[0]])
        })
        return arr;
    }

    render() {
        return (
            <div className="ymap-container">
                <YMaps>
                    <Map
                        width="100%"
                        height="100%"
                        instanceRef={ref => this.disableDrag(ref, this.state.windowSize)}
                        defaultState={{
                            center: [59.944199, 30.286271],
                            zoom: 10,
                        }}
                    >
                        {this.state.pins && this.state.pins.map(pin => {
                            return(
                                <Polygon
                                    options={{
                                        fillColor: pin.fill,
                                        opacity: pin.fill_opacity,
                                        strokeColor: pin.stroke,
                                        strokeWidth: pin.stroke_width,

                                        strokeStyle: "shortdash",
                                    }}
                                    geometry={[this.reverseArray(JSON.parse(pin.zone.replace(/[(]/g,'[').replace(/[)]/g,']')))]}
                                />
                            )
                        })}
                        {this.state.pins && this.state.pins.map(pin => {
                            let coord = JSON.parse(pin.pin.replace(/[(]/g,'[').replace(/[)]/g,']'))
                            return(
                                <Placemark
                                    onClick={() => window.open("https://yandex.ru/maps/-/CCarJ-LN", "_blank")}
                                    geometry={[coord[1], coord[0]]}
                                    properties={{
                                        iconCaption: pin.min_payment_amount === 0 ? 'Без min суммы' : `от ${pin.min_payment_amount || 500} руб`,
                                    }}
                                />
                            )
                        })}
                        <ZoomControl
                            options={{
                                size: "small",
                                zoomDuration: 1000,
                                position: { top: 40, right: 3, },
                            }}
                        />
                        <GeolocationControl options={{ float: "left", }} />
                    </Map>
                </YMaps>
            </div>
        );
    }
}

export default RestMap;
