import React from "react";
import PropTypes from "prop-types";
import "./PersonCount.scss";

function PersonCount({ changePersonCount, cuttlery_quantity, }) {
    return (
        <div className="payment__block payment__block--half">
            <div className="payment__block-person-count-container">
                <div className="payment__block-title">Количество персон</div>
                <input
                    className="payment__block-input"
                    type="number"
                    defaultValue={cuttlery_quantity}
                    onChange={changePersonCount}
                    min='1'
                />
            </div>
        </div>
    );
}

PersonCount.propTypes = {
    changePersonCount: PropTypes.func.isRequired,
    cuttlery_quantity: PropTypes.number.isRequired,
};

export default PersonCount;
