import { reactLocalStorage } from "reactjs-localstorage";

const initState = {
    hits: [],
    cart: reactLocalStorage.getObject("cart").items || [],
    products: [],
    profile: {},
    definitions: {
        layout: {
            company_phone: "",
            restaurants: [],
            social_links: {},
            frontpage_slider: [],
            mobile_slider: [],
        },
        orders: {
            bonus_accrual_percent: 0,
        },
    },
    listOfToppings: [],
};
export default initState;
