import { connect } from "react-redux";
import Component from "./BasketMobile";

function mapStateToProps(state) {
    return { cart: state.cart, };
}

const ConnectedComponent = connect(
    mapStateToProps,
)(Component);

export default ConnectedComponent;
