import React from "react";

function Button(props) {
    const { onClickMethod, onClickSubmit, buttonType, buttonClass, buttonTitle, disabled = false } = props;

    if (onClickSubmit) {
        return (
            <button
                type="submit"
                className={`button button-${buttonType} ${buttonClass}`}
                onClick={onClickMethod}
                disabled={disabled}
            >
                {buttonTitle}
            </button>
        );
    }
    return (
        <div
            className={`button button-${buttonType} ${buttonClass}`}
            onClick={onClickMethod}
        >
            {buttonTitle}
        </div>
    );
}
Button.propTypes = {
    buttonTitle: PropTypes.string.isRequired,
    buttonType: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
    onClickMethod: PropTypes.func,
    onClickSubmit: PropTypes.bool,
};
Button.defaultProps = {
    onClickMethod: () => { },
    onClickSubmit: null,
    buttonClass: "",
};
export default Button;
