import "./Email.scss";

function Email({ invoiceEmail, setInvoiceEmail, }) {
    const changeMail = (e) => {
        const val = e.currentTarget.value;
        // const val = e.currentTarget.value.toLowerCase();
        setInvoiceEmail(val);
    };
    const keyDownHandle = (e) => {
        if (e.key === " ") e.preventDefault();
    };
    return (
        <div className="col col-2 email-block">
            <div className="email-block__title">Ваш e-mail</div>
            <div className="email-block__content">
                <label htmlFor="email">На вашу почту мы будем отправлять чеки</label>
                <input
                    type="text"
                    className="email-block__input"
                    id="email"
                    name="email"
                    autoComplete="off"
                    value={invoiceEmail}
                    onChange={changeMail}
                    onKeyDown={keyDownHandle}
                />
            </div>
        </div>
    );
}
Email.propTypes = {
    invoiceEmail: PropTypes.string,
    setInvoiceEmail: PropTypes.func.isRequired,
};
Email.defaultProps = { invoiceEmail: "", };
export default Email;
