import React from "react";
import PropTypes from "prop-types";
import BonusIcon from "../../icons/BonusIcon";
import { formatCurrency, getBoulTitle } from "../../../helpers";
import { connect } from "react-redux";
import "./Order.scss";
import {reloadData, setPromoDiscount, setUsePromo} from "../../../AC";

class Order extends React.PureComponent {
    get bonuses() {
        let bonuses = 0;
        if (this.props.bonusesAccrued)
            bonuses = parseInt(this.props.bonusesAccrued, 10);
        else if (this.props.bonusAccrualPercent)
            bonuses = Math.ceil(this.props.total_due * this.props.bonusAccrualPercent);
        return bonuses;
    }

    render() {
        const { order, total_due, total, } = this.props;
        const discount = total - total_due;
        return (
            <div>
                <div className="history-orders__title">Ваш заказ</div>
                {this.props.date &&
                    <p className="history-orders__title pink-label">{this.props.date}</p>
                }
                <div className="history-orders__list">
                    {order.map(product => (
                        <div className="order__order-item" key={product.title}>
                            <div className="order__item-container">
                                <div className="order__item-title">
                                    {product.title}
                                    {"\u00A0"}
                                    {product.quantity > 1 ? "x" + product.quantity : ""}
                                </div>
                                <div className="order__item-price">{formatCurrency(parseInt(product.price, 10) || product.amount)}</div>
                            </div>
                            <div className="order__description">
                                {product.description ? product.description : getBoulTitle(product.groups)}
                                {product.categories && product.categories.reduce((accumulator, currentValue) =>  (currentValue.bonus_enabled === false) || accumulator, false) && 
                                (<i className="bonus_warning"><br/>Промокоды и бонусы не распространяются</i>)
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <div className="history-orders__result">
                    {(discount > 0)
                        && (
                            <div className="history-orders__discount">
                                <div className="history-orders__discount-title">Скидка</div>
                                <div className="history-orders__discount-amount">{this.props.payment.use_promo ?
                                    formatCurrency(this.props.payment.promo_discount):
                                    formatCurrency(discount)
                                        }</div>
                            </div>
                        )
                    }
                    <div className="history-orders__title history-orders__total">
                        <div>Сумма заказа</div>
                        <div className="history-orders__total">{formatCurrency(total_due)}</div>
                    </div>
                    {discount === 0 &&
                        <div className="history-orders__bonuses">
                            Будет начислено {this.bonuses} <BonusIcon />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

Order.propTypes = {
    bonusAccrualPercent: PropTypes.number.isRequired,
    bonusesAccrued: PropTypes.number,
    total_due: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.instanceOf(Array).isRequired,
};
Order.defaultProps = { bonusesAccrued: undefined, };

// export default Order;

const mapStateToProps = store => {
    return{
        payment: store.payment,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        reloadData: (flag) => dispatch(reloadData(flag)),
        setPromoDiscount: (data) => dispatch(setPromoDiscount(data)),
        setUsePromo: (flag) => dispatch(setUsePromo(flag))
    }
};
export default connect(mapStateToProps)(Order)
