const NODE_ENV = process.env.NODE_ENV || "development";

const IS_DEV = NODE_ENV === "development";

// for build app: npx webpack src

const PORT = IS_DEV ? 8080 : process.env.PORT;

// const requestUri = IS_DEV ? "https://api.backend.pobo.webjox.ru/api/" : "https://pobo.ru/api/";

const requestUri = "https://done.pobo.ru/api/";

module.exports = {
    IS_DEV,
    PORT,
    requestUri,
};
