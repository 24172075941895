import { GET_HITS, ERR } from "../AC";
import initState from "../store/initState";

function hitsReducer(hits = initState.hits, { type, payload: data, }) {
    switch (type) {
    case GET_HITS: {
        return data.result.items;
    }
    case GET_HITS + ERR: {
        console.log(data);
        return hits;
    }
    default: {
        return hits;
    }
    }
}
export default hitsReducer;
