import { scroller } from "react-scroll";
import { withRouter } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const scrollOpt = {
    isDynamic: true,
    offset: -120,
    spy: true,
    smooth: true,
    duration: 500,
};

class Menu extends React.Component {

    renderMenuItem = (item, i, active) => (
        <NavHashLink
            key={i}
            to={`/#${item.alias}`}
            className={`menu__item ${active && "menu__item_active"}`}
            scroll={(el) => {
                const elName = el.getAttribute("name");
                scroller.scrollTo(elName, scrollOpt);
            }}
        >
            {item.title}
        </NavHashLink>
    )

    goHref = href => () => {
        const { location, setShowMobileMenu, history, } = this.props;
        const hereAndModile = location.pathname === href && setShowMobileMenu;
        return hereAndModile ? setShowMobileMenu() : history.push(href);
    }

    renderLink = item => (
        <div
            key={item.link}
            className="menu__item"
            onClick={this.goHref(item.link)}
        >
            {item.title}
        </div>
    )

    render() {
        const { menuItems, location} = this.props;
        if (!menuItems.length) return null;
        return (
            <nav className="menu">
                {menuItems.map((item, i) => {
                    const active = location.hash === `#${item.alias}`;
                    return (
                        item.link
                            ? this.renderLink(item, i)
                            : this.renderMenuItem(item, i, active)
                    );
                })}
            </nav>
        );
    }
}
Menu.propTypes = {
    menuItems: PropTypes.instanceOf(Array),
    setShowMobileMenu: PropTypes.func,
    location: PropTypes.shape({
        hash: PropTypes.string.isRequired,
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired, }).isRequired
};
Menu.defaultProps = {
    menuItems: [],
    setShowMobileMenu: null,
};
export default withRouter(Menu);
