import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { requestUri } from "../common/config";

export const PROD = true;

function getCart() {
    const cart = reactLocalStorage.getObject("cart");
    return cart.items ? cart.items : [];
}

function getRequest(requestApi) {
    return axios.get(requestUri + requestApi, getRequestHeaders());
}

function postRequest(requestApi, requestParams) {
    return axios.post(requestUri + requestApi, requestParams, getRequestHeaders());
}

function getRequestHeaders() {
    const token = getCookie("authToken");
    const headers = token ? ({ headers: { Authorization: "Bearer " + token, }, }) : undefined;
    return headers;
}

function findIndexItem(cartItems, product) {
    let elementId = -1;

    cartItems.forEach((element, index) => {
        if (element.itemData.id === product.id) {
            elementId = index;
        }
    });

    return elementId;
}

export function elsInCart(cart, id) {
    let itemsInCart = 0;
    cart.forEach((element) => {
        const elementId = element.itemData ? element.itemData.id : element.id;
        if (elementId === id) {
            itemsInCart = element.numberItems;
        }
    });
    return itemsInCart;
}

function setDeliveryMethod(type) {
    reactLocalStorage.setObject("delivery", { delivery: type, });
}

function getDeliveryMethod() {
    return reactLocalStorage.getObject("delivery");
}

function getCartItem(item, count) {
    if (item.type !== "set") {
        item.type = "product";
    }

    return {
        inCart: true,
        numberItems: count,
        itemData: item,
    };
}

function removeAllItemsFromCart(product) {
    const cartItems = getCart();
    const elementId = findIndexItem(cartItems, product);

    if (elementId > -1) {
        cartItems.splice(elementId, 1);
    }

    reactLocalStorage.setObject("cart", { items: cartItems, });

    return cartItems;
}

function loadCart(items) {
    const products = [];

    items.map((item, i) => {
        products.push(getCartItem(item, item.quantity ? item.quantity : 1));
    });

    reactLocalStorage.setObject("cart", { items: products, });

    return products;
}

function checkItemInCart(id) {
    const cart = getCart();
    const item = cart.filter(product => product.itemData.id === id);
    return !!item[0];
}

export function checkItemInCart2(cart, id) {
    const item = cart.filter(product => product.itemData.id === id);
    return !!item[0];
}

function addItemToCart(product) {
    const cartItems = getCart();
    const elementId = findIndexItem(cartItems, product);

    if (elementId > -1) {
        cartItems[elementId].numberItems += 1;
    } else {
        cartItems.push(getCartItem(product, 1));
    }

    reactLocalStorage.setObject("cart", { items: cartItems, });

    return cartItems;
}

function getTotalPrice() {
    const cart = getCart();
    let cartPrice = 0;

    cart.forEach((element) => {
        cartPrice += parseInt(element.itemData.price ? element.itemData.price : element.itemData.amount, 10) * element.numberItems;
    });

    return cartPrice;
}

export function getPrice1(cart) {

    let cartPrice = 0;

    cart.forEach((el) => {
        cartPrice += parseInt(el.itemData.price
            ? el.itemData.price
            : el.itemData.amount, 10) * el.numberItems;

        if (el && el.itemData && el.itemData.toppings && el.itemData.toppings.length !== 0) {
            el.itemData.toppings.forEach((topping) => {
                if (topping.price) { cartPrice += parseInt(topping.price, 10) * topping.amount; }
            });
        }
    });

    return cartPrice;
}

export function getTotalCount(cart) {
    let count = 0;
    cart.forEach((element) => {
        if (element.numberItems) {
            count += parseInt(element.numberItems, 10);
        }
    });

    return count;
}

function getCookie(name) {
    const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));

    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
    options = options || {};

    let expires = options.expires;
    const date = new Date();
    let propName;
    let propValue;
    let updatedCookie;

    if (typeof expires === "number" && expires) {
        date.setTime(date.getTime() + expires * 1000);
        expires = options.expires = date;
    }

    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);
    updatedCookie = name + "=" + value;

    for (propName in options) {
        updatedCookie += "; " + propName;
        propValue = options[propName];

        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

function deleteCookie(name) {
    setCookie(name, "", { expires: -1, });
}

function getBoulTitle(groups) {
    let title = "";

    groups.map((item, i) => {
        item.ingredients && item.ingredients.map((ingredient, index) => {
            if (i > 0) {
                title += ingredient.title.toLowerCase() + ", ";
            } else {
                title += ingredient.title + ", ";
            }
        });
    });

    title = title.substr(0, title.length - 2);

    return title;
}

function focusInputById(id) {
    setTimeout(() => {
        const input = document.getElementById(id);
        if (input) {
            input.focus();
        }
    }, 0);
}

function formatCurrency(value) {
    return (value !== null && value !== undefined) ? `${value} ₽` : "";
}

function formatBonus(value) {
    return value;
}

function getFetchInit(method, headers, body) {
    const init = {
        method,
        cache: "no-cache",
        credentials: "same-origin",
        headers,
        redirect: "follow",
        referrerPolicy: "no-referrer",
        mode: "cors",
    };
    if (method.trim().toLowerCase() !== "get") { init.body = body; }
    return init;
}

export const sendRequest = async (method, end_point, token, data) => {
    const Headers = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
    };
    let success = true;
    return await fetch(requestUri + end_point, getFetchInit(method, Headers, data)).then(async (response) => {
        if (Math.trunc(response.status / 100) === 4) { success = false; }
        const res = await response.json();
        return await res;
    }).catch((err) => {
        console.log(err);
    });
};

export function isEmpty(obj) {
    return Object.keys(obj).length < 1;
}

export {
    getTotalPrice,
    getCart,
    addItemToCart,
    removeAllItemsFromCart,
    getCookie,
    setCookie,
    deleteCookie,
    loadCart,
    focusInputById,
    formatCurrency,
    formatBonus,
    getRequest,
    postRequest,
    setDeliveryMethod,
    getDeliveryMethod,
    getBoulTitle,
    checkItemInCart,
    getRequestHeaders
};
