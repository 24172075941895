import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import PaymentIcon from "../../PaymentIcons/PaymentIcon";
import EditItem from "../../icons/EditItem";
import { getRequest, postRequest, focusInputById } from "../../../helpers";
import BonusIcon from "../../icons/BonusIcon";
import RemoveItemButton from "../../icons/RemoveItemButton";
import Logout from "../../icons/mobile/Logout";
import Addresses from "../Addresses";
import AuthModal from "../../Modals/AuthModal";
import Email from "../Email";
import MyOrders from "../MyOrders";
import BirthbayField from "./BirthbayField";
import ValidationResult from "../ValidationResult";
import Button from "../../Button";

class PersonalArea extends React.Component {
    /**
     * @constructor
     * Method for initialising data
     */
    constructor(props) {
        super(props);

        this.state = {
            birthday: this.props.profile.birthday ? this.stringToDate(this.props.profile.birthday) : "",
            phone: {
                edit: false,
                value: this.props.profile.phone,
            },
            name: {
                edit: false,
                value: this.props.profile.firstname,
                error: "",
            },
            cards: this.props.profile.cards ? this.props.profile.cards : [],
            email: this.props.profile.email,
            emailConfirmed: this.props.profile.email_confirmed,
            emailSubscribed: this.props.profile.email_subscribed,
            bonuses: this.props.profile.bonuses,
            orders: this.props.profile.orders ? this.props.profile.orders : [],
            profile: this.props.profile,
        };
    }

    componentWillMount() {
        this.setState({ phoneNumber: this.props.phoneNumber, });
    }

    repeateOrder(id) {
        const self = this;

        getRequest("/order/get?id=" + id)
            .then((res) => {
                this.props.loadCart(res.data.result.order.items);
                window.location.href = "#/basket";
            });
    }

    renderOrders() {
        return (
            <MyOrders
                orders={this.state.orders.items ? this.state.orders.items : []}
                repeateOrder={this.repeateOrder.bind(this)}
            />
        );
    }

    async getUserProfile() {
        let profile = {};
        if (typeof this.props.getUserProfile === "function") {
            profile = await this.props.getUserProfile();
            this.props.setUserProfile(Object.assign(true, this.state.profile, profile));
        }
    }

    setUserProfile(profile) {
        if (typeof this.props.setUserProfile === "function") {
            this.props.setUserProfile(Object.assign(true, this.state.profile, profile));
        }
    }

    onSavePhoneEdit(data) {
        if (data && data.success === true) {
            this.setUserProfile(data.result);
        }
    }

    onCancelPhoneEdit() {
        const phone = Object.assign({}, this.state.phone);

        phone.edit = false;

        this.setState({ phone, });
    }

    onSaveName(e) {
        e.preventDefault();

        const self = this;

        postRequest("/profile/username", { firstname: this.state.name.value, })
            .then((res) => {
                self.onEditFinish("name");

                if (res && res.data) {
                    if (res.data.success === true) {
                        self.setUserProfile(res.data.result);
                    } else if (res.data.errors) {
                        const error = res.data.errors.map(it => it.message).join(" ");
                        this.onEditError("name", error);
                    }
                }
            });
    }

    onSaveEmail(value) {
        this.setState({ email: value, });
    }

    saveEmailSubscribe(e) {
        postRequest("/profile/subscribe", { email_subscribed: e.currentTarget.checked, })
            .then((res) => {
                if (res && res.data && res.data.success) {
                    this.setUserProfile(res.data.result);
                }
            });
    }

    stringToDate(strDate) {
        let result = new Date();

        if (strDate) {
            const parts = strDate.split(".");

            if (parts && parts.length) {
                const year = parseInt(parts[2], 10);
                const month = parseInt(parts[1], 10) - 1;
                const day = parseInt(parts[0]);

                result = new Date(year, month, day);
            }
        }

        return result;
    }

    onEditStart(name) {
        const param = Object.assign({}, this.state[name]);
        const state = {};
        param.edit = true;
        param.error = "";
        state[name] = param;
        this.setState(Object.assign({}, state));
        focusInputById(name);
    }

    onEditError(name, error) {
        const param = Object.assign({}, this.state[name]);
        const state = {};
        param.edit = true;
        param.error = error;
        state[name] = param;
        this.setState(Object.assign({}, state));
        focusInputById(name);
    }

    onEditFinish(name) {
        const param = Object.assign({}, this.state[name]);
        const state = {};
        param.edit = false;
        param.error = "";
        state[name] = param;
        this.setState(Object.assign({}, state));
    }

    changeField(event, field) {
        const dataFields = Object.assign({}, this.state);

        dataFields[field].value = event.target.value;
        this.setState(dataFields);
    }

    logout() {
        this.props.logout();
    }

    removeCard(id) {
        const self = this;

        postRequest("/cards/revoke", { id, })
            .then((res) => {
                if (res.data.success) {
                    const cards = this.state.cards.slice();
                    let index = -1;

                    cards.map((item, i) => {
                        if (item.id === id) {
                            index = i;
                        }
                    });

                    cards.splice(index, 1);

                    self.setState({ cards, });
                }
            });
    }

    renderCards() {
        if (this.state.cards && this.state.cards.length > 0) {
            return (
                <div className="inf-block my-cards">
                    <h2 className="inf-block__title">Мои карты</h2>
                    <ul className="personal__cards">
                        {this.state.cards.map(item => (
                            <li key={"card" + item.id} className="personal__card">
                                <span className="personal__card-remove" onClick={() => { this.removeCard(item.id); }}><RemoveItemButton /></span>
                                <PaymentIcon
                                    id={item.brand}
                                    style={{ width: 80, }}
                                    className="personal__card-icon"
                                />
                                <div className="personal__card-title">**** {item.number}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }

        return "";
    }

    renderBonuses() {
        return (
            <div className="bonuses">
                <div className="inf-block-bonuses">
                    <div className="inf-block-bonuses__title">Побо-бонусы</div>
                    <div className="bonuses__container">
                        <div className="bonuses__value">{this.state.bonuses} <BonusIcon /></div>
                    </div>
                    <div className="bonuses-desc">
                        Побо-бонусами можно оплатить 50% заказа.
                        Бонусы сгорают через полгода бездействия.
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={classNames({ "hide-element": this.props.showMobileAuth, })}>
                <AuthModal
                    modalTitle="Изменение номера телефона"
                    modalText="Укажите телефон, куда прислать СМС с кодом подтверждения"
                    modalDescription=""
                    requestAuthCodeApi="/profile/phone"
                    confirmAuthCodeApi="/profile/phone-confirm"
                    isOpen={this.state.phone.edit}
                    phoneNumber={this.state.phone.value}
                    onSuccess={this.onSavePhoneEdit.bind(this)}
                    onClose={this.onCancelPhoneEdit.bind(this)}
                />
                <div className="container">
                    <div className="mobile-only">
                        <h2 className="page__title">Личный кабинет <span className="logout-button" onClick={this.logout.bind(this)}><Logout /></span></h2>
                    </div>
                    <div className="personal-area">
                        <div className="main">
                            <div className="mobile-only">
                                {this.renderBonuses()}
                            </div>
                            <div className="inf-block">
                                <h2 className="inf-block__title">Личная информация<span className="logout-button" onClick={this.logout.bind(this)}><Logout /></span></h2>
                                <form className="input-stroke">
                                    <div className="input-stroke__label">Имя</div>
                                    <div className={classNames({ "input-stroke__container": this.state.name.edit, })}>
                                        <input
                                            type="text"
                                            className={classNames({ "personal-input": this.state.name.edit, }, { "personal-input_hidden": !this.state.name.edit, })}
                                            id="name"
                                            name="name"
                                            autoComplete="off"
                                            readOnly={!this.state.name.edit}
                                            value={this.state.name.value}
                                            onChange={(event) => {
                                                this.changeField(event, "name");
                                            }}
                                        />
                                        <ValidationResult error={this.state.name.error} />
                                    </div>
                                    <div className={classNames({ "hide-element": !this.state.name.edit, "input-stroke__container": this.state.name.edit, })}>
                                        <Button
                                            buttonType="default"
                                            buttonTitle="Сохранить"
                                            buttonClass="personal-button"
                                            onClickSubmit
                                            onClickMethod={this.onSaveName.bind(this)}
                                        />
                                    </div>
                                    <div
                                        className="edit-icon"
                                        onClick={() => {
                                            this.onEditStart("name");
                                        }}
                                    >
                                        <div
                                            className={classNames({ "hide-element": this.state.name.edit, })}
                                        >
                                            <EditItem />
                                        </div>
                                    </div>
                                </form>
                                <form className="input-stroke">
                                    <div className="input-stroke__label">Номер телефона</div>
                                    <input
                                        type="text"
                                        className="personal-input_hidden"
                                        id="phone"
                                        name="phone"
                                        readOnly={!this.state.phone.edit}
                                        autoComplete="off"
                                        value={this.state.phone.value}
                                        onChange={(event) => {
                                            this.changeField(event, "phone");
                                        }}
                                    />
                                    <div
                                        className={classNames("edit-icon", { "hide-element": this.state.phone.edit, })}
                                        onClick={() => {
                                            this.onEditStart("phone");
                                        }}
                                    >
                                        <EditItem />
                                    </div>
                                </form>
                                <Email
                                    setProfileEmail={this.props.setProfileEmail.bind(this)}
                                    setEmail={this.onSaveEmail.bind(this)}
                                    email={this.state.email}
                                    mode="cabinet"
                                />
                                {/*<BirthbayField*/}
                                {/*    setUserProfile={this.setUserProfile.bind(this)}*/}
                                {/*    birthday={this.props.profile.birthday}*/}
                                {/*/>*/}
                                <form className="input-stroke">
                                    <div className="input-stroke__label input-stroke__label_mailing">Рассылка</div>
                                    <input
                                        className="checkbox"
                                        id="cbx"
                                        type="checkbox"
                                        disabled={!this.state.emailConfirmed}
                                        checked={this.state.emailSubscribed}
                                        onChange={this.saveEmailSubscribe.bind(this)}
                                    />
                                    <label className="cbx" htmlFor="cbx">
                                        <span className="input-stroke__label">Подписаться на акции</span>
                                    </label>
                                </form>
                            </div>
                            <Addresses
                                mode="cabinet"
                                setAddresses={this.props.setAddresses.bind(this)}
                                addresses={this.state.profile.addresses}
                                dadataToken={this.props.dadataToken}
                            />
                            {this.renderCards()}
                        </div>
                        <div className="sidebar">
                            {this.renderBonuses()}
                            <div className="history-orders">
                                <div className="history-orders__title">Мои заказы</div>
                                {this.renderOrders()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
PersonalArea.propTypes = {
    dadataToken: PropTypes.string,
    loadCart: PropTypes.func.isRequired,
};
PersonalArea.defaultProps = { dadataToken: undefined, };
export default PersonalArea;
