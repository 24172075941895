import React from "react";
import ym from "react-yandex-metrika";
import PropTypes from "prop-types";
import classNames from "classnames";
import PaymentIcon from "../../PaymentIcons/PaymentIcon";
import Addresses from "../Addresses";
import {
    getRequest,
    postRequest,
    getPrice1,
    getDeliveryMethod,
    formatCurrency,
    PROD
} from "../../../helpers";
import Order from "../Order/Order";
import BonusIcon from "../../icons/BonusIcon";
import AuthModal from "../../Modals/AuthModal";
import ValidationResult from "../ValidationResult";
import AsapField from "./AsapField";
import PersonCount from "./PersonCount/PersonCount";
import ChooseStock from "./ChooseStock/ChooseStock";
import Email from "./Email/Email";
import "./pay-icons.scss";
import RemoveItemButton from "../../icons/RemoveItemButton";
import Modal from "../../Modal/Modal";
import Notification from "../../Modal/Notification";
import TransferNotification from "./TransferNotification/TransferNotification";
import basketCheck from "../../../libs/backetCheck";

class PaymentArea extends React.Component {
    constructor(props) {
        super(props);
        this.mounted = true;
        const deliveryMethod = getDeliveryMethod();
        this.state = {
            confirm_order: false,
            promocode: null,
            stopListError: false,
            categories: this.props.categories || [],
            definitions: this.props.definitions,
            restaurants: this.props.restaurants,
            bonusAccrualPercent: this.props.bonus_accrual_percent / 100,
            total: getPrice1(this.props.cart),
            isAuthorized: this.props.isAuthorized,
            showModal: !this.props.isAuthorized,
            total_due: getPrice1(this.props.cart),
            bonuses_applied: 0,
            items: this.getCartItems(),
            addresses: this.props.profile.addresses,
            bonuses: this.props.profile.bonuses,
            invoiceEmail: this.props.profile.email,
            delivery_method: deliveryMethod ? deliveryMethod.delivery : 1,
            delivery_details: {
                comments: "",
                address: {},
            },
            extra: {
                personal_agreement: false,
                subscribe_news: false,
            },
            payment_method: -1,
            payment_details: {
                save_card: false,
                card: false,
                invoice_email: this.props.profile.email,
            },
            cards: [],
            errors: [],
            restaurantTimeSlots: [],
            slots: {
                today: {
                    date: "",
                    slots: [],
                },
                tomorrow: {
                    date: "",
                    slots: [],
                },
            },
            card: "",
            showAllSlots: false,
            id: null,
            processing: false,
            cuttlery_quantity: 1,
            load_page_time: new Date(),
            open_transfer_notification: false,
        };

        if (!this.getCartItems().length) {
            window.location.hash = "#/";
        }
    }

    componentDidMount() {
        this.getCheckoutTimeAvailable();
        this.getCards();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setDefaultAddress() {
        // if (this.state.delivery_method === 1 && this.state.restaurants) {
        //     const addresses = this.state.restaurants.slice()
        //         .filter(res => res.published)
        //         .map(res => {res.restaurant_id = res.id; return res;});
        //     this.setAddress(addresses[0]);
        // }
        // if (this.state.delivery_method === 2 && this.state.addresses) {
        //     const addresses = this.state.addresses.slice();
        //     this.setAddress(addresses[0]);
        // }
    }

    getCards() {
        if (this.mounted) {
            getRequest("/cards").then((res) => {
                this.setState({ cards: res.data.result, });
            });
        }
    }

    setCard(e) {
        this.setState({ card: e.currentTarget.value, });
    }

    setSaveCard(e) {
        const details = Object.assign({}, this.state.payment_details);

        details.save_card = e.currentTarget.checked;

        this.setState({ payment_details: details, });
    }

    setPayment(e) {
        const value = e.currentTarget.value;
        const isCard = value === "card";
        let method = 1
        switch (value) {
            case 'card':
                method = 3
                break
            case 'on-pickup':
                method = 1
                break
            case 'sbp':
                method = 5
                break
            default:
                method = 0
                break
        }

        this.setState(
            {
                payment_method: method,
                paymentType: value,
            },
            () => {
                const details = Object.assign({}, this.state.payment_details);

                details.card = isCard;
                this.setState({ payment_details: details, });
            }
        );
    }

    setInvoiceEmail = (value) => {
        this.setState(state => ({
            invoiceEmail: value,
            payment_details: {
                ...state.payment_details,
                invoice_email: value,
            },
        }));
    };

    setExtra(e, type) {
        const details = Object.assign({}, this.state.extra);

        details[type] = e.currentTarget.checked;

        this.setState({ extra: details, });
    }

    setComments(e) {
        const details = Object.assign({}, this.state.delivery_details);

        details.comments = e.currentTarget.value;

        this.setState({ delivery_details: details, });
    }

    setTimeslot(date, time) {
        const details = Object.assign({}, this.state.delivery_details);

        details.date = date;
        details.time = time;

        this.setState({ delivery_details: details, });
    }

    setAsap(e) {
        const details = Object.assign({}, this.state.delivery_details);

        details.asap = e.currentTarget.value === "asap";

        this.setState({ delivery_details: details, });
    }

    getCheckoutTimeAvailable() {
        const self = this;
        if (this.mounted) {
            getRequest("/checkout/time-available", {}).then((res) => {
                self.setState({
                    slots: res.data.result.default[self.state.delivery_method],
                    restaurantTimeSlots: res.data.result,
                });
                self.setDefaultAddress();
            });
        }
    }

    getCartItems = () => this.props.cart.map((item) => {
        const product = Object.assign({}, item.itemData);

        product.quantity = item.numberItems;
        return product;
    });

    async checkOrder() {
        let result = false;
        const { payment_method, delivery_details, invoiceEmail, } = this.state;
        const RegMail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

        const productsReq = await getRequest('/products');
        const products = productsReq.data.result.items;

        const brokenItems = basketCheck(this.state.items, products).map(item => item.id);
        const brokenItemsTitles = basketCheck(this.state.items, products).map(item => item.title);

        if (brokenItems.length > 0) {
            const newCart = this.props.cart.filter(item => !brokenItems.includes(item.itemData.id));
            brokenItems.map(id => this.props.removeAllItemsFromCart(id));
            // todo process bonuses or discount
            this.setState({
                items: newCart.map(item => item.itemData),
                total: getPrice1(newCart),
                total_due: getPrice1(newCart),
                errors: [{ message: `Временно недоступные позиции были удалены из корзины: ${brokenItemsTitles.join(', ')}` }]
            });

            console.log('newCart', newCart);

            return false;
        }
        if (Object.keys(delivery_details.address).length === 0) {
            this.setState({ errors: [{ message: this.state.delivery_method === 1 ? "Не указан адрес самовывоза" : "Не указан адрес доставки", }], });
        } else if (this.state.total_due < delivery_details.address.min_payment_amount) {
            this.setState({
                errors: [{
                    message: "Для данного адреса минимальная сумма заказа после применения скидок: " +
                        this.state.delivery_details.address.min_payment_amount + " ₽",
                }],
            });
        } else if (!delivery_details.asap && !delivery_details.time) {
            this.setState({ errors: [{ message: "Выберите время доставки для оформления заказа", }], });
        } else if (!(await this.checkCheckoutTime())) {
            this.setState({ errors: [{ message: "К сожалению, наше заведение уже закрыто, пожалуйста сделайте заказ после открытия.", }], });
        } else if (!invoiceEmail) {
            this.setState({ errors: [{ message: "Поле e-mail обязательно для заполнения", }], });
        } else if (!RegMail.test(invoiceEmail)) {
            this.setState({ errors: [{ message: "Поле e-mail заполнено не корректно", }], });
        } else if (payment_method === -1) {
            this.setState({ errors: [{ message: "Выберите способ оплаты для оформления заказа", }], });
        } else if (!this.state.extra.personal_agreement) {
            this.setState({
                errors: [
                    {
                        message:
                            "Для оформления заказа необходимо согласие на обработку персональных данных. " +
                            "Мы запомним ваше имя, номер телефона и адреса доставки, чтобы не указывать их при следующих заказах. " +
                            "Все ваши данные надёжно защищены",
                    }
                ],
            });
        } else if (this.getErrorMessage(delivery_details.asap, this.state.items, delivery_details).error) {
            this.setState({
                errors: [
                    {
                        message: this.getErrorMessage(
                            delivery_details.asap,
                            this.state.items,
                            delivery_details
                        ).message,
                    }
                ],
            });
        } else if (!this.isProcessing) {
            result = true;
            this.setState({ errors: [], });
        } else {
            this.setState({ errors: [], });
        }
        return result;
    }

    async checkCheckoutTime() {
        if (!this.state.delivery_details.asap) {
            return true
        }
        if (this.state.delivery_details.address !== undefined && this.state.delivery_details.address.restaurant_id != undefined) {
            const restaurants = this.state.restaurants.filter(res => res.id === this.state.delivery_details.address.restaurant_id)
            if (restaurants.length === 1) {
                var restaurant = restaurants[0];
                var [startHour, startMinuts] = [0, 0];
                var [endHour, endMinuts] = [0, 0];
                if (this.state.delivery_method === 1) {
                    [startHour, startMinuts] = restaurant.pickup_start_time.split(":");
                    [endHour, endMinuts] = restaurant.pickup_end_time.split(":");
                } else if (this.state.delivery_method === 2) {
                    [startHour, startMinuts] = restaurant.delivery_start_time.split(":");
                    [endHour, endMinuts] = restaurant.delivery_end_time.split(":");
                } else {
                    return false
                }
                const openTime = new Date().setHours(startHour, startMinuts, 0).valueOf();
                const endTime = new Date().setHours(endHour, endMinuts, 0).valueOf();
                const now = new Date().valueOf();

                if ((openTime <= now && now < endTime)) {
                    return await fetch("https://worldtimeapi.org/api/timezone/Europe/Moscow").then(
                        async (response) => {
                            if (response.status == 200) {
                                var data = await response.json()
                                var date = Date.parse(data.datetime).valueOf()
                                return (openTime <= date && date < endTime)
                            }
                            return true
                        }
                    ).catch((err) => {
                        console.log("error requesting time from internet:", err)
                        return true
                    })
                }
            } else {
                console.log("error choosing restaurant from address while checking checkout time")
            }
        }
        return false
    }

    getParamsForOrder = () => {
        let order_delivery_details = this.state.delivery_details;

        if (new Date().getHours() === 22 && new Date().getMinutes() >= 30) {
            const currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            const day = currentDate.getDate();
            const month = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();

            order_delivery_details = {
                ...this.state.delivery_details,
                date: `${year}-${month}-${day}`,
                time: "9:00 - 9:30",
                asap: false,
            };
        }

        return {
            bonuses_applied: this.state.bonuses_applied,
            cuttlery_quantity: this.state.cuttlery_quantity,
            definitions: this.state.definitions,
            delivery_details: order_delivery_details,
            delivery_method: this.state.delivery_method,
            extra: this.state.extra,
            items: this.state.items,
            paymentType: this.state.paymentType,
            payment_details: this.state.payment_details,
            payment_method: this.state.payment_method,
            total: this.state.total,
            total_due: this.state.total_due,
            promocode: this.state.promocode,
        };
    };

    createOrder() {
        const params = this.getParamsForOrder();
        console.log(params);
        const actionId = this.state.id === null ? "create" : "update";

        if (this.mounted && !this.state.processing) {
            this.setState({ processing: true, });
            postRequest(
                "/order/" + actionId,
                this.state.id === null ? params : { ...params, id: this.state.id, }
            ).then((res) => {
                if (res.data.success) {
                    const data = res.data.result;
                    const bonusesApplied = parseInt(res.data.result.order.bonuses_applied, 10);
                    console.log("Order has been created");

                    if (actionId === "create") {
                        const VK = window.VK;
                        VK.Goal("purchase", { value: params.total_due, });
                    }

                    this.setState({ id: data.order.id, });

                    if (this.state.payment_details.card && this.state.payment_method === 3) {
                        if (this.state.card === "") {
                            this.showPayment(data, bonusesApplied);
                        } else {
                            this.createPayment(data.order.id, data.order.total_due, data.customer, bonusesApplied);
                        }
                    } else {
                        this.setCartEmpty(bonusesApplied);
                        this.setState({ processing: false, });
                        window.location.href = "#/status/" + data.order.id;
                        if (this.state.payment_method === 5) {
                            setTimeout(() => {window.open(data.order.payment_details.URL, "_self"); }, 500);
                        }
                    }
                    if (PROD) ym("reachGoal", "Order");
                } else {
                    this.setState({ errors: res.data.errors, });
                    this.setState({ processing: false, });
                }
            }).catch(() => {
                setTimeout(() => {
                    this.setState({ processing: false, });
                }, 1000);
            });
        }
    }

    showPayment(params, bonusesApplied) {
        const widget = new cp.CloudPayments();
        const self = this;
        const filterRestaurant_d = this.state.delivery_details.address.restaurant_id;
        let restaurant = this.props.restaurants.filter(restaurant => parseInt(restaurant.id) === parseInt(filterRestaurant_d));
        if (restaurant.length !== 0) {
            restaurant = restaurant[0];
        }

        widget.charge(
            {
                publicId: restaurant.cloudpayments_public
                    ? restaurant.cloudpayments_public
                    : this.props.cloudpaymentsPublicId,
                description: "Заказ N " + params.order.order_number + " от " + params.order.order_date,
                amount: parseInt(params.order.total_due, 10),
                currency: "RUB",
                invoiceId: params.order.id,
                accountId: params.customer.id,
                email: params.customer.email,
                requireEmail: true,
                data: {
                    order_number: params.order.order_number,
                    order_date: params.order.order_date,
                },
            },
            () => {
                self.setCartEmpty(bonusesApplied);
                self.setState({ processing: false, });
                window.location.href = "#/status/" + params.order.id;
            },
            () => {
                self.setState({ processing: false, });
                // window.location.href = '#/payment/';
            }
        );
    }

    createPayment(orderId, total, customer, bonusesApplied) {
        const params = {
            cardId: this.state.card,
            invoiceId: orderId,
            accountId: customer.id,
            amount: parseInt(total, 10),
            email: customer.email,
        };
        if (this.mounted) {
            postRequest("/order/pay-one-click", params).then((res) => {
                if (res.data.success) {
                    this.setCartEmpty(bonusesApplied);
                    this.setState({ processing: false, });
                    window.location.href = "#/status/" + orderId;
                } else {
                    this.setState({ errors: res.data.errors, });
                    this.setState({ processing: false, });
                }
            });
        }
    }

    setCartEmpty(bonusesApplied) {
        this.props.clearCart();
        this.props.setProfileBonuses(this.props.profile.bonuses - bonusesApplied);
    }

    setDuePromo = (amount) => {
        this.setState(state => ({ total_due: amount || state.total, }));
    };

    setPromocode = (promocode) => {
        this.setState({ promocode, });
    };

    setDueBonus = (useBonuses) => {
        if (useBonuses) {
            this.props.setUsePromo(false);
            this.props.setPromoDiscount(0);
            const params = this.getParamsForOrder();
            params.bonuses_applied = Math.trunc(this.state.total / 2) > this.state.bonuses ? this.state.bonuses : Math.trunc(this.state.total / 2);
            if (params.bonuses_applied > 0) {
                this.setState({ processing: true, });
                postRequest("/order/validate-promocode", params).then((res) => {
                    console.log("api validate bonuses", res.data);
                    if (res.data.success) {
                        const { total_due, bonuses_applied } = res.data.result.order;
                        if (bonuses_applied > 0) {
                            this.props.setPaymentWithBonus(true);
                            this.props.setBonusDiscount(bonuses_applied);
                        } else {
                            this.props.setPaymentWithBonus(false);
                            this.props.setBonusDiscount(0);
                        }
                        this.setState({
                            total_due: total_due,
                            bonuses_applied: bonuses_applied,
                        });
                    } else {
                        this.props.setPaymentWithBonus(false);
                        this.props.setBonusDiscount(0);
                        this.setState({ errors: res.data.errors, hint: "err", });
                    }
                    this.setState({ processing: false, });
                }).catch(err => {
                    this.props.setPaymentWithBonus(false);
                    this.props.setBonusDiscount(0);
                    this.setState({ processing: false, errors: res.data.errors, hint: "err", });
                });
            } else {
                this.props.setNotBonuses(true);
            }
        } else {
            this.props.setPaymentWithBonus(false);
            this.props.setBonusDiscount(0);
            this.setState((state) => {
                return {
                    total_due: state.total,
                    bonuses_applied: 0,
                };
            });
        }
    };

    setAddress(address) {
        const delivery = Object.assign(this.state.delivery_details);

        delivery.address = address;
        const stateUpdate = { delivery_details: delivery, };
        if (this.state.restaurantTimeSlots.default !== undefined) {
            if (this.state.restaurantTimeSlots[parseInt(address.restaurant_id)] !== undefined) {
                stateUpdate.slots = this.state.restaurantTimeSlots[parseInt(address.restaurant_id)][
                    parseInt(this.state.delivery_method)
                ];
            } else {
                stateUpdate.slots = this.state.restaurantTimeSlots.default[parseInt(this.state.delivery_method)];
            }
        }

        this.setState(stateUpdate);
    }

    setShowAllSlots(type) {
        this.setState({ showAllSlots: true, });
    }

    onAuth(data) {
        if (data && data.success === true) {
            this.props.doAuth(data);
            this.onCloseAuth();
        }
    }

    onCloseAuth() {
        this.setState({ showModal: false, });
    }

    render() {
        return (
            <main className={classNames("page payment", { "hide-element": this.props.showMobileAuth, })}>
                <AuthModal
                    modalTitle="Введите номер телефона"
                    modalText="Укажите телефон, куда прислать СМС с кодом подтверждения"
                    modalDescription="Зарегистрируйтесь для оформления заказа"
                    requestAuthCodeApi="/auth/login-or-register"
                    confirmAuthCodeApi="/auth/dual-confirm"
                    isOpen={!this.state.isAuthorized && this.state.showModal}
                    onSuccess={this.onAuth.bind(this)}
                    onClose={this.onCloseAuth.bind(this)}
                />
                {this.props.constructor && this.props.constructor.not_bonuses && (
                    <Modal>
                        <Notification />
                    </Modal>
                )}
                {this.state.isAuthorized ? this.content : this.register}
            </main>
        );
    }

    get register() {
        return (
            <div className="page__container container">Пожалуйста, зарегистрируйтесь для оформления заказа.</div>
        );
    }

    changePersonCount = (e) => {
        this.setState({ cuttlery_quantity: +e.target.value, });
    };

    get content() {
        return (
            <div className="page__container container">
                <div className="payment__blocks-wrapper left">
                    <div className="payment__block">{this.addressesBlockContent}</div>
                    <PersonCount
                        cuttlery_quantity={this.state.cuttlery_quantity}
                        changePersonCount={this.changePersonCount}
                    />
                    <ChooseStock
                        bonuses={this.state.bonuses}
                        setDueBonus={this.setDueBonus}
                        setDuePromo={this.setDuePromo}
                        paramsForOrder={this.getParamsForOrder}
                        setPromocode={this.setPromocode}
                    />
                    <div className="payment__block-row payment__block">
                        {this.deliveryBlockContent}
                        {this.paymentBlockContent}
                    </div>
                    <div className="payment__block-row payment__block">
                        {this.commentBlockContent}
                        <Email invoiceEmail={this.state.invoiceEmail} setInvoiceEmail={this.setInvoiceEmail} />
                    </div>
                    <div className="payment__block-row payment__block payment__block--white">
                        {this.bottomBlockContent}
                    </div>
                </div>
                <div className="payment__blocks-wrapper right">
                    <div className="payment__block">{this.basket}</div>
                </div>
                <div className="mobile-only">
                    <h2 className="page__title">Оплата</h2>
                </div>
            </div>
        );
    }

    get paymentBlockContent() {
        return (
            <div className="col col-2">
                <div className="payment__block-title">Оплата</div>
                <div className="payment__block-content payment-method">
                    <ul>
                        {this.state.delivery_method === 1 && this.pickupPay("В ресторане")}
                        <li>
                            <input
                                type="radio"
                                id="payment-sbp"
                                name="payment-method"
                                value="sbp"
                                onClick={this.setPayment.bind(this)}
                            />
                            <label htmlFor="payment-sbp">
                                <PaymentIcon
                                    id='sbp'
                                    style={{ height: 30, }}
                                    className="payment__card_icon"
                                />{" "}
                                Система быстрых платежей
                            </label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                id="payment-card"
                                name="payment-method"
                                value="card"
                                onClick={this.setPayment.bind(this)}
                            />
                            <label htmlFor="payment-card">Картой на сайте</label>
                        </li>
                        <div className={`add-new-card ${this.state.paymentType === "card" && "show-new-card"}`}>
                            {this.cards}
                        </div>
                    </ul>
                    <div className={`save-new-card ${this.state.paymentType === "card" && "show-save-new-card"}`}>
                        {this.saveCard}
                    </div>
                </div>
            </div>
        );
    }

    get saveCard() {
        return (
            <div className="payment__save">
                <input
                    type="checkbox"
                    name="save-card"
                    id="save-card"
                    className="checkbox"
                    onChange={this.setSaveCard.bind(this)}
                />
                <label htmlFor="save-card" className="payment__save-card">
                    Сохранить карту в личном кабинете для быстрой оплаты следующего заказа
                </label>
            </div>
        );
    }

    pickupPay = text => (
        <li>
            <input
                type="radio"
                id="on-pickup"
                name="payment-method"
                value="on-pickup"
                onClick={this.setPayment.bind(this)}
            />
            <label htmlFor="on-pickup">{text}</label>
        </li>
    );

    getDayName(day) {
        switch (day) {
            case 1:
                return "mon";
            case 2:
                return "tue";
            case 3:
                return "wed";
            case 4:
                return "thu";
            case 5:
                return "fri";
            case 6:
                return "sat";
            case 7:
                return "sun";
            default:
                return "mon";
        }
    }

    getHelpMessage(items) {
        const categories = [];
        const categories_tomorrow = [];
        items.map((item) => {
            if (item.time_available !== undefined && item.time_available !== null) {
                if (item.time_available[this.getDayName(new Date().getDay())] !== undefined) {
                    // console.log(item)
                    item.categories.forEach((element) => {
                        let time = ", которая доступна сегодня в: ";
                        item.time_available[this.getDayName(new Date().getDay())].forEach((el) => {
                            time
                                += el.start.substring(0, el.start.length - 3)
                                + "-"
                                + el.end.substring(0, el.end.length - 3)
                                + ", ";
                        });
                        if (item.time_available[this.getDayName(new Date().getDay() + 1)] !== undefined) {
                            item.categories.forEach((element) => {
                                time += "доступна завтра в: ";
                                item.time_available[this.getDayName(new Date().getDay() + 1)].forEach((el) => {
                                    time
                                        += el.start.substring(0, el.start.length - 3)
                                        + "-"
                                        + el.end.substring(0, el.end.length - 3)
                                        + ", ";
                                });
                            });
                        } else {
                            time += "недоступна завтра  ";
                        }
                        time = time.substring(0, time.length - 2) + ".";
                        categories.push({ category: element.title, time, });
                    });
                } else {
                    item.categories.forEach((element) => {
                        let time = ", которая недоступна сегодня";
                        if (item.time_available[this.getDayName(new Date().getDay() + 1)] !== undefined) {
                            item.categories.forEach((element) => {
                                time += ", доступна завтра в: ";
                                item.time_available[this.getDayName(new Date().getDay() + 1)].forEach((el) => {
                                    time
                                        += el.start.substring(0, el.start.length - 3)
                                        + "-"
                                        + el.end.substring(0, el.end.length - 3)
                                        + ", ";
                                });
                            });
                        } else {
                            time += ", недоступна завтра  ";
                        }
                        time = time.substring(0, time.length - 2) + ".";
                        categories.push({ category: element.title, time, });
                    });
                }
            }
        });
        const history = [];
        let message = "В вашей корзине есть позиции из категории: ";
        const result = [];
        categories.forEach((item) => {
            if (history.indexOf(item.category) === -1) {
                result.push(item);
                message += item.category;
                history.push(item.category);
                message += item.time;
            }
        });
        // console.log(result, categories)
        if (result.length === 0) return undefined;
        return (
            <div className="payment__time-error">
                <p className="payment__time-error_title">В вашей корзине есть позиции из категории:</p>
                <ul className="payment__time-error_list">
                    {result.map(category => (
                        <li className="payment__time-error_list-element">{category.category + category.time}</li>
                    ))}
                </ul>
            </div>
        );
    }

    getErrorMessage(isAsap, items, delivery_details) {
        const bad_items = [];
        let incorrect = false;
        if (isAsap) {
            let message = "";
            items.forEach((item) => {
                let item_incorrect = true;
                if (item.time_available !== undefined && item.time_available !== null) {
                    if (item.time_available[this.getDayName(new Date().getDay())] !== undefined) {
                        let pushed = false;
                        item.time_available[this.getDayName(new Date().getDay())].forEach((time_item) => {
                            const start = time_item.start;
                            const end = time_item.end;
                            const now = new Date();
                            if (
                                !(
                                    now.getHours() >= Number(start.split(":")[0]) && now.getHours() <= Number(end.split(":")[0])
                                )
                                || (now.getHours() === Number(end.split(":")[0]) && now.getMinutes() > Number(end.split(":")[1]))
                            ) {
                                if (!pushed) {
                                    bad_items.push(item);
                                    message += item.title + ", ";
                                    pushed = true;
                                }
                            } else {
                                item_incorrect = false;
                            }
                        });
                    } else {
                        bad_items.push(item);
                        message += item.title + ", ";
                    }
                } else {
                    item_incorrect = false;
                }
                if (!incorrect && item_incorrect) {
                    incorrect = true;
                }
            });
            try {
                message = message.substring(0, message.length - 2);
            } catch (e) { }
            message += " недоступны для заказа в данный момент.";
            return { error: incorrect, message, };
        }
        let message = "В вашем заказе есть позиции из категории ";
        let categories = [];
        const categories_time = [];
        let incorrect_today = false;

        incorrect = false;

        if (delivery_details.time !== undefined) {
            const time = delivery_details.time;
            const min_time = time.split("-")[0];
            const max_time = time.split("-")[1] === undefined ? min_time : time.split("-")[1];
            let min_item_time = "24:00:00";
            let max_item_time = "00:00:00";
            let index = -1;
            items.forEach((item) => {
                let pushed = false;
                let item_incorrect = true;
                const time = [];
                if (item.time_available !== undefined && item.time_available !== null) {
                    if (
                        item.time_available[
                        Number(delivery_details.date.split("-")[2]) === new Date().getDate()
                            ? this.getDayName(new Date().getDay())
                            : this.getDayName(new Date().getDay() + 1)
                        ] !== undefined
                    ) {
                        item.time_available[
                            Number(delivery_details.date.split("-")[2]) === new Date().getDate()
                                ? this.getDayName(new Date().getDay())
                                : this.getDayName(new Date().getDay() + 1)
                        ].forEach((time_item) => {
                            const start = time_item.start;
                            const end = time_item.end;
                            if (
                                !(
                                    Number(max_time.split(":")[0]) <= Number(end.split(":")[0])
                                    && Number(min_time.split(":")[0]) >= Number(start.split(":")[0])
                                )
                                || !(
                                    Number(min_time.split(":")[0]) >= Number(start.split(":")[0]) - 1
                                    && Number(min_time.split(":")[0]) >= Number(start.split(":")[0])
                                    && Number(max_time.split(":")[0]) <= Number(end.split(":")[0])
                                    && Number(end.split(":")[0] !== Number(start.split(":")[0]))
                                )
                                || (min_time === max_time
                                    && Number(max_time.split(":")[0]) === Number(end.split(":")[0])
                                    && Number(min_time.split(":")[1]) > Number(end.split(":")[1]))
                                || (Number(min_time.split(":")[0]) === Number(end.split(":")[0])
                                    && Number(min_time.split(":")[0]) !== Number(end.split(":")[0]))
                            ) {
                                if (!pushed) {
                                    index++;
                                    item.categories.forEach(el => categories.push(el.title));
                                    pushed = true;
                                }
                                time.push(
                                    time_item.start.substring(0, time_item.start.length - 3)
                                    + "-"
                                    + time_item.end.substring(0, time_item.end.length - 3)
                                );
                                if (Number(min_item_time.split(":")[0]) > Number(start.split(":")[0])) min_item_time = start;
                                if (Number(max_item_time.split(":")[0]) < Number(end.split(":")[0])) max_item_time = end;
                            } else {
                                item_incorrect = false;
                            }
                        });
                    } else {
                        incorrect = true;
                        incorrect_today = true;
                        item.categories.forEach(el => categories.push(el.title));
                    }
                } else {
                    item_incorrect = false;
                }
                if (item.time_available === null) {
                    item.categories.forEach(el => (categories += el.title + ", "));
                }
                if (!incorrect && item_incorrect) {
                    incorrect = true;
                }
                if (time.length > 0) {
                    categories_time.push(time);
                }
            });
            const history_item = [];
            if (!incorrect_today) {
                try {
                    categories.forEach((item, index) => {
                        if (history_item.indexOf(item) === -1) {
                            message += item + ", которая доступна с ";
                            categories_time[index].forEach((element) => {
                                message += element + ", ";
                            });
                            message = message.substring(0, message.length - 2) + ". ";
                            history_item.push(item);
                        }
                    });
                } catch (e) { }
            } else {
                message = "Позиции из категориии: ";
                categories.forEach((item) => {
                    message += item + ", ";
                });
                message += "недоступны завтра.";
            }
        }
        return { error: incorrect, message, };
    }

    get deliveryBlockContent() {
        let error_message = { error: false, };
        if (this.state.delivery_details.asap !== undefined) {
            error_message = this.getErrorMessage(
                this.state.delivery_details.asap,
                this.state.items,
                this.state.delivery_details
            );
        }
        const deliveryText = this.state.delivery_method === 1 ? "Время самовывоза" : "Время доставки";
        return (
            <div className="col col-2">
                <div className="payment__block-title">{deliveryText}</div>
                <div className="payment__block-content delivery-method">
                    <ul>
                        {this.props.restaurants.length > 0 && (
                            <AsapField
                                schedule={this.props.restaurants[0].schedule}
                                slots={this.state.slots.today.slots.length}
                                setAsap={this.setAsap.bind(this)}
                                message={error_message}
                            />
                        )}
                        {this.props.restaurants.length > 0 && this.state.delivery_details.asap && error_message.error && (
                            <p className="payment__time-error">{error_message.message}</p>
                        )}
                        <li>
                            <input
                                type="radio"
                                id="delivery-to-time"
                                name="delivery"
                                value="to-time"
                                onChange={this.setAsap.bind(this)}
                            />
                            <label htmlFor="delivery-to-time">Заказать ко времени</label>
                        </li>
                        {this.state.delivery_details.asap !== undefined
                            && !this.state.delivery_details.asap
                            && this.getHelpMessage(this.state.items)}
                        {/* <p className={'payment__time-error'}> */}
                        {/*    {this.getHelpMessage(this.state.items)} */}
                        {/*    /!*{this.getErrorMessage(this.state.delivery_details.asap, this.state.items, {time: '01:00-01:30', date: new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate()}).message}*!/ */}
                        {/* </p> */}
                    </ul>
                    <div className={`available-times ${this.state.delivery_details.asap === false && "show-times"}`}>
                        {this.state.delivery_details.asap === false ? this.timeAvailable : ""}
                    </div>
                </div>
            </div>
        );
    }

    get commentBlockContent() {
        return (
            <div className="col col-2">
                <div className="payment__block-title">Дополнительно</div>
                <div className="payment__block-content">
                    <label htmlFor="comment">Ваш комментарий к заказу</label>
                    <input
                        type="text"
                        id="comment"
                        name="comment"
                        value={this.state.delivery_details.comments}
                        onChange={this.setComments.bind(this)}
                    />
                </div>
            </div>
        );
    }

    get errors() {
        return this.state.errors.length ? (
            <div className="payment__block1">
                {this.state.errors.map((item, index) => (
                    <ValidationResult key={`error_${index}`} error={item.message} />
                ))}
            </div>
        ) : (
            ""
        );
    }

    get bottomBlockContent() {
        return (
            <div className="col">
                <ul className="payment__accept">
                    <li className="payment__accept_item">
                        <input
                            type="checkbox"
                            id="accept"
                            className="checkbox"
                            onChange={(e) => {
                                this.setExtra(e, "personal_agreement");
                            }}
                        />
                        <label htmlFor="accept">
                            Соглашаюсь на обработку персональных данных на условиях{" "}
                            <a href="#/legal">пользовательского соглашения</a>
                        </label>
                    </li>
                    <li className="payment__accept_item">
                        <input
                            type="checkbox"
                            className="checkbox"
                            id="subscribe"
                            onChange={(e) => {
                                this.setExtra(e, "subscribe_news");
                            }}
                        />
                        <label htmlFor="subscribe">Подписаться на акции</label>
                    </li>
                </ul>
                {this.errors}
                <button
                    onClick={
                        () => this.checkOrder().then((res) => res && this.createOrder())
                    }
                    disabled={false}
                    className={classNames("btn", { "payment__button--disable": this.isButtonCreateOrderDisabled || this.state.stopListError, })}
                >
                    Подтвердить
                </button>
                <div className="payment__order_bonuses">
                    Будет начислено {Math.ceil(this.state.total_due * this.state.bonusAccrualPercent)} <BonusIcon />
                </div>
            </div>
        );
    }

    get isButtonCreateOrderDisabled() {
        const { payment_method, invoiceEmail, } = this.state;
        const { asap, time, address, } = this.state.delivery_details;
        const { personal_agreement, } = this.state.extra;

        let disabled = true;

        if (
            personal_agreement
            && payment_method !== -1
            && (asap || time)
            && Object.keys(address).length !== 0
            && invoiceEmail
        ) {
            disabled = false;
        }

        return disabled;
    }

    get isProcessing() {
        return this.state.processing === true;
    }

    get basket() {
        let date;
        if (this.state.confirm_order) {
            const delivery_date = this.state.delivery_details.asap
                ? new Date()
                : new Date(this.state.delivery_details.date);
            date = `Дата ${this.state.delivery_method === 2 ? "доставки" : "самовывоза"}: `;
            date += `${this.zeroFill(delivery_date.getDate(), 2)}.${this.zeroFill(
                delivery_date.getMonth() + 1,
                2
            )}.${delivery_date.getFullYear()} ${!this.state.delivery_details.asap ? `ко времени: ${this.state.delivery_details.time}` : ""
                }`;
        }
        return (
            <Order
                date={date}
                profile={this.props.profile}
                bonusAccrualPercent={this.state.bonusAccrualPercent}
                order={this.state.items}
                total={this.state.total}
                total_due={this.state.total_due}
            />
        );
    }

    get addressesBlockContent() {
        const currentRestaurantId = (this.state.delivery_details
            && this.state.delivery_details
            && this.state.delivery_details.address.restaurant_id)
            || 1;
        const listOfRestaurants = this.state.restaurants;
        const servingRestaurant = listOfRestaurants.find(item => item.id === currentRestaurantId);
        const errorItems = [];
        const hiddenItems = [];

        // check stoplist
        let stopError = this.state.items
            && this.state.items.some(
                item => item.in_stop_list_in
                    && item.in_stop_list_in.some(item => (servingRestaurant && servingRestaurant.id) === item)
            );

        // check if category available
        const idsOfCategories = this.state.categories.map(c => c.id);

        stopError = !(
            this.state.items
            && this.state.items.every(
                item => item.categories && item.categories.map(c => idsOfCategories.includes(c.id)).every(i => i)
            )
        );

        stopError = this.state.items.every(
            item => item.categories && item.categories.map(c => idsOfCategories.includes(c)).every(i => i)
        );

        if (stopError !== this.state.stopListError) {
            if (
                this.state.items.length
                !== this.state.items.reduce((acc, item) => {
                    if (item.groups) acc += 1;
                    return acc;
                }, 0)
            ) {
                this.setState({ stopListError: stopError, });
            }
        }

        this.state.items
            && this.state.items.forEach((item) => {
                item.in_stop_list_in && item.in_stop_list_in.length !== 0 && errorItems.push(item.title);
            });

        this.state.items
            && this.state.items.forEach((item) => {
                item.categories
                    && !item.categories.map(c => idsOfCategories.includes(c.id)).every(i => i)
                    && hiddenItems.push(item);
            });

        return (
            <>
                {" "}
                {this.state.delivery_method === 1 ? (
                    this.restaurants
                ) : (
                    <Addresses
                        mode="payment"
                        basketCost={getPrice1(this.props.cart)}
                        addresses={this.state.addresses}
                        setAddresses={this.props.setAddresses.bind(this)}
                        setAddress={this.setAddress.bind(this)}
                        dadataToken={this.props.dadataToken}
                    />
                )}
                <div className={`stop-list-error ${this.state.stopListError && "show-error"}`}>
                    {hiddenItems.length !== 0 && (
                        <span>
                            В данный момент мы не можем приготовить некоторые позиции Вашего заказа, а именно{" "}
                            {hiddenItems.map(i => (
                                <strong>{i.title}</strong>
                            ))}
                            . Посмотрите наше меню. В нем Вы найдете то, что Вам обязательно понравится.
                        </span>
                    )}
                    {errorItems.length !== 0 && (
                        <span>
                            В данный момент мы не можем приготовить некоторые позиции Вашего заказа, а именно{" "}
                            {errorItems.map(i => (
                                <strong>{i} </strong>
                            ))}{" "}
                            на этот адрес. Выберите другой адрес, или посмотрите наше меню. В нем Вы найдете то, что Вам
                            обязательно понравится.
                        </span>
                    )}
                </div>
            </>
        );
    }

    get cards() {
        let restaurant = { cloudpayments_public: false, };
        if (this.state.delivery_details.address !== undefined) {
            if (this.state.delivery_details.address.restaurant_id != undefined) {
                const restaurants = this.state.restaurants
                    .filter(res => res.id === this.state.delivery_details.address.restaurant_id)
                    .slice();
                restaurant = restaurants[0];
            }
        }

        return (
            <div>
                {this.state.cards.map((item, index) => (restaurant && item.cloudpayments_public === restaurant.cloudpayments_public ? (
                    <div key={`card_${index}`} className="payment__card">
                        <input
                            type="radio"
                            id={item.id}
                            name="cards"
                            value={item.id}
                            onClick={this.setCard.bind(this)}
                        />
                        <label htmlFor={item.id}>
                            <PaymentIcon
                                id={item.brand}
                                style={{ margin: 5, width: 30, }}
                                className="payment__card_icon"
                            />{" "}
                            **** {item.number}
                        </label>
                    </div>
                ) : null))}
                <div className="payment__card">
                    <input
                        type="radio"
                        id="new-card"
                        name="cards"
                        value=""
                        onClick={this.setCard.bind(this)}
                    />
                    <label htmlFor="new-card">Новая карта</label>
                </div>
            </div>
        );
    }

    get restaurants() {
        const addresses = this.state.restaurants
            ? this.state.restaurants
                .slice()
                .filter(res => res.published && res.id !== 17)
                .map((res) => {
                    res.restaurant_id = res.id;
                    return res;
                })
            : [];

        return (
            <div className="payment__restaurants">
                <h2 className="inf-block__title">Выберите заведение</h2>
                {addresses && addresses.map((address, index) => (
                    <div className="input-stroke" key={`address_${index}`}>
                        <div className="address-container">
                            <div className="container-address-title">
                                <div className="input-stroke__label address-title">
                                    <input
                                        type="radio"
                                        id={"address" + address.id}
                                        name="address"
                                        value={address.id}
                                        defaultChecked={this.state.delivery_details.address.address === address.address}
                                        onChange={() => {
                                            this.setAddress(address);
                                        }}
                                    />
                                    <label htmlFor={"address" + address.id}>{address.address}</label>
                                </div>
                            </div>
                            <div className="input-stroke__label address-value">{address.metro}</div>
                            <div className="input-stroke__label address-value">{address.phone}</div>
                            <div className="input-stroke__label address-value">{address.schedule}</div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    zeroFill = (number, width) => {
        let str = number.toString();
        for (let i = str.length; i < width; i++) str = "0" + str;
        return str;
    };

    get timeAvailable() {
        const date = new Date();
        const time = this.state.delivery_details
            && this.state.delivery_details.time
            && this.state.delivery_details.date
            !== `${date.getFullYear()}-${this.zeroFill(date.getMonth() + 1, 2)}-${this.zeroFill(date.getDate(), 2)}`
            ? this.state.delivery_details.time
            : "";
        date.setDate(date.getDate() + 1);
        return (
            <div className="payment__times">
                {this.renderTimeSlots("today")}
                {this.state.slots.today.length < 12 || this.state.showAllSlots ? (
                    <div className="payment__timeslot-label pink-label">
                        {`Оформить отложенный заказ на ${this.zeroFill(date.getDate(), 2)}.${this.zeroFill(
                            date.getMonth() + 1,
                            2
                        )}.${date.getFullYear()} ${time}`}
                    </div>
                ) : (
                    ""
                )}
                {this.renderTimeSlots("tomorrow")}
            </div>
        );
    }

    renderTimeSlots(type) {
        const slots = this.state.slots[type].slots;
        const todaySlotsLength = this.state.slots.today.slots.length;
        const tomorrowClass = this.state.delivery_method === 1 ? "" : " payment__timeslot--delivery";
        let visibleSlots = 0;
        const isToday = type === "today";
        const showButtonMore = (isToday && todaySlotsLength > 12) || (!isToday && todaySlotsLength < 12);

        if (this.state.showAllSlots) {
            visibleSlots = slots.length;
        } else if (isToday) {
            if (todaySlotsLength > 12) {
                visibleSlots = 12;
            } else {
                visibleSlots = todaySlotsLength;
            }
        } else if (todaySlotsLength) {
            visibleSlots = 0;
        } else {
            visibleSlots = 12;
        }

        return (
            <div>
                <div className="payment__timeslot-container">
                    {slots
                        && slots.slice(0, visibleSlots).map((item, index) => {
                            const isSelected = this.state.delivery_details.time === item
                                && this.state.delivery_details.date === this.state.slots[type].date;
                            let disabled = false;
                            disabled = this.getErrorMessage(this.state.delivery_details.asap, this.state.items, {
                                time: item,
                                date: this.state.slots[type].date,
                            }).error;
                            return (
                                <span
                                    key={`timeslot_${index}`}
                                    className={
                                        disabled
                                            ? "payment__timeslot disabled"
                                            + tomorrowClass
                                            + (isSelected ? " payment__timeslot--selected" : "")
                                            : "payment__timeslot"
                                            + tomorrowClass
                                            + (isSelected ? " payment__timeslot--selected" : "")
                                    }
                                    onClick={() => {
                                        if (!disabled) this.setTimeslot(this.state.slots[type].date, item);
                                    }}
                                >
                                    {item}
                                </span>
                            );
                        })}
                    {showButtonMore && !this.state.showAllSlots ? (
                        <span
                            className="show-more-slots"
                            onClick={() => {
                                this.setShowAllSlots(type);
                            }}
                        >
                            Eще время
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}
PaymentArea.propTypes = {
    dadataToken: PropTypes.string,
    cloudpaymentsPublicId: PropTypes.string,
    clearCart: PropTypes.func.isRequired,
    restaurants: PropTypes.instanceOf(Array).isRequired,
    cart: PropTypes.instanceOf(Array).isRequired,
};
PaymentArea.defaultProps = {
    dadataToken: undefined,
    cloudpaymentsPublicId: undefined,
};
export default PaymentArea;
