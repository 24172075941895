import { connect } from "react-redux";
import Component from "./PersonalArea";
import { loadCart } from "../../../AC";

function mapStateToProps(state) {
    return { cart: state.cart, };
}
const mapDispatchToProps = { loadCart, };

const ConnectedComponent = connect(
    mapStateToProps, mapDispatchToProps
)(Component);

export default ConnectedComponent;
