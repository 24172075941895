import "core-js";
import "regenerator-runtime/runtime";
import { Provider } from "react-redux";
import App from "./components/App";
import store from "./store/store";
import "./assets/scss/main.scss";

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("app")
);
