import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import classNames from "classnames";
import { getRequest } from "../../helpers";

class SalesArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            page: 1,
            total: 0,
        };
    }

    loadMore = async () => {
        const categoryId = 1;
        const pageSize = 2;
        const currentPage = this.state.page;
        const pagesUrl = `/pages/category?category_id=${categoryId}&page=${currentPage}&page_size=${pageSize}`;
        const response = await getRequest(pagesUrl);

        this.setState({
            articles: [...this.state.articles, ...response.data.result.items],
            total: response.data.result.total,
            page: currentPage + 1,
        });
    }

    render() {
        return '';
        return (
<main className={classNames('page container', { 'hide-element': this.props.showMobileAuth })}>
            <div className="page__container market-section">
                <h1 className="page__title container market-section market-section__title">Акции</h1>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={this.loadMore}
                    hasMore={this.hasMore}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    <div className="sales__list">
                        {this.articlesContent}
                    </div>
                </InfiniteScroll>
            </div>
        </main>
);
    }

    renderArticle(article) {
        return (
            <article className="sales__item sale" key={article.id}>
                <a className="sale__link" href="#">
                    <img className="sale__img" src={article.image} alt="" />
                    <div className="sale__content">
                        <div className="sale__title">{article.title}</div>
                        <div className="sale__text">{article.introtext}</div>
                    </div>
                </a>
            </article>
        );
    }

    get articlesContent() {
        return this.state.articles.map(article => this.renderArticle(article));
    }

    get hasMore() {
        return !this.state.total || this.state.total > this.state.articles.length;
    }
}

export default SalesArea;
