import React from 'react';

const Modal = ({children, closeModal}) => {
    return (
        <div className={'modal'}
        >
            <button
                className={'modal__background'}
                onClick={() => {
                    closeModal();
                }}
            />
            {children}
        </div>
    )
}

export default Modal;
