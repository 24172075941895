import React from 'react';

const Logout = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.50176 18.2578C8.50176 18.666 8.16777 19 7.75957 19H0.742188C0.333984 19 0 18.666 0 18.2578V0.742188C0 0.333984 0.333984 0 0.742188 0H7.75957C8.16777 0 8.50176 0.333984 8.50176 0.742188C8.50176 1.15039 8.16777 1.48438 7.75957 1.48438H1.48438V17.5156H7.75957C8.17148 17.5156 8.50176 17.8496 8.50176 18.2578Z" fill="#8DD161"/>
            <path d="M18.7848 8.97646L14.584 4.77568C14.2945 4.48623 13.8232 4.48623 13.5338 4.77568C13.2443 5.06514 13.2443 5.53643 13.5338 5.82588L16.4654 8.75752H3.5625C3.1543 8.75752 2.82031 9.0915 2.82031 9.49971C2.82031 9.90791 3.1543 10.2419 3.5625 10.2419H16.4654L13.5338 13.1735C13.2443 13.463 13.2443 13.9343 13.5338 14.2237C13.8232 14.5132 14.2945 14.5132 14.584 14.2237L18.7811 10.0267C18.8813 9.92647 18.9555 9.79287 18.9852 9.64814C19.0334 9.41064 18.9629 9.15459 18.7848 8.97646Z" fill="#8DD161"/>
        </svg>
    );
};

export default Logout;
