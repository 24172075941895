import React from "react";
import loader from "./loader.gif";
import "./Loader.scss";

export default function Loading() {
    return (
        <img
            className="loader"
            src={loader}
            alt="Загрузка..."
        />
    );
}
