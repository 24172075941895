import { connect } from "react-redux";
import Component from "./PaymentArea";
import { setNotBonuses, clearCart, setPaymentWithBonus, setUsePromo, setBonusDiscount, setPromoDiscount, removeAllItemsFromCart } from "../../../AC";

function mapStateToProps(state) {
    return {
        cart: state.cart,
        payment: state.payment,
        constructor: state.bowl_constructor,
        products: state.products,
    };
}
const mapDispatchToProps = {
    setNotBonuses, clearCart, setUsePromo, setPaymentWithBonus, setBonusDiscount, setPromoDiscount, removeAllItemsFromCart,
};

const ConnectedComponent = connect(
    mapStateToProps, mapDispatchToProps
)(Component);

export default ConnectedComponent;
