import React from 'react';

const Instagram = () => {
    return (
        <svg width='26' height='25' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.4825 7.28125C9.16748 7.28125 6.4375 9.9636 6.4375 13.2207C6.4375 16.4779 9.16748 19.1602 12.4825 19.1602C15.7974 19.1602 18.5274 16.4779 18.5274 13.2207C18.5274 9.9636 15.7974 7.28125 12.4825 7.28125ZM12.4825 16.8611C10.5325 16.8611 8.77749 15.3283 8.77749 13.2207C8.77749 11.3048 10.3375 9.58041 12.4825 9.58041C14.4325 9.58041 16.1874 11.1132 16.1874 13.2207C16.1874 15.1367 14.4325 16.8611 12.4825 16.8611Z' fill='#F8819C'/>
            <path d='M20.8649 0H4.09497C1.75499 0 0 1.72432 0 4.02342V20.6919C0 22.7994 1.75499 24.7153 4.09497 24.7153H21.0599C23.2048 24.7153 25.1548 22.991 25.1548 20.6919V4.02342C24.9598 1.72432 23.2048 0 20.8649 0ZM22.2298 20.5003C22.2298 21.2667 21.6448 21.8414 20.8649 21.8414H4.09497C3.31498 21.8414 2.72998 21.2667 2.72998 20.5003V4.02342C2.72998 3.25705 3.31498 2.68228 4.09497 2.68228H16.7699V5.74774H19.4999V2.68228H20.8649C21.6448 2.68228 22.2298 3.25705 22.2298 4.02342V20.5003Z' fill='#F8819C'/>
        </svg>
    );
};

export default Instagram;
