import React from "react";
import classNames from "classnames";
import Button from "../Button";
import EditItem from "../icons/EditItem";
import { postRequest, focusInputById } from "../../helpers";
import ValidationResult from "./ValidationResult";

class Email extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.email,
            error: "",
            edit: false,
        };
    }

    onChange(e) {
        this.setState({
            email: e.currentTarget.value,
            error: "",
        });
    }

    onEditToggle(value) {
        this.setState({
            edit: value,
            error: "",
        }, () => {
            if (this.state.edit) {
                focusInputById("email");
            }
        });
    }

    onSave(e) {
        e.preventDefault();

        const self = this;

        postRequest("/profile/email", { email: this.state.email, })
            .then((res) => {
                if (res.data.success === true) {
                    self.props.setEmail(this.state.email);
                    self.props.setProfileEmail(res.data.result.email, res.data.result.email_confirmed, res.data.result.email_subscribed);
                    self.onEditToggle(false);
                } else if (res.data.errors) {
                    const error = res.data.errors.map(it => it.message).join(" ");
                    self.setState({
                        edit: true,
                        error,
                    }, () => {
                        if (this.state.edit) {
                            focusInputById("email");
                        }
                    });
                }
            });
    }

    get chooseElement() {
        return (
<input
            type="checkbox"
            id={'address'}
            name="address"
            className="as-radio"
            onChange={(e) => { this.props.setEmail(e.currentTarget.checked ? this.state.email : ''); }} />
);
    }

    get header() {
        return (<div className="input-stroke__label">E-mail</div>);
    }

    render() {
        return (
<form className='input-stroke'>
            {this.props.mode === 'payment' ? this.chooseElement : this.header}
            <div className={classNames({ 'input-stroke__container': this.state.edit })}>
                <input
                    type='text'
                    className={classNames({ 'personal-input': this.state.edit }, { 'personal-input_hidden': !this.state.edit })}
                    id='email'
                    name='email'
                    readOnly={!this.state.edit}
                    autoComplete='off'
                    value={this.state.email}
                    onChange={(event) => {
                        this.onChange(event);
                    }} />
                <ValidationResult error={this.state.error} />
            </div>
            <div className={classNames({ 'hide-element': !this.state.edit, 'input-stroke__container': this.state.edit })}>
                <Button
                    className={classNames({ 'fade': this.state.edit })}
                    buttonType='default'
                    buttonTitle='Сохранить'
                    buttonClass='personal-button'
                    onClickSubmit={true}
                    onClickMethod={this.onSave.bind(this)}
                />
            </div>
            <div
                className={classNames('edit-icon', { 'hide-element': this.state.edit })}
                onClick={() => {
                    this.onEditToggle('email');
                }} >
                <EditItem />
            </div>
        </form>
);
    }
}

export default Email;
