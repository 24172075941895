import React from "react";
import PropTypes from "prop-types";
import "./SliderItem.scss";

function SliderItem({ text, }) {
    const newText = text.replace(/<<(.*?)>>/g, "<span class='slider-item__text_red'>$1</span>");
    return (
        <div className="slider-item">
            <p className="slider-item__text" dangerouslySetInnerHTML={{ __html: newText, }} />
        </div>
    );
}

SliderItem.propTypes = { text: PropTypes.string.isRequired, };

export default SliderItem;
