import React from "react";
import "./Checkbox.scss";

function Checkbox({ children, id, onChange,checked }) {
    return (
        <span className="fake-checkbox">
            <input
                type="checkbox"
                className="fake-checkbox__input"
                id={id}
                onChange={onChange}
                checked={checked}
            />
            <label
                className="fake-checkbox__label"
                htmlFor={id}
            >
                {children}
            </label>
        </span>
    );
}
export default Checkbox;
