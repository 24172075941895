import withFetch from "../../../HOC/withFetch";
import "./Legal.scss";

function Legal({ showMobileAuth, data, }) {
    const page = data.result.description;
    return (
        <main className={`page container ${showMobileAuth && "hide-element"} `}>
            <div className="page__container">
                <div className="legal_agreement_container">
                    <div dangerouslySetInnerHTML={{ __html: page, }} />
                </div>
            </div>
        </main>
    );
}

Legal.propTypes = {
    showMobileAuth: PropTypes.bool.isRequired,
    data: PropTypes.shape({ result: PropTypes.instanceOf(Object), }).isRequired,
};

export default withFetch("/pages/item?id=6", Legal);
