import MarketSection from "../MarketSection";

class Hits extends React.Component {
    componentDidMount() {
        this.props.getHits();
    }

    render() {
        const { hits, title, withScroll, } = this.props;
        return (
            <div className="basket">
                <MarketSection
                    id={0}
                    alias="hits"
                    title={title}
                    items={hits}
                    withScroll={withScroll}
                />
            </div>
        );
    }
}

Hits.propTypes = {
    hits: PropTypes.instanceOf(Object).isRequired,
    title: PropTypes.string.isRequired,
    withScroll: PropTypes.bool,
    getHits: PropTypes.func.isRequired,
};
Hits.defaultProps = { withScroll: false, };
export default Hits;
