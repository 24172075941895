import React from "react";
import PropTypes from "prop-types";
import './Asap.scss';

class AsapField extends React.Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.slots !== this.props.slots;
    }

    render() {
        const { message, setAsap, slots, schedule } = this.props;
        // if (!slots) return null; Если нет данных "заказть ко времени" значит ресторан закрыт
        const openHour = schedule.split(' ')[1].split(':')[0];
        const openMin = schedule.split(' ')[1].split(':')[1];
        const closeHour = schedule.split(' ')[3].split(':')[0];
        const closeMin = schedule.split(' ')[3].split(':')[1];
        const openTime = new Date();
        const closeTime = new Date();
        openTime.setHours(openHour, openMin, 0);
        closeTime.setHours(closeHour, closeMin, 0)
        const now = new Date();

        if ((now.valueOf() > closeTime.valueOf()) || (openTime.valueOf() > now.valueOf())) return null;

        let currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        let day = currentDate.getDate()
        let month = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1
        let year = currentDate.getFullYear()

        return (
            <li>
                <input
                    type="radio"
                    id="delivery-asap"
                    name="delivery"
                    value="asap"
                    onChange={setAsap}
                />
                <label htmlFor="delivery-asap">Как можно скорее</label>
                {(new Date().getHours() === 22 && new Date().getMinutes() >= 55) && <span className="time-left-warning">Уважаемый посетитель! Мы работаем с 9:00 до 23:00. Ваш заказ будет принят в работу {openTime.valueOf() > now.valueOf() ? day - 1 : day}.{month}.{year} в 9:00</span>}
            </li>
        );
    }
}

AsapField.propTypes = {
    setAsap: PropTypes.func.isRequired,
    message: PropTypes.object,
    slots: PropTypes.number,
    schedule: PropTypes.string.isRequired,
};
AsapField.defaultProps = { slots: 0, };

export default AsapField;
