import React from "react";
import { useSelector } from "react-redux";
import Topping from "./Topping";
import "./toppings.scss";

export default function Toppings({ product, smallCart = false, }) {
    const cart = useSelector(state => state.cart);
    const [toppings, setToppings] = React.useState([]);

    React.useEffect(() => {
        const newToppings = [];
        cart.forEach((item) => {
            if (item.itemData.id === product.id) {
                item.itemData.toppings.forEach((topping) => {
                    if (topping.amount && topping.amount > 0) {
                        newToppings.push(topping);
                    }
                });
            }
        });
        setToppings(newToppings);
    }, [product, cart]);

    return (
        <>
            {toppings.length !== 0 && (
                <section
                    className={`toppings__wrapper ${
                        cart.findIndex(item => item.itemData.id === product.id) !== -1 && "show"
                    } `}
                >
                    {!smallCart && (
                        <div className="toppings__wrapper-title">
                            <h1 className="toppings__wrapper-title__text">
                                Топпинги
                            </h1>
                        </div>
                    )}
                    <div
                        className={`toppings__wrapper-items ${
                            smallCart && "column"
                        }`}
                    >
                        {toppings.map(topping => (
                            <Topping
                                smallCart={smallCart}
                                product={product}
                                key={topping.id}
                                topping={topping}
                            />
                        ))}
                    </div>
                </section>
            )}
        </>
    );
}

Toppings.propTypes = {
    product: PropTypes.instanceOf(Object).isRequired,
    smallCart: PropTypes.boolean,
};

Toppings.defaultProps = { smallCart: false, };
