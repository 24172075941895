const BurgerMobile = ({ open, }) => {
    if (open) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <rect y="13" width="18" height="2.61417" rx="1" transform="rotate(-45 0 13)" fill="#FDFDFD" />
                <rect x="2" width="18" height="2.61417" rx="1" transform="rotate(45 2 0)" fill="#FDFDFD" />
            </svg>
        );
    }
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="2.61417" rx="1" fill="white" />
            <rect y="12.1992" width="18" height="2.61417" rx="1" fill="white" />
            <rect y="6.09961" width="18" height="2.61418" rx="1" fill="white" />
        </svg>
    );
};

export default BurgerMobile;
