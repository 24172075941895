import React from 'react';

const Logo = () => {
    return (
        <svg width='159' height='51' viewBox='0 0 159 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M58.4915 34.9645C63.6633 34.9645 67.8558 30.6794 67.8558 25.3935C67.8558 20.1076 63.6633 15.8226 58.4915 15.8226C53.3198 15.8226 49.1272 20.1076 49.1272 25.3935C49.1272 30.6794 53.3198 34.9645 58.4915 34.9645ZM58.4915 43.2871C68.1604 43.2871 75.9987 35.2759 75.9987 25.3935C75.9987 15.5112 68.1604 7.5 58.4915 7.5C48.8226 7.5 40.9844 15.5112 40.9844 25.3935C40.9844 35.2759 48.8226 43.2871 58.4915 43.2871Z'
                fill='black'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.5071 34.9645C22.6789 34.9645 26.8714 30.6794 26.8714 25.3935C26.8714 20.1076 22.6789 15.8226 17.5071 15.8226C12.3354 15.8226 8.14286 20.1076 8.14286 25.3935C8.14286 30.6794 12.3354 34.9645 17.5071 34.9645ZM17.5071 43.2871C27.1761 43.2871 35.0143 35.2759 35.0143 25.3935C35.0143 15.5112 27.1761 7.5 17.5071 7.5C7.83821 7.5 0 15.5112 0 25.3935C0 35.2759 7.83821 43.2871 17.5071 43.2871Z'
                fill='black'
            />
            <path
                d='M0 24.9785H8.14286V50.5011H0V24.9785Z'
                fill='black'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M141.492 16.0355C146.663 16.0355 150.856 20.3206 150.856 25.6065C150.856 30.8924 146.663 35.1774 141.492 35.1774C136.32 35.1774 132.127 30.8924 132.127 25.6065C132.127 20.3206 136.32 16.0355 141.492 16.0355ZM141.492 7.71291C151.16 7.71291 158.999 15.7241 158.999 25.6065C158.999 35.4888 151.16 43.5 141.492 43.5C131.823 43.5 123.984 35.4888 123.984 25.6065C123.984 15.7241 131.823 7.71291 141.492 7.71291Z'
                fill='black'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M100.507 16.0355C105.679 16.0355 109.871 20.3206 109.871 25.6065C109.871 30.8924 105.679 35.1774 100.507 35.1774C95.3354 35.1774 91.1429 30.8924 91.1429 25.6065C91.1429 20.3206 95.3354 16.0355 100.507 16.0355ZM100.507 7.71291C110.176 7.71291 118.014 15.7241 118.014 25.6065C118.014 35.4888 110.176 43.5 100.507 43.5C90.8382 43.5 83 35.4888 83 25.6065C83 15.7241 90.8382 7.71291 100.507 7.71291Z'
                fill='black'
            />
            <path
                d='M83 26.0215H91.1429V0.498905H83V26.0215Z'
                fill='black'
            />
        </svg>

    );
};

export default Logo;
