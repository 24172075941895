import React from "react";
import PropTypes from "prop-types";
import "./ChooseHint.scss";

function ChooseHint({ rules, items, classes, }) {
    let selectedPaid = 0;
    let selectedFree = 0;
    let hintText;
    items.forEach((item) => {
        if (item.paid > 0) selectedPaid += item.paid;
        if (item.free > 0) selectedFree += item.free;
    });
    const maxItemsFree = rules.min_items;
    const maxItemsPaid = rules.max_items - maxItemsFree;
    const aviableFree = maxItemsFree - selectedFree;
    const aviablePaid = maxItemsPaid - selectedPaid;
    const chooseFreeText = `Выберите ${aviableFree}`;
    const choosePaidText = `Дополнительно ${aviablePaid}`;
    hintText = aviableFree && aviablePaid;
    if (aviableFree > 0 || (aviableFree === 0 && aviablePaid === 0 && !rules.extra_item)) {
        hintText = chooseFreeText;
    } else {
        hintText = choosePaidText;
    }

    return (
        <div className={`constructor__count-hint ${classes}`}>{hintText}</div>
    );
}
ChooseHint.propTypes = {
    rules: PropTypes.shape({
        min_items: PropTypes.number.isRequired,
        max_items: PropTypes.number.isRequired,
    }).isRequired,
    items: PropTypes.instanceOf(Array).isRequired,
    classes: PropTypes.string.isRequired,
};
export default ChooseHint;
